import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { setAuthToken } from "../../../service/dashboard/AuthService";
import { setNumberStringThousandSeparator } from "../../../util/stringFormat";
import { setProductDetailFromList } from "../../../state/dashboard/webshopSlice";
import { Button } from "primereact/button";
import noImageAvailable from "../../../asset/no_image_available.png";
import noCategoryLogoAvailable from "../../../asset/no_category_logo_available.png";
import AddToCartButton from "../../../component/dashboard/webshop/AddToCartButton";

const ProductCard: React.FC<any> = (props: any) => {
    setAuthToken();
    const dispatch = useDispatch();

    const userProfile: any = useSelector(
        (state: RootState) => state.user.userProfile
    );

    const userRanks: any = useSelector(
        (state: RootState) => state.user.userRanks
    );

    const numberOfBizzClubPartners: number | null = useSelector(
        (state: RootState) => state.user.numberOfBizzClubPartners
    );

    const productList: [] = useSelector(
        (state: RootState) => state.webshop.productList
    );

    const productId: any = props.id;

    const [product, setProduct] = useState<any>(null);
    const [productPriceGroupCode, setProductPriceGroupCode] =
        useState<any>(null);
    const [productPlPrice, setProductPlPrice] = useState<any>(null);
    const [productPlDiscountPercentage, setProductPlDiscountPercentage] =
        useState<any>(null);
    const [productInStock, setProductInStock] = useState<any>(null);

    useEffect(() => {
        if (
            !product &&
            productList &&
            productList.length > 0 &&
            userProfile &&
            numberOfBizzClubPartners !== null
        ) {
            const actualProduct: any = productList.find((productItem: any) => {
                return productItem.id === productId;
            });
            setProduct(actualProduct);
            // console.log(actualProduct);
            const validMinStock = actualProduct.minStock
                ? actualProduct.minStock
                : actualProduct.globalMinStock;
            setProductInStock(
                Number(actualProduct.stockInWarehouse) -
                    Number(actualProduct.reservedStock) >
                    validMinStock
            );
            // 2023.02.14. Valentin napra PL8 mindenkinek mindenre
            // if (
            //     actualProduct &&
            //     actualProduct.productPriceGroup &&
            //     actualProduct.productPriceGroup.rrp &&
            //     actualProduct.productPriceGroup.pl8
            // ) {
            //     setProductPlPrice(actualProduct.productPriceGroup.pl8);
            //     setProductPlDiscountPercentage(
            //         Math.round(
            //             (1 -
            //                 actualProduct.productPriceGroup.pl8 /
            //                     actualProduct.productPriceGroup.rrp) *
            //                 100
            //         )
            //     );
            //     setProductPriceGroupCode("pl8");
            // }
            if (
                actualProduct &&
                actualProduct.productPriceGroup &&
                actualProduct.productPriceGroup.rrp &&
                actualProduct.productPriceGroup.employee &&
                userProfile.zepterRank &&
                userProfile.zepterRank === "EMPLOYEE"
            ) {
                setProductPlPrice(actualProduct.productPriceGroup.employee);
                setProductPlDiscountPercentage(
                    Math.round(
                        (1 -
                            actualProduct.productPriceGroup.employee /
                                actualProduct.productPriceGroup.rrp) *
                            100
                    )
                );
                setProductPriceGroupCode("employee");
                // FELEXOPTIM ZHU promo 2024.02.27-ig
                // MT szabály miatt kisebb, mint 30 ajánlott klubtag
                // } else if (
                //     actualProduct &&
                //     (actualProduct.itemNumber === "SF-K04" ||
                //         actualProduct.itemNumber === "SF-FEO" ||
                //         actualProduct.itemNumber === "SF-SOL" ||
                //         actualProduct.itemNumber === "SF-SOL-2" ||
                //         actualProduct.itemNumber === "SF-SOL-3") &&
                //     actualProduct.productPriceGroup &&
                //     actualProduct.productPriceGroup.rrp &&
                //     actualProduct.productPriceGroup.pl6 &&
                //     actualProduct.productPriceGroup.pl10 &&
                //     userProfile.zepterRank &&
                //     (userProfile.zepterRank === "CLUBMEMBER" ||
                //         userProfile.zepterRank === "REPRESENTATIVE")
                // ) {
                //     if (userProfile.zepterRank === "REPRESENTATIVE") {
                //         setProductPlPrice(actualProduct.productPriceGroup.pl10);
                //         setProductPlDiscountPercentage(
                //             Math.round(
                //                 (1 -
                //                     actualProduct.productPriceGroup.pl10 /
                //                         actualProduct.productPriceGroup.rrp) *
                //                     100
                //             )
                //         );
                //         setProductPriceGroupCode("pl10");
                //         //klubtagok esetén, de nem működik jól
                //     } else if (
                //         numberOfBizzClubPartners &&
                //         numberOfBizzClubPartners < 30
                //     ) {
                //         setProductPlPrice(actualProduct.productPriceGroup.pl6);
                //         setProductPlDiscountPercentage(
                //             Math.round(
                //                 (1 -
                //                     actualProduct.productPriceGroup.pl6 /
                //                         actualProduct.productPriceGroup.rrp) *
                //                     100
                //             )
                //         );
                //         setProductPriceGroupCode("pl6");
                //     } else {
                //         setProductPlPrice(actualProduct.productPriceGroup.pl6);
                //         setProductPlDiscountPercentage(
                //             Math.round(
                //                 (1 -
                //                     actualProduct.productPriceGroup.pl6 /
                //                         actualProduct.productPriceGroup.rrp) *
                //                     100
                //             )
                //         );
                //         setProductPriceGroupCode("pl6");
                //     }
                // MT szabály miatt kisebb, mint 50 ajánlott klubtag
                // INT. INFO Letter BizzClub Offer 053 – MyBasics – Premium Health Supplements
                // } else if (
                //     actualProduct &&
                //     actualProduct.productCategory &&
                //     actualProduct.productCategory.id &&
                //     actualProduct.productCategory.id === "8" &&
                //     actualProduct.itemNumber !== "SF-SOL" &&
                //     actualProduct.itemNumber !== "SF-SOL-2" &&
                //     actualProduct.itemNumber !== "SF-SOL-3" &&
                //     actualProduct.productPriceGroup &&
                //     actualProduct.productPriceGroup.rrp &&
                //     actualProduct.productPriceGroup.pl10 &&
                //     userProfile.zepterRank &&
                //     userProfile.zepterRank === "CLUBMEMBER"
                // ) {
                //     setProductPlPrice(actualProduct.productPriceGroup.pl10);
                //     setProductPlDiscountPercentage(
                //         Math.round(
                //             (1 -
                //                 actualProduct.productPriceGroup.pl10 /
                //                     actualProduct.productPriceGroup.rrp) *
                //                 100
                //         )
                //     );
                //     setProductPriceGroupCode("pl10");
                // } else if (
                //     actualProduct &&
                //     actualProduct.productCategory &&
                //     actualProduct.productCategory.id &&
                //     actualProduct.productCategory.id === "8" &&
                //     actualProduct.itemNumber !== "SF-SOL" &&
                //     actualProduct.itemNumber !== "SF-SOL-2" &&
                //     actualProduct.itemNumber !== "SF-SOL-3" &&
                //     actualProduct.productPriceGroup &&
                //     actualProduct.productPriceGroup.rrp &&
                //     actualProduct.productPriceGroup.pl14 &&
                //     userProfile.zepterRank &&
                //     userProfile.zepterRank === "REPRESENTATIVE"
                // ) {
                //     setProductPlPrice(actualProduct.productPriceGroup.pl14);
                //     setProductPlDiscountPercentage(
                //         Math.round(
                //             (1 -
                //                 actualProduct.productPriceGroup.pl14 /
                //                     actualProduct.productPriceGroup.rrp) *
                //                 100
                //         )
                //     );
                //     setProductPriceGroupCode("pl14");
            } else if (
                actualProduct &&
                (actualProduct.itemNumber === "TAEO-250" ||
                    actualProduct.itemNumber === "TSS-150") &&
                actualProduct.productPriceGroup &&
                actualProduct.productPriceGroup.rrp &&
                actualProduct.productPriceGroup.pl7 &&
                userProfile.zepterRank &&
                userProfile.zepterRank === "CLUBMEMBER" &&
                numberOfBizzClubPartners &&
                numberOfBizzClubPartners < 50
            ) {
                setProductPlPrice(actualProduct.productPriceGroup.pl7);
                setProductPlDiscountPercentage(
                    Math.round(
                        (1 -
                            actualProduct.productPriceGroup.pl7 /
                                actualProduct.productPriceGroup.rrp) *
                            100
                    )
                );
                setProductPriceGroupCode("pl7");
                // TODO ezt az ágat kikapcsolni
                // 24/06/2024
                // General Instruction: MyBasic Supplements BizzClub Purchase Requirements
            } else if (
                actualProduct &&
                ((actualProduct.productCategory &&
                    actualProduct.productCategory.id &&
                    actualProduct.productCategory.id === "8") ||
                    actualProduct.itemNumber === "TAEO-250" ||
                    actualProduct.itemNumber === "TSS-150") &&
                actualProduct.productPriceGroup &&
                actualProduct.productPriceGroup.rrp &&
                actualProduct.productPriceGroup.pl12 &&
                userProfile.zepterRank &&
                userProfile.zepterRank === "REPRESENTATIVE"
            ) {
                setProductPlPrice(actualProduct.productPriceGroup.pl12);
                setProductPlDiscountPercentage(
                    Math.round(
                        (1 -
                            actualProduct.productPriceGroup.pl12 /
                                actualProduct.productPriceGroup.rrp) *
                            100
                    )
                );
                setProductPriceGroupCode("pl12");
            } else if (
                actualProduct &&
                (actualProduct.itemNumber === "PAP-BDC-50" ||
                    actualProduct.itemNumber === "PAP-NF-50") &&
                actualProduct.productPriceGroup &&
                actualProduct.productPriceGroup.rrp &&
                actualProduct.productPriceGroup.pl8 &&
                userProfile.zepterRank &&
                userProfile.zepterRank === "CLUBMEMBER" &&
                numberOfBizzClubPartners &&
                numberOfBizzClubPartners < 50
            ) {
                setProductPlPrice(actualProduct.productPriceGroup.pl7);
                setProductPlDiscountPercentage(
                    Math.round(
                        (1 -
                            actualProduct.productPriceGroup.pl7 /
                                actualProduct.productPriceGroup.rrp) *
                            100
                    )
                );
                setProductPriceGroupCode("pl7");
                // TODO ezt az ágat kikapcsolni
                // INT. INFO Le􏰁er BizzClub Offer 055 – Absolument Parfumeur Offer 2024 IX-es hónapra
            } else if (
                actualProduct &&
                (actualProduct.itemNumber === "PAP-BDC-50" ||
                    actualProduct.itemNumber === "PAP-NF-50") &&
                actualProduct.productPriceGroup &&
                actualProduct.productPriceGroup.rrp &&
                actualProduct.productPriceGroup.pl14 &&
                userProfile.zepterRank &&
                userProfile.zepterRank === "REPRESENTATIVE"
            ) {
                setProductPlPrice(actualProduct.productPriceGroup.pl14);
                setProductPlDiscountPercentage(
                    Math.round(
                        (1 -
                            actualProduct.productPriceGroup.pl14 /
                                actualProduct.productPriceGroup.rrp) *
                            100
                    )
                );
                setProductPriceGroupCode("pl14");
            } else if (
                actualProduct &&
                actualProduct.productPriceGroup &&
                actualProduct.productPriceGroup.rrp &&
                actualProduct.productPriceGroup.pl1 &&
                userProfile.zepterRank &&
                userProfile.zepterRank === "CLUBMEMBER"
            ) {
                //MT 2024.01.31-től (BP feltétel)
                if (
                    actualProduct.productPriceGroup.pl8 &&
                    numberOfBizzClubPartners &&
                    numberOfBizzClubPartners >= 50
                ) {
                    setProductPlPrice(actualProduct.productPriceGroup.pl8);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl8 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl8");
                } else if (
                    actualProduct.productPriceGroup.pl7 &&
                    numberOfBizzClubPartners &&
                    numberOfBizzClubPartners >= 40
                ) {
                    setProductPlPrice(actualProduct.productPriceGroup.pl7);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl7 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl7");
                } else if (
                    actualProduct.productPriceGroup.pl6 &&
                    numberOfBizzClubPartners &&
                    numberOfBizzClubPartners >= 30
                ) {
                    setProductPlPrice(actualProduct.productPriceGroup.pl6);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl6 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl6");
                } else if (
                    actualProduct.productPriceGroup.pl5 &&
                    numberOfBizzClubPartners &&
                    numberOfBizzClubPartners >= 10
                ) {
                    setProductPlPrice(actualProduct.productPriceGroup.pl5);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl5 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl5");
                } else if (
                    actualProduct.productPriceGroup.pl4 &&
                    numberOfBizzClubPartners &&
                    numberOfBizzClubPartners >= 4
                ) {
                    setProductPlPrice(actualProduct.productPriceGroup.pl4);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl4 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl4");
                } else if (
                    actualProduct.productPriceGroup.pl3 &&
                    numberOfBizzClubPartners &&
                    numberOfBizzClubPartners >= 3
                ) {
                    setProductPlPrice(actualProduct.productPriceGroup.pl3);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl3 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl3");
                } else {
                    setProductPlPrice(actualProduct.productPriceGroup.pl1);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl1 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl1");
                }
            } else if (
                actualProduct &&
                actualProduct.productPriceGroup &&
                actualProduct.productPriceGroup.rrp &&
                actualProduct.productPriceGroup.pl4 &&
                userRanks &&
                userRanks.length === 3 &&
                userProfile.zepterRank &&
                userProfile.zepterRank === "REPRESENTATIVE"
            ) {
                //TODO MANAGER PL8 csak 100.000 Ft-ig egy hónapban PL8
                const highestPriceGroupPl8 = userRanks.some((rank: any) => {
                    return (
                        rank.rank === "RP" ||
                        rank.rank === "AP" ||
                        rank.rank === "DP" ||
                        rank.rank === "SP" ||
                        rank.rank === "TP"
                    );
                });
                const highestPriceGroupPl6 = userRanks.some((rank: any) => {
                    return rank.rank === "JP" && !highestPriceGroupPl8;
                });
                if (
                    highestPriceGroupPl8 &&
                    actualProduct.productPriceGroup.pl8
                ) {
                    setProductPlPrice(actualProduct.productPriceGroup.pl8);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl8 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl8");
                    // MT szabály
                } else if (
                    actualProduct.productPriceGroup.pl8 &&
                    numberOfBizzClubPartners &&
                    numberOfBizzClubPartners >= 50
                ) {
                    setProductPlPrice(actualProduct.productPriceGroup.pl8);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl8 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl8");
                } else if (
                    actualProduct.productPriceGroup.pl7 &&
                    numberOfBizzClubPartners &&
                    numberOfBizzClubPartners >= 40
                ) {
                    setProductPlPrice(actualProduct.productPriceGroup.pl7);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl7 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl7");
                } else if (
                    actualProduct.productPriceGroup.pl6 &&
                    ((numberOfBizzClubPartners &&
                        numberOfBizzClubPartners >= 30) ||
                        (highestPriceGroupPl6 &&
                            actualProduct.productPriceGroup.pl6))
                ) {
                    setProductPlPrice(actualProduct.productPriceGroup.pl6);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl6 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl6");
                } else {
                    if (
                        actualProduct.productPriceGroup.pl5 &&
                        numberOfBizzClubPartners &&
                        numberOfBizzClubPartners >= 10
                    ) {
                        setProductPlPrice(actualProduct.productPriceGroup.pl5);
                        setProductPlDiscountPercentage(
                            Math.round(
                                (1 -
                                    actualProduct.productPriceGroup.pl5 /
                                        actualProduct.productPriceGroup.rrp) *
                                    100
                            )
                        );
                        setProductPriceGroupCode("pl5");
                    } else {
                        setProductPlPrice(actualProduct.productPriceGroup.pl4);
                        setProductPlDiscountPercentage(
                            Math.round(
                                (1 -
                                    actualProduct.productPriceGroup.pl4 /
                                        actualProduct.productPriceGroup.rrp) *
                                    100
                            )
                        );
                        setProductPriceGroupCode("pl4");
                    }
                }
            } else if (
                actualProduct &&
                actualProduct.productPriceGroup &&
                actualProduct.productPriceGroup.rrp &&
                actualProduct.productPriceGroup.pl4 &&
                userProfile.zepterRank &&
                userProfile.zepterRank === "REPRESENTATIVE"
            ) {
                // ebben az ágban elvileg nem kéne járnunk, a rank ágon el kéne sülni
                if (
                    actualProduct.productPriceGroup.pl8 &&
                    numberOfBizzClubPartners &&
                    numberOfBizzClubPartners >= 50
                ) {
                    setProductPlPrice(actualProduct.productPriceGroup.pl8);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl8 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl8");
                } else if (
                    actualProduct.productPriceGroup.pl7 &&
                    numberOfBizzClubPartners &&
                    numberOfBizzClubPartners >= 40
                ) {
                    setProductPlPrice(actualProduct.productPriceGroup.pl7);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl7 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl7");
                } else if (
                    actualProduct.productPriceGroup.pl6 &&
                    numberOfBizzClubPartners &&
                    numberOfBizzClubPartners >= 30
                ) {
                    setProductPlPrice(actualProduct.productPriceGroup.pl6);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl6 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl6");
                } else if (
                    actualProduct.productPriceGroup.pl5 &&
                    numberOfBizzClubPartners &&
                    numberOfBizzClubPartners >= 10
                ) {
                    setProductPlPrice(actualProduct.productPriceGroup.pl5);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl5 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl5");
                } else {
                    setProductPlPrice(actualProduct.productPriceGroup.pl4);
                    setProductPlDiscountPercentage(
                        Math.round(
                            (1 -
                                actualProduct.productPriceGroup.pl4 /
                                    actualProduct.productPriceGroup.rrp) *
                                100
                        )
                    );
                    setProductPriceGroupCode("pl4");
                }
            } else if (
                actualProduct &&
                actualProduct.productPriceGroup &&
                actualProduct.productPriceGroup.rrp &&
                actualProduct.productPriceGroup.pl1
            ) {
                //ez az ág is csak a biztonság kedvéért van benne, itt nem kéne járni
                setProductPlPrice(actualProduct.productPriceGroup.pl1);
                setProductPlDiscountPercentage(
                    Math.round(
                        (1 -
                            actualProduct.productPriceGroup.pl1 /
                                actualProduct.productPriceGroup.rrp) *
                            100
                    )
                );
                setProductPriceGroupCode("pl1");
            }
        }
    }, [
        product,
        productPlDiscountPercentage,
        productId,
        productList,
        userProfile,
        userRanks,
        numberOfBizzClubPartners,
    ]);

    return (
        <>
            {productPlPrice &&
            productPlPrice !== null &&
            typeof productPlPrice !== "undefined" &&
            productPlPrice !== "" &&
            productPlPrice !== "0" &&
            productPriceGroupCode &&
            productPriceGroupCode !== null &&
            typeof productPriceGroupCode !== "undefined" &&
            userProfile &&
            product.productCategory &&
            product.productCategory.id &&
            ((userProfile.contractReceiveDate !== null &&
                userProfile.contractReceiveDate !== "" &&
                product.productCategory.id === "34") ||
                product.productCategory.id !== "34") ? (
                <div className="w-23rem p-2 mb-1 col-12 md:w-full md:col-4 lg:col-3 lg:mb-0">
                    <div className="w-full shadow-2 p-4 surface-card border-round justify-content-center md:w-25rem">
                        <div className="relative mb-3">
                            <img
                                width="250"
                                src={
                                    product &&
                                    product.productCategory &&
                                    product.productCategory.logo
                                        ? `data:image/png;base64,${product.productCategory.logo}`
                                        : noCategoryLogoAvailable
                                }
                                className="w-4 surface-card shadow-2 p-2 absolute text-900 border-round-3xl"
                                style={{
                                    left: "0rem",
                                    top: "0rem",
                                }}
                                alt="termék kategória logó"
                            />
                            <span
                                className="shadow-2 p-2 absolute text-center text-900 font-medium bg-pink-400 text-pink-50 sm:text-xs md:text-base lg:text-xl border-round-3xl"
                                style={{
                                    right: "0rem",
                                    top: "0rem",
                                }}
                            >
                                -{productPlDiscountPercentage}%
                            </span>
                            <img
                                src={
                                    product && product.productImageList[0]
                                        ? `data:image/png;base64,${product.productImageList[0].image}`
                                        : noImageAvailable
                                }
                                // src={
                                //     product.productImageList &&
                                //     product.productImageList.length > 0
                                //         ? `data:image/png;base64,${
                                //               product.productImageList.find(
                                //                   (productItem: any) => {
                                //                       return productItem.orderNo
                                //                           ? productItem.orderNo ===
                                //                                 "1"
                                //                           : null;
                                //                   }
                                //               ).thumbnail
                                //           }`
                                //         : noImageAvailable
                                // }
                                alt={
                                    product && product.productImageList[0]
                                        ? product.name + " kép"
                                        : "A termékhez nem érhető el kép"
                                }
                                className="w-full"
                            />
                        </div>
                        <div className="flex justify-content-between align-items-center mb-1">
                            <span className="text-900 font-medium text-left text-indigo-600 sm:text-base md:text-xl lg:text-xl">
                                {product && product.name ? product.name : null}
                            </span>
                            <div className="white-space-nowrap">
                                {productPlDiscountPercentage ? (
                                    <>
                                        <span className="block text-right sm:text-sm md:text-sm lg:text-base line-through">
                                            {product &&
                                            product.productPriceGroup &&
                                            product.productPriceGroup.rrp
                                                ? setNumberStringThousandSeparator(
                                                      product.productPriceGroup
                                                          .rrp
                                                  ) + " Ft"
                                                : null}
                                        </span>
                                        <span className="block text-right text-900 ml-2 text-pink-400 font-medium sm:text-base md:text-lg lg:text-2xl">
                                            {productPlPrice
                                                ? setNumberStringThousandSeparator(
                                                      productPlPrice
                                                  ) + " Ft"
                                                : null}
                                        </span>{" "}
                                    </>
                                ) : (
                                    <span className="block text-right text-900 ml-2 text-pink-400 font-medium sm:text-base md:text-lg lg:text-2xl">
                                        {productPlPrice
                                            ? setNumberStringThousandSeparator(
                                                  productPlPrice
                                              ) + " Ft"
                                            : null}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="mt-0 mb-3 text-align-center sm:ml-2 sm:mr-2 md:ml-2 md:mr-2 lg:ml-3 lg:mr-3 ">
                            <>
                                <i
                                    className={
                                        productInStock
                                            ? "pi pi-check pi-circle-fill mr-2 text-green-400"
                                            : "pi pi-times mr-2 text-yellow-400"
                                    }
                                />
                                <span
                                    className={
                                        productInStock
                                            ? "text-green-400 mt-0 mb-2 text-900 font-bold line-height-2 sm:text-base md:text-base lg:text-lg"
                                            : "text-yellow-400 mt-0 mb-2 text-900 font-bold line-height-2 sm:text-base md:text-base lg:text-lg"
                                    }
                                >
                                    {productInStock
                                        ? "Készleten"
                                        : "Nincs készleten, de előrendelhető"}
                                </span>
                            </>
                        </div>
                        <div className="mb-3 h-7rem text-700 line-height-3 text-justify sm:ml-2 sm:mr-2 md:ml-2 md:mr-2 lg:ml-3 lg:mr-3">
                            {product && product.shortDescription
                                ? product.shortDescription
                                : null}
                        </div>
                        <div>
                            <Button
                                label="Részletek"
                                icon="pi pi-file"
                                className="p-ripple p-component cursor-pointer p-3 border-round-3xl surface-500 hover:bg-black-alpha-50 border-none text-0 w-full transition-all transition-duration-300 text-center"
                                style={{
                                    border: "none",
                                    borderRadius: "2rem",
                                    // top: "1rem",
                                    bottom: "0.5rem",
                                }}
                                onClick={() => {
                                    dispatch(
                                        setProductDetailFromList({
                                            showDialog: true,
                                            product: product,
                                            productPlPrice: productPlPrice,
                                            productPlDiscountPercentage:
                                                productPlDiscountPercentage,
                                            productInStock: productInStock,
                                            bonus: false,
                                        })
                                    );
                                }}
                            />
                            <AddToCartButton
                                product={product}
                                productPriceGroupCode={productPriceGroupCode}
                                productPlPrice={productPlPrice}
                                productPlDiscountPercentage={
                                    productPlDiscountPercentage
                                }
                                productInStock={productInStock}
                                bonus={false}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default ProductCard;
