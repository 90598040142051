import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setActiveMenuItem } from "../../state/dashboard/navigationSlice";
import { StatusCodes } from "http-status-codes";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MessageDialog from "../../component/MessageDialog";
import { getCurrentUser, logout } from "../../service/dashboard/AuthService";
import { getRecommenderLink } from "../../service/dashboard/AffiliateService";
import QRCode from "react-qr-code";
// https://www.npmjs.com/package/react-qr-code
import {
    EmailShareButton,
    EmailIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    ViberShareButton,
    ViberIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";
// https://www.npmjs.com/package/react-share
import pageTeaserRecommenderLinkImage from "../../asset/page-teaser-recommenderlink.png";
import "../../css/RecommenderLink.css";

const RecommanderLink: React.FC = () => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("recommenderlink"));

    let navigate = useNavigate();
    const [recommenderLink, setRecommenderLink] = React.useState<string>("");
    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);

    useEffect(() => {
        // console.log("RecommanderLink useEffect is called");
        getRecommenderLink(getCurrentUser().email).then(
            (response) => {
                setRecommenderLink(response.data);
            },
            (error) => {
                // console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    }, [navigate]);

    const socialTitle = "Regisztráljon a Zepter Club hűségprogramba";

    return (
        <>
            <MessageDialog
                messageDialogError="false"
                messageDialogShow={messageDialogShow}
                messageDialogHeader="Értesítés"
                messageDialogBody="A Zepter Club ajánlói link sikeresen felmásolva a vágólapra."
            />
            <div className="border-2 border-dashed surface-border border-round surface-section flex-auto p-3 md:p-4 lg:p-5">
                <div className="surface-card border-round shadow-2 p-2 md:p-4 lg:p-5">
                    <div className="text-xl text-900 font-medium mb-3 ml-2">
                        Ajánlói linkem
                    </div>
                    <div className="">
                        <img
                            src={pageTeaserRecommenderLinkImage}
                            className="flex align-items-center justify-content-center text-center responsive fadeinleft animation-duration-500 md:hidden"
                            alt="bizzclub.hu ajánlói link kép"
                        />
                    </div>
                    <p className="ml-2">
                        Egyedi linkjét elküldheti barátainak, ismerőseinek
                    </p>
                    <div className="grid">
                        <div className="col-12 md:col-6">
                            <ul className="list-none">
                                <li className="line-height-4">SMS-ben,</li>
                                <li className="line-height-4">Skype-on,</li>
                                <li className="line-height-4">
                                    {/* TODO */}
                                    {false &&
                                    recommenderLink &&
                                    recommenderLink !== "" ? (
                                        <FacebookMessengerShareButton
                                            url={recommenderLink}
                                            appId="521270401588372"
                                            className="social-network-share-button"
                                        >
                                            <FacebookMessengerIcon
                                                size={32}
                                                round
                                            />
                                        </FacebookMessengerShareButton>
                                    ) : null}
                                    Messenger-en,
                                </li>
                                <li className="flex align-content-start">
                                    <div className="flex align-items-center justify-content-center">
                                        {recommenderLink &&
                                        recommenderLink !== "" ? (
                                            <ViberShareButton
                                                url={recommenderLink}
                                                title={socialTitle}
                                                className="social-network-share-button"
                                            >
                                                <ViberIcon
                                                    className="mt-1 mr-1"
                                                    size={32}
                                                    round
                                                />
                                            </ViberShareButton>
                                        ) : null}
                                        Viber-en,
                                    </div>
                                </li>
                                <li className="flex align-content-start">
                                    <div className="flex align-items-center justify-content-center">
                                        {recommenderLink &&
                                        recommenderLink !== "" ? (
                                            <WhatsappShareButton
                                                url={recommenderLink}
                                                title={socialTitle}
                                                separator=":: "
                                                className="social-network-share-button"
                                            >
                                                <WhatsappIcon
                                                    className="mt-1 mr-1"
                                                    size={32}
                                                    round
                                                />
                                            </WhatsappShareButton>
                                        ) : null}
                                        WhatsApp-on,
                                    </div>
                                </li>
                                <li className="flex align-content-start">
                                    <div className="flex align-items-center justify-content-center">
                                        {recommenderLink &&
                                        recommenderLink !== "" ? (
                                            <LinkedinShareButton
                                                url={recommenderLink}
                                                className="social-network-share-button"
                                            >
                                                <LinkedinIcon
                                                    className="mt-1 mr-1"
                                                    size={32}
                                                    round
                                                />
                                            </LinkedinShareButton>
                                        ) : null}
                                        LinkedIn-en,
                                    </div>
                                </li>
                                <li className="flex align-content-start">
                                    <div className="flex align-items-center justify-content-center">
                                        {recommenderLink &&
                                        recommenderLink !== "" ? (
                                            <EmailShareButton
                                                url={recommenderLink}
                                                subject={socialTitle}
                                                body=""
                                                className="social-network-share-button"
                                            >
                                                <EmailIcon
                                                    className="mt-1 mr-1"
                                                    size={32}
                                                    round
                                                />
                                            </EmailShareButton>
                                        ) : null}
                                        e-mailben vagy
                                    </div>
                                </li>
                                <li className="flex align-content-start">
                                    <div className="flex align-items-center justify-content-center">
                                        {recommenderLink &&
                                        recommenderLink !== "" ? (
                                            <div className="Demo__some-network">
                                                <FacebookShareButton
                                                    url={recommenderLink}
                                                    quote={socialTitle}
                                                    className="social-network-share-button"
                                                >
                                                    <FacebookIcon
                                                        className="mt-1 mr-1"
                                                        size={32}
                                                        round
                                                    />
                                                </FacebookShareButton>
                                            </div>
                                        ) : null}{" "}
                                        egyszerűen posztolja ki a saját Facebook
                                        oldalára.
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 md:col-6">
                            <img
                                src={pageTeaserRecommenderLinkImage}
                                className="hidden align-items-center justify-content-center text-center responsive fadeinleft animation-duration-500 md:flex"
                                alt="bizzclub.hu ajánlói link kép"
                            />
                        </div>
                    </div>
                    {recommenderLink && recommenderLink !== "" ? (
                        <>
                            <div className="flex flex-wrap align-items-start p-3 mr-2 bg-green-100 border-round border-1 border-green-300">
                                <p className=" m-0 p-0 pt-0 md:pt-3 text-green-700 line-height-5">
                                    {recommenderLink}
                                </p>
                                <div className="m-auto text-green-900 pt-3 md:pt-0">
                                    <CopyToClipboard text={recommenderLink}>
                                        <Button
                                            className="p-button-text"
                                            autoFocus
                                            onClick={() => {
                                                setMessageDialogShow(
                                                    Date.now()
                                                );
                                            }}
                                        >
                                            <i className="pi pi-copy text-green-900 text-2xl mr-2" />
                                            <span className="text-green-900">
                                                Másolás
                                            </span>
                                        </Button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                            <p className="mt-4 ml-2">
                                Egyedi ajánlói linkjét az alábbi QR kódban is
                                megtalálja:
                            </p>
                            <div
                                className="text-center"
                                style={{ background: "white", padding: "16px" }}
                            >
                                <QRCode value={recommenderLink} />
                            </div>
                        </>
                    ) : (
                        <>
                            <span className="mb-2">
                                Ajánló link betöltése, kérem várjon
                            </span>
                            <ProgressBar
                                className=" m-0 p-0 pt-0 md:pt-3 mr-2"
                                mode="indeterminate"
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default RecommanderLink;
