import React from "react";
import "../../css/GeneralTerms.css";
import pageGeneralTermsImage from "../../asset/page-teaser-general-terms.png";

const DataHandling220615: React.FunctionComponent<any> = (props: any) => {
    return (
        <>
            <div className="px-4 md:px-6 lg:px-8">
                <div className="flex flex-wrap shadow-2">
                    <div className="w-full lg:w-12 px-3 py-3 lg:p-7 bg-blue-50">
                        <div className="mt-0 mb-2 flex justify-content-center">
                            <div className="w-auto md:w-8">
                                <img
                                    src={pageGeneralTermsImage}
                                    className="responsive fadeinleft animation-duration-500"
                                    alt="bizzclub.hu adatkezelési tájékoztató kép"
                                />
                            </div>
                        </div>
                        <div className="text-xl text-black-alpha-90 font-500 mb-3 text-center">
                            ADATKEZELÉSI TÁJÉKOZTATÓ
                        </div>
                        Adatkezelési tájékoztató Zepter Ungarn Kft.-vel online
                        kötött adásvételi szerződéshez kapcsolódó
                        adatkezelésről:
                        <ol>
                            <li>
                                Adatkezelő: Zepter Ungarn Kft. (1138 Budapest,
                                Váci út 191.,{" "}
                                <a href="mailto:adat@zepter.hu">
                                    adat@zepter.hu
                                </a>
                                , a továbbiakban “Zepter”.).
                            </li>
                            <li>
                                Adatfeldolgozás: Hitelközvetítési tevékenység
                                esetében Zepter, mint adatfeldolgozó jár el, a
                                pénzintézettel, mint adatkezelővel kötött
                                adatfeldolgozási szerződés alapján.
                            </li>
                            <li>
                                Adatvédelmi tisztviselő: Név: dr. Kovács Attila
                                Krisztián E-mail:{" "}
                                <a href="mailto:adat@zepter.hu">
                                    adat@zepter.hu
                                </a>
                            </li>
                            <li>
                                Zepter feldolgozás céljából átadja az adatokat
                                különböző címzetteknek. Ahol lehetséges, ott a
                                címzettek név szerint kerülnek megjelölésre,
                                ahol az nem lehetséges, ott a címzettek jól
                                meghatározott körét jelöljük meg.
                            </li>
                            <li>
                                Adatkezelés célja, jogalapja, kezelt adatok köre
                                (adatkategóriák), adatkezelés időtartama:
                                <table>
                                    <tr>
                                        <th></th>
                                        <th>Adatkezelés célja</th>
                                        <th>Adatkezelés jogalapja</th>
                                        <th>Adatkategória</th>
                                        <th>Adatkezelés időtartama</th>
                                        <th>GDPR 13. Cikk (2) e.</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span>1.</span>
                                        </td>
                                        <td>
                                            <span>
                                                Adásvételi szerződés
                                                teljesítése:
                                            </span>{" "}
                                            Zepter tevékenységével
                                            összefüggésben kötött vevői
                                            szerződés teljesítése, így különösen
                                            a termékre vonatkozó tájékoztatás
                                            nyújtása, termék kiszállítása,
                                            beüzemelése, összvásárlói szokások
                                            elemzése, célzottabb kiszolgálás
                                            biztosítása, vevői elégedettség
                                            mérés, adategyeztetés, szerződés
                                            teljesítéséhez szükséges vevői
                                            intézkedés megtételére felhívás,
                                            szerződésmódosítás, helyettesítő
                                            termék felajánlása, vevői
                                            nyilvántartás vezetés, egyes
                                            termékek esetében fertőtlenítés
                                            elvégzésének nyomonkövetése,
                                            tájékoztatás és felhívás közlése a
                                            fertőtlenítés tekintetében.
                                        </td>
                                        <td>
                                            GDPR 6.§. (1) b. pont – Szerződés
                                            teljesítése
                                        </td>
                                        <td>
                                            teljes név, lakcím, számlázási cím;
                                            szállítási cím, telefonszám,
                                            születési idő, e-mail cím, termék,
                                            szerződésszám, IP cím
                                        </td>
                                        <td>
                                            szerződés megszűnésétől számított 5
                                            év (elévülés) + 60 nap
                                        </td>
                                        <td>
                                            szerződés teljesítésének feltétele
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span>2.</span>
                                        </td>
                                        <td>
                                            <span>Számlázás:</span> Szerződéshez
                                            kapcsolódó vételár beszedés, vételár
                                            elszámolása, számla kiállítása,
                                            bizonylatolás, téves befizetés
                                            kezelése, pénzügyi nyilvántartás
                                            vezetés.
                                        </td>
                                        <td>
                                            GDPR 6.§. (1) c. - Jogi
                                            kötelezettség teljesítése; Számv.
                                            tv. 169. § (2)
                                        </td>
                                        <td>
                                            teljes név, lakcím, születési idő,
                                            számla száma, szerződésszám, számla
                                            adatok
                                        </td>
                                        <td>
                                            számla kiállításától számított 8 év
                                            + 60 nap
                                        </td>
                                        <td>jogszabályi kötelezettség</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span>3.</span>
                                        </td>
                                        <td>
                                            <span>
                                                Ügyfélszolgálati adatkezelés:
                                            </span>{" "}
                                            Vevők megkülönböztetése, az
                                            esetleges fogyasztói visszajelzések
                                            kezelése, panaszügyek kezelése,
                                            szerződéssel kapcsolatos elállási
                                            ügyek kezelése, ügyfélszolgálati
                                            tevékenység ellátása, pénzügyi
                                            nyilvántartás, kapcsolattartás,
                                            telefonos panasz nyilvántartása,
                                            telefonon bejelentett panasz
                                            rögzítése, panasz nyilvántartás,
                                            telefonon bejelentett panasz
                                            nyilvántartása
                                        </td>
                                        <td>
                                            GDPR 6.§. (1) c. - Jogi
                                            kötelezettség teljesítése; Fgytv.
                                            17/A. § (7) bekezdése; Hpt. 288.§.
                                        </td>
                                        <td>
                                            teljes név, lakcím, szállítási cím,
                                            telefonszám, születési idő, e-mail
                                            cím, szerződésszám, telefonos
                                            bejelentett panasz nyilvántartási
                                            száma, panasz ügy nyilvántartási
                                            száma
                                        </td>
                                        <td>
                                            elállástól számított 5 év (elévülés)
                                            + 60 nap; panasz bejelentésétől
                                            számított 3 év (elévülés) + 60 nap;
                                            telefonon bejelentett panasz
                                            megtételétől számított 5 év + 60 nap
                                        </td>
                                        <td>jogszabályi kötelezettség</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span>4.</span>
                                        </td>
                                        <td>
                                            <span>
                                                Szavatossági és jótállási ügyek
                                                kezelése:
                                            </span>{" "}
                                            Az adásvételi szerződések esetén
                                            jogszabály alapján fennálló, vagy
                                            Zepter vállalása alapján nyújtott
                                            jótállási kötelezettségek
                                            teljesítése, szavatossági
                                            kötelezettségek teljesítése,
                                            nyilvántartása, kapcsolattartás a
                                            jogosulttal, szállítás.
                                        </td>
                                        <td>
                                            GDPR 6.§. (1) b. pont – Szerződés
                                            teljesítése ; GDPR 6.§. (1) c. -
                                            Jogi kötelezettség teljesítése;
                                            151/2003. (IX. 22.) Korm. rendelet
                                        </td>
                                        <td>
                                            teljes név, lakcím, szállítási cím,
                                            telefonszám, születési idő, e-mail
                                            cím, szerződésszám, termék, számla
                                            száma, jótállási jegy száma
                                        </td>
                                        <td>
                                            szavatosság esetén a termék
                                            átadásától számított 2 év (elévülés)
                                            + 30 nap; Zepter által szerződés
                                            alapján vállalt jótállás esetén a
                                            termék átadásától számítottan a
                                            jótállás időtartama + 60 nap;
                                            kötelező jótállás esetén a termék
                                            átadásától számított 1 év + 60 nap
                                        </td>
                                        <td>jogszabályi kötelezettség</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span>5.</span>
                                        </td>
                                        <td>
                                            <span>
                                                Követelés érvényesítése és jogi
                                                tanácsadás és képviselet vitás
                                                ügyben:
                                            </span>{" "}
                                            Ki nem fizetett vételár,
                                            szerződéssel kapcsolatban felmerült
                                            kár-, kötbér, költség követelés
                                            érvényesítése. A vevő vagy Zepter
                                            által kezdeményezett bármely a
                                            szerződést érintő jogvita esetében
                                            jogi tanácsadás, képviselet.
                                        </td>
                                        <td>
                                            GDPR 6.§. (1) b. pont – Szerződés
                                            teljesítése GDPR 6.§. (1) f. pont –
                                            Jogos érdek
                                        </td>
                                        <td>
                                            teljes név, lakcím, születési idő,
                                            számla száma, szerződésszám, számla
                                            adatok
                                        </td>
                                        <td>
                                            követelés esedékességétől számított
                                            5 év (elévülés) + 60 nap
                                        </td>
                                        <td>szerződés teljesítése</td>
                                    </tr>
                                </table>
                            </li>
                            <li>
                                <p>Címzettek:</p>
                                <ul>
                                    <li>
                                        Futárszolgálat részére az árukiszállítás
                                        céljából. – GLS General Logistics
                                        Systems Hungary Kft.
                                    </li>
                                    <li>
                                        Terméktípustól függően a termék
                                        beszerelése és beüzemelése céljából,
                                        illetve víztisztító készülékek
                                        rendszeres, 3 havonkénti
                                        fertőtlenítésének elvégzésére felhívás,
                                        a fertőtlenítés elvégzése valamint a
                                        fertőtlenítés nyomonkövetése
                                        alvállalkozó részére továbbítás. –
                                        Waterminator Kft.
                                    </li>
                                    <li>
                                        Követelés érvényesítése és jogi ügyek
                                        kezelése: dr. Soós Ádám Ügyvédi Iroda
                                    </li>
                                    <li>
                                        Információtechnológiai szolgáltatás –
                                        Zepter Poland
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>Érintett jogai:</p>
                                <u>A helyesbítéshez való jog:</u> Az adatai
                                pontosítását, javítását, helyesbítését kérheti.
                                <br />
                                <u>A törléshez való jog:</u> Bizonyos esetekben
                                kérheti, hogy személyes adatai törlésre
                                kerüljenek, így az eredeti adatkezelési cél
                                érdekében már nincs szükség az adatai
                                tárolására, vagy hozzájáruláson alapuló
                                adatkezelés esetén visszavonja hozzájárulását,
                                feltéve, hogy az adatok törlését jogszabály vagy
                                egyéb jogi kötelezettség teljesítése nem zárja
                                ki.
                                <br />
                                <u>
                                    Az adatkezelés korlátozásához való jog:
                                </u>{" "}
                                Bizonyos esetekben joga van kérni az adatok
                                zárolását (fel nem használását) valamely
                                intézkedés megtörténtének, vagy joga
                                gyakorlásának elbírálásáig.
                                <br />
                                <u>Adathordozhatósághoz való jog:</u> Önnek joga
                                van kérni elektronikus adatkezelés esetén, hogy
                                adjuk át személyes adatait az Ön által kijelölt
                                harmadik fél részére. Az adatokat „.xls” vagy
                                „.pdf” fájlformátumban adjuk ki.
                                <br />
                                <u>Tiltakozáshoz való jog:</u> Ön tiltakozhat
                                adatai kezelése ellen, amennyiben úgy látja,
                                egyes adatkezelési cél érdekében már
                                szükségtelen a további adatkezelés, kivéve ha az
                                adatkezelő igazolja, hogy az adatkezelés
                                jogszerű és szükséges.
                            </li>
                            <li>
                                <p>
                                    Ön jogosult az adatkezeléssel szemben
                                    panasszal a Felügyeleti hatósághoz fordulni.
                                    A felügyeleti hatóság a Nemzeti Adatvédelmi
                                    és Információszabadság Hatóság.
                                </p>
                                <p>
                                    Nemzeti Adatvédelmi és Információszabadság
                                    Hatóság elérhetőségei: posta cím: 1363
                                    Budapest, Pf.: 9.; cím: 1055 Budapest, Falk
                                    Miksa utca 9-11., Telefon: +36 (1) 391-1400;
                                    Fax: +36 (1) 391-1410, E-mail:{" "}
                                    <a href="mailto:ugyfelszolgalat@naih.hu">
                                        ugyfelszolgalat@naih.hu
                                    </a>
                                    ; URL:{" "}
                                    <a
                                        href="https://naih.hu"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://naih.hu
                                    </a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Az adatok megadása Ön részéről adásvételi
                                    szerződésből eredő kötelezettségen alapul,
                                    ugyanis az adásvétel teljesítéséhez
                                    szükséges Zepter által kért adata. A
                                    szerződéskötésnek feltétele a szerződéses
                                    adatok megadása. A szerződés teljesítéséhez
                                    szükséges adat nélkül a szerződés Zepter
                                    részéről nem teljesíthető.
                                </p>
                                <p>
                                    Amennyiben Ön hitellel kívánja finanszírozni
                                    az adásvételt, úgy Zepter a többes ügynöki
                                    tevékenysége teljesítéséhez a megjelölt
                                    adatkezelő bankok részére begyűjti és átadja
                                    az Ön adatait. A hitelszerződés
                                    teljesítésének feltétele a kért adatok
                                    megadása, ennek elmaradása esetén a bank
                                    megtagadhatja a hitelezést és így
                                    meghiúsulhat az adásvételi szerződés is.
                                </p>
                            </li>
                            <li>
                                Zepter profilalkotást nem végez, automatizált
                                döntéshozatalra nem kerül sor.
                            </li>
                            <li>
                                A személyes adatok kezelésére a{" "}
                                <a
                                    href="https://www.zeptermagazin.hu/wp-content/uploads/2018/05/Zepter-adatvedelmi-es-adatkezelesi-szabalyzat.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    https://www.zeptermagazin.hu/wp-content/uploads/2018/05/Zepter-adatvedelmi-es-adatkezelesi-szabalyzat.pdf
                                </a>{" "}
                                oldalon elérhető adatvédelmi szabályzata
                                valamint a természetes személyeknek a személyes
                                adatok kezelése tekintetében történő védelméről
                                és az ilyen adatok szabad áramlásáról, valamint
                                a 95/46/EK rendelet hatályon kívül helyezéséről
                                szóló az EURÓPAI PARLAMENT ÉS A TANÁCS (EU)
                                2016/679 RENDELETE (“GDPR” vagy “általános
                                adatvédelmi rendelet”) az irányadó.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DataHandling220615;
