import React from "react";
import "../../css/GeneralTerms.css";

const GeneralTerms220615: React.FunctionComponent<any> = (props: any) => {
    return (
        <>
            {/* <div className="px-4 md:px-6 lg:px-8"> */}
            <div className="flex flex-wrap shadow-2">
                <div className="w-full lg:w-12 px-3 py-3 lg:p-7 bg-blue-50">
                    <div className="text-xl text-black-alpha-90 font-500 mt-3 mb-3 text-center">
                        "My Basics ösztönző program
                        <br />
                        2022.06.14-től-tól 2022.12.31.ig"
                    </div>
                    <p>
                        A Zepter Ungarn Kft. (1138 Budapest, Váci út 191., a
                        továbbiakban „Zepter”) a Club Live 100 hűségprogramban
                        résztvevő My Basics tanácsadók részére a következő
                        ösztönző programot hirdeti 2022.06.14 - 2022.12.31.
                        közötti időszakban. A „My Basics ösztönző program”
                        esetében irányadó a Club Live 100 hűségprogram
                        feltételei és ennek keretében a My Basics tanácsadói
                        feltételek, és a feltételek által alkalmazott fogalmak.
                        A megjelölt időszakon kívül teljesített feltételek nem
                        jogosítanak a programban megjelölt termékek, jutalmak,
                        bónuszok, jutalékok (a továbbiakban „jutalom”)
                        érvényesítésére. A nyereményjátékban a vonatkozó
                        nyereményjáték feltételek, valamint adatkezelési
                        feltételek elfogadása kötelező, és ennek hiánya a
                        nyereményjátékból történő kizárást vonja maga után. A
                        programban történő részvétel feltétele Club Live 100
                        hűségprogram feltételeinek és ennek keretében a My
                        Basics tanácsadói feltételek elfogadása, a Club Live 100
                        tagság megléte az ösztönző program teljes tartama alatt,
                        mindezek hiányában a részvétel az ösztönző programban
                        kizárt, és a teljesített feltételek érvénytelenek. A
                        jogosulatlanul, illetve a Club Live 100 hűségprogram
                        feltételei illetve ennek részeként a My Basics
                        tanácsadói feltételek megkerülésével, kijátszásával
                        igénybe vett jutalmat a jogosulatlanul igénybe vevő
                        köteles visszatéríteni Zepter részére. A visszatérítés
                        alapja megszerzett termékek esetén minden esetben Zepter
                        az ösztönző program kezdő napján érvényes Zepter termék
                        listaára, egyéb jutalom esetén a kiírásban szereplő
                        érték, összeg. Zepter a feltételek teljesülése esetén az
                        ösztöntő program feltételeiben foglalt jutalmakon túl
                        további jutalom, összeg, termék, bónusz, kedvezmény,
                        díj, jutalék, költségtérítés stb. juttatására,
                        megfizetésére nem kötelezhető. Zepter az ösztönző
                        programot előzetes értesítés nélkül bármikor jogosult
                        megszüntetni, ilyen esetben Zepter a megszüntetésig
                        teljesített feltételek után járó jutalmak kiadására,
                        megfizetésére vállal kötelezettséget. Zepter kizárja
                        kártérítési felelősségét a program visszavonásával
                        összefüggésben.
                    </p>
                    <span>
                        <u>My Basics ösztönző program feltételek:</u>
                    </span>
                    <p>
                        My Basics beléptetésnek minősül a My Basics Tanácsadó
                        által a
                        <a
                            href="https://bizzclub.hu/login"
                            target="_blank"
                            rel="noreferrer"
                        >
                            www.bizzclub.hu
                        </a>
                        rendszeren keresztül a Club Live 100 hűségprogramba
                        történő meghívás, majd a meghívott részéről a
                        csatlakozás a Club Live 100 hűségprogramba, Club Live
                        100 taggá válás, valamint a My Basics Tanácsadói
                        feltételek elfogadása.
                    </p>
                    <p>
                        My Basics forgalomnak minősül, ha a Tanácsadó által
                        beléptetett Tanácsadó közreműködésével egy Klubtag új My
                        Basics Tanácsadóvá válik, és a
                        <a
                            href="https://bizzclub.hu/login"
                            target="_blank"
                            rel="noreferrer"
                        >
                            www.bizzclub.hu
                        </a>
                        oldalon My Basics terméket vásárol.
                    </p>
                    <p>
                        Új Klubtagnak, és új My Basics Tanácsadónak az minősül,
                        aki a csatlakozási igényt megelőzően nem volt Klubtag,
                        vagy Tanácsadó.
                    </p>
                    <p>
                        Aktív Tanácsadónak az minősül, aki My Basics terméket
                        vásárol, és a termék vételára kiegyenlítésre kerül.
                    </p>
                    <ol>
                        <li>
                            <span>
                                3 új My Basics tanácsadó - extra jutalom
                            </span>
                            <p>
                                Ha egy My Basics Tanácsadó a belépésétől
                                számított két Zepter hónap alatt (2x28 nap) 3 új
                                My Basics Tanácsadót léptet be, akik bármekkora
                                My Basics forgalommal rendelkeznek, akkor a
                                Tanácsadó az alábbi termékre jogosult, melyet
                                Zepter a Tanácsadó következő rendelése mellé ad
                                ki extra bónuszként:
                            </p>
                            <ul>
                                <li>
                                    <strong>SF-DGW Digestiwell 24 Tabs;</strong>
                                </li>
                            </ul>
                        </li>
                        <br />
                        <li>
                            <span>
                                7 új My Basics tanácsadó – további 2 extra
                                jutalom
                            </span>
                            <p>
                                Ha egy My Basics Tanácsadó a belépésétől
                                számított két Zepter hónap alatt (2x28 nap) 7 új
                                My Basics Tanácsadót léptet be, akik bármekkora
                                My Basics forgalommal rendelkeznek, akkor a
                                Tanácsadó a jelen feltételek 1. pontjában
                                meghatározott jutalmakon túl további
                                termék(ek)re jogosult, melyet Zepter a Tanácsadó
                                következő rendelése mellé ad ki extra
                                bónuszként:
                            </p>
                            <ul>
                                <li>
                                    <strong>SF-CF Coldfree 20 Tabs;</strong>
                                </li>
                                <li>
                                    <strong>SF-DMW Dermawell 40g;</strong>
                                </li>
                            </ul>
                        </li>
                        <br />
                        <li>
                            <span>
                                7 új My Basics tanácsadó, fejenként legalább
                                77.000,- Ft My Basics vásárlás esetén további
                                extra jutalom
                            </span>
                            <p>
                                Ha egy My Basics Tanácsadó a belépésétől
                                számított két Zepter hónap alatt (2x28 nap) 7 új
                                My Basics Tanácsadót léptet be, akik legalább
                                77.000,- Ft My Basics forgalommal rendelkeznek,
                                akkor a Tanácsadó a jelen feltételek 1-2.
                                pontjaiban meghatározott jutalmakon túl további
                                termékre jogosult, melyet Zepter a Tanácsadó
                                következő rendelése mellé ad ki extra
                                bónuszként:
                            </p>
                            <ul>
                                <li>
                                    <strong>HM-2 HyperOn készülék;</strong>
                                </li>
                            </ul>
                        </li>
                        <br />
                        <li>
                            <span>
                                7 új My Basics tanácsadó – 3 aktív belépő
                            </span>
                            <p>
                                Ha egy My Basics Tanácsadó 2022. december 31-ig
                                rendelkezik 7 olyan általa beléptetett My Basics
                                Tanácsadóval, akik bármekkora My Basics
                                forgalommal aktívvá váltak és rendelkeznek
                                fejenként legalább 3 új My Basics Tanácsadóval,
                                akik szintén bármekkora My Basics forgalommal
                                aktívvá váltak, Tanácsadó a jelen feltételek
                                1-3. pontjaiban meghatározott jutalmakon túl
                                további termékre jogosult, melyet Zepter a
                                Tanácsadó következő rendelése mellé ad ki extra
                                bónuszként:
                            </p>
                            <ul>
                                <li>
                                    <strong>DP-P Pozitúra párna;</strong>
                                </li>
                            </ul>
                        </li>
                        <br />
                        <li>
                            <span>7 ember – 7 csomag</span>
                            <p>
                                Ha egy My Basics Tanácsadó 2022. december 31-ig
                                rendelkezik 7 olyan általa beléptetett My Basics
                                Tanácsadóval, akik bármekkora My Basics
                                forgalommal aktívvá váltak és rendelkeznek
                                fejenként legalább 7 új My Basics Tanácsadóval,
                                akik legalább 77.000,- Ft My Basics forgalommal
                                aktívvá váltak, Tanácsadó a jelen feltételek
                                1-3. pontjaiban meghatározott jutalmakon túl
                                további ajándékra jogosult:
                            </p>
                            <ul>
                                <li>
                                    <span>
                                        két éjszakás Wellness hétvége 2 fő
                                        részére a Visegrádi Hotel Silvanusban,
                                        vagy választás szerint
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        egy db Zepter HyperLight szemüveg
                                    </span>
                                </li>
                            </ul>
                        </li>
                        <br />
                        <li>
                            <span>
                                Dubai álomnyaralás! 7 hónap – 35 közvetlen
                                belépő & 20 millió forint forgalom
                            </span>
                            <p>
                                Az alábbi feltétetek teljesülése esetén a
                                Tanácsadó jogosulttá válik egy Dubai nyaralásra,
                                két fő részére.
                            </p>
                            <p>
                                A Tanácsadó legalább 35, általa beléptetett új
                                My Basics Tanácsadóval rendelkezik, akik
                                bármekkora My Basics forgalommal aktívvá váltak.
                            </p>
                            <p>
                                A Tanácsadó 20 millió forint számlázott
                                forgalmat elérte. A forgalomba beleszámít
                            </p>
                            <ul>
                                <li>
                                    <span>
                                        minden, a Tanácsadó által közvetlenül
                                        beléptetett új My Basics tanácsadó
                                        vásárlása és abban az esetben, ha
                                        időközben Zepter Kereskedővé válik,
                                        akkor bármely Zepter termék értékesítése
                                        is;
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        minden régi, a Tanácsadó által
                                        beléptetett Klubtagból a Tanácsadó által
                                        beléptetett MyBasics tanácsadó által
                                        beléptetett új MyBasics tanácsadók
                                        vásárlása és abban az esetben, ha
                                        időközben Zepter Kereskedővé válik,
                                        akkor bármely Zepter termék értékesítése
                                        is;
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        a Tanácsadó által saját maga részére
                                        vásárolt termékből eredő személyes
                                        forgalom nem számít bele a 20 millió
                                        forint forgalomba;
                                    </span>
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <hr />
                    <div className="text-xl text-black-alpha-90 font-500 mb-3 text-center">
                        <h2>NYEREMÉNYJÁTÉK</h2>
                        <span>Vegyél részt a sorsoláson és utazz Dubaiba!</span>
                        <p>
                            <span>
                                Játékszabályzat Zepter My Basics ösztönző
                                programhoz kapcsolódó nyereményjátékhoz
                                Játékszabályzat 2022.06.14. – 2022.12.31.
                            </span>
                        </p>
                    </div>
                    <ol type="I">
                        <li>
                            <span>A Játék</span>
                            <p>
                                Zepter Ungarn Kft. (Zepter Ungarn Kft.,
                                székhely: 1138 Budapest, Váci út 191..; Cg.:
                                01-09-076039; adószám: 10537361-2-41; a
                                továbbiakban: „Szervező” vagy „Zepter”)
                                nyereményjátékot szervez (a továbbiakban:
                                Nyereményjáték) a Club Live 100 hűségprogramban
                                és ennek keretében a My Basics programban részt
                                vevő, Tanácsadók részére. A Nyereményjáték
                                célja, hogy a My Basics ösztönző program
                                eredményességének növelése. A Nyereményjátékban
                                történő részvétel feltétele a jelen
                                Nyereményjáték feltételek elfogadása. A
                                Nyereményjátékban minden My Basics Tanácsadó
                                részt vesz, aki kifejezetten nem nyilatkozott a
                                részvétel elutasításáról, és teljesítette a
                                Nyereményjáték feltételeit.
                            </p>
                        </li>
                        <li>
                            <span>Nyereményjátékban résztvevő személyek</span>
                            <p>
                                A Nyereményjátékban részt vehet minden 18.
                                életévét betöltött devizabelföldi, magyar
                                állampolgár, természetes személy, továbbá jogi
                                személy vagy jogi személyiséggel nem rendelkező
                                társaság, amennyiben My Basics Tanácsadónak
                                minősül és teljesíti a nyereményjáték
                                feltételeit, kivéve a Szervező megbízásából a
                                Nyereményjáték előkészítésében és
                                lebonyolításában közreműködő személyek és cégek
                                vezető tisztségviselői, munkavállalói,
                                megbízottjai, valamint ezen személyeknek a Ptk.
                                fentebb hivatkozott közeli hozzátartozói.
                            </p>
                        </li>
                        <li>
                            <span>Feltételek, időtartam</span>
                            <p>
                                Nyereményjáték 2022.06.14. 12:00 órától
                                2022.12.31. 23:59 óráig tart.
                                <br />A Nyereményjáték megkezdése előtt, és a
                                Nyereményjáték befejezése után teljesített
                                feltételek érvénytelenek.
                            </p>
                            <p>
                                A Nyereményjáték 2022.09.15. napjától további
                                feltételekkel és nyereményekkel bővül. Az új
                                feltételek nem érintik a korábban közzétett
                                feltételeket és Nyereményt.
                            </p>
                            <p>
                                Nyereményjátékon történő részvétel feltétele:{" "}
                            </p>
                            <p>
                                Minden Club Live 100 Klubtag, aki 2022.12.31-ig
                                7 MyBasics Tanácsadót léptet be, az részt vesz
                                egy sorsoláson, ahol megnyerhet egy Dubai
                                álomutazást 2 fő részére. A nyereményjátékban 7
                                MyBasics Tanácsadó beléptetése esetén minden
                                további 3 új MyBasics tanácsadónként a Játékos
                                további plusz egy (1) kupont kap a sorsolási
                                esélyek növelése érdekében.
                            </p>
                            <p>
                                Minden Club Live 100 Klubtag, aki legalább 2, 3,
                                vagy 5 új MyBasics Tanácsadót léptet be
                                2022.12.31-ig tárgynyeremény sorsolásokon vesz
                                részt. A Nyereményjátékban történő részvétel
                                során a MyBasics Tanácsadót vásárlási
                                kötelezettség továbbra sem terheli.
                            </p>
                            <p>
                                Az a Club Live 100 Klubtag, aki a Nyereményjáték
                                során 2022.06.14. napjától 2022.09.14. napjáig
                                már léptetett be új MyBasics Tanácsadót, de még
                                nem érte el a Dubai álomutazás nyeremény
                                sorsoláson történő részvételhez szükséges számú
                                beléptetett 7 új MyBasics Tanácsadót, úgy
                                beléptetett új MyBasics Tanácsadó száma szerint
                                (2, 3, 5) részt vesz a tárgynyeremény
                                sorsolásokon.
                            </p>
                            <p>
                                A beléptetett új MyBasics Tanácsadók számához
                                igazodva különböző nyeremény kategóriákban
                                történik a Játékos részvétele és Nyeremények
                                sorsolása:
                            </p>
                            <ul>
                                <li>
                                    az a Club Live 100 Klubtag, aki első
                                    alkalommal belép MyBasics programba, és
                                    Tanácsadóvá válik, részt vesz az 1.
                                    kategória sorsolásán, 1 kuponnal;
                                </li>
                                <li>
                                    az a Club Live 100 Klubtag, aki legalább 2
                                    új MyBasics Tanácsadót léptet be, részt vesz
                                    az 1-2. kategória sorsolásán, nyeremény
                                    kategóriánként 1 kuponnal;
                                </li>
                                <li>
                                    a Club Live 100 Klubtag, aki legalább 3 új
                                    MyBasics Tanácsadót léptet be, részt vesz az
                                    1-3. kategória sorsolásán, nyeremény
                                    kategóriánként 1 kuponnal;
                                </li>
                                <li>
                                    a Club Live 100 Klubtag, aki legalább 5 új
                                    MyBasics Tanácsadót léptet be, részt vesz az
                                    1-4. kategória sorsolásán, nyeremény
                                    kategóriánként 1 kuponnal;
                                </li>
                                <li>
                                    a Club Live 100 Klubtag, aki legalább 7 új
                                    MyBasics Tanácsadót léptet be, részt vesz az
                                    1-5. kategória sorsolásán, 1-4. nyeremény
                                    kategóriánként 1 kuponnal, továbbá az 5.
                                    kategória nyeremény esetében minden további
                                    3 új MyBasics Tanácsadó beléptetése esetén
                                    az 5. kategóriában további 1
                                    nyereménykuponnal vesz részt.
                                </li>
                            </ul>
                            <br />
                        </li>
                        <li>
                            <span>Sorsolás menete</span>
                            <p>
                                <span>
                                    A Nyereményjáték sorsolás időpontja és
                                    helyszíne: 2023.01.25. 11:00 napja
                                    elektronikus sorsolással történik, Zepter
                                    budapesti székhelyén, a 1138 Budapest, Váci
                                    út 191. cím alatt. Szervező a nyeremény
                                    kategóriák sorrendjében 1 nyertest és 1
                                    tartalék nyertest húz.
                                </span>
                            </p>
                        </li>
                        <li>
                            <span>Sorsolás nyereménye</span>
                            <p>
                                A Szervező a sorsolás alkalmával 1 nyertest és 1
                                tartaléknyertest sorsol Nyeremény
                                kategóriánként. Amennyiben a nyertes bármely
                                okból nem jogosult a nyereményre, vagy 30 napon
                                belül nem elérhető, úgy a Szervező értesíti és
                                felkéri a nyeremény átvételére az adott
                                kategóriához kisorsolt, soron következő
                                tartaléknyertest.
                            </p>
                            <p>
                                A Szervező a sorsolást követően e-mailben
                                értesíti Nyertest a sorsolást követő 10 napon
                                belül.
                            </p>
                            <p>
                                A Nyeremény átadása a sorsolást követő 30 napon
                                belül történik
                            </p>
                            <p>
                                <span>Nyeremény kategóriák:</span>
                            </p>
                            <p>
                                Kategória: MyBasics programba történő belépés
                                esetén
                            </p>
                            <ul>
                                <li>
                                    1. díj: TAS-100 TherapyAir Smart 459.900 Ft
                                    értékben;
                                </li>
                                <li>
                                    2. díj: Z4L Bioptron MedoLight 299.900 Ft
                                    értékben;
                                </li>
                                <li>
                                    3. díj: BL-002 ArtMix Pro 299.900 Ft
                                    értékben;
                                </li>
                                <li>
                                    4. díj: ION-03 MyIonZ Pro 139.900 Ft
                                    értékben;
                                </li>
                                <li>
                                    5. díj: ION-02 MyIon 119.900 Ft értékben;
                                </li>
                                <li>
                                    6-10. díj: DP-P QuanoMed Pozitúra 79.900 Ft
                                    értékben.
                                </li>
                            </ul>
                            <p>
                                2. Kategória: 2 új MyBasics Tanácsadó
                                beléptetése
                            </p>
                            <ul>
                                <li>
                                    1 db ION-03 MyIonZ Pro készülék 139.900 Ft
                                    értékben;
                                </li>
                                <li>
                                    1 db KE-002 Indukciós teáskanna 100.200 Ft
                                    értékben;
                                </li>
                                <li>
                                    1 db DP-P QuanoMed Pozitúra párna 79.900 Ft
                                    értékben;
                                </li>
                                <li>
                                    1 db PNF-708 La Danza HyperLight Fusion
                                    öregedésgátló komplex 59.900 Ft értékben;
                                </li>
                                <li>
                                    1 db HM-2 HyperOn masszírozó 65.900 Ft
                                    értékben;
                                </li>
                            </ul>
                            <p>
                                3. Kategória: 3 új MyBasics Tanácsadó
                                beléptetése
                            </p>
                            <ul>
                                <li>
                                    1 db Kétszemélyes wellness kirándulása a
                                    visegrádi Silvanus Hotelbe (3 nap / 2 éj
                                    szállás és félpanziós ellátás);
                                </li>
                            </ul>
                            <p>
                                4. Kategória: 5 új MyBasics Tanácsadó
                                beléptetése
                            </p>
                            <ul>
                                <li>
                                    1 db Kétszemélyes hosszú hétvége az egyik
                                    Zepter Hotelben (3 nap / 2 éj szállás és
                                    félpanziós ellátás);
                                </li>
                            </ul>
                            <p>
                                5. Kategória: 7 új MyBasics Tanácsadó
                                beléptetése
                            </p>
                            <ul>
                                <li>
                                    1 db, két fős, 7 napos (6 éjszaka), Dubai
                                    nyaralás, 400.000,- Ft értékben
                                </li>
                            </ul>
                            <p>
                                A nyertesek a nyeremény átvételére az értesítést
                                követően, de legkésőbb a sorsolást követő 30
                                napon belül jogosultak. Amennyiben a nyeremény
                                átvételére jogosult Játékos a nyereményét a
                                rendelkezésére álló határidőn belül nem venné
                                át, úgy a Szervező a fentiek szerint értesíti a
                                tartaléknyertest. Tartaléknyertes kiesése esetén
                                a Szervező nem köteles további tartaléknyertest
                                sorsolni. A játékban résztvevők vállalják, hogy
                                nyertességük esetén jogosultságukat személyi
                                azonosító irattal igazolják, és együttműködnek a
                                Szervezővel annak érdekében, hogy a nyeremény
                                átadása legkésőbb az adott sorsolást követő 30
                                napon belül megtörténjen. Amennyiben a nyertes
                                cselekvőképességében korlátozott vagy
                                cselekvőképtelen, úgy a nyereményével
                                kapcsolatos érdemi ügyintézésre vagy a nyeremény
                                átvételére nevében kizárólag törvényes
                                képviselője járhat el.
                            </p>
                            <p>A nyeremény pénzre nem váltható át.</p>
                            <p>
                                A nyerteseket a nyereménnyel kapcsolatosan nem
                                terheli személyi jövedelemadó-fizetési
                                kötelezettség. A nyeremény átvételével és
                                felhasználásával összefüggésben esetlegesen
                                felmerülő egyéb költségek és kiadások (pl.
                                nyeremény átadásának helyszínére történő utazás
                                költsége) a nyertest terhelik.
                            </p>
                        </li>
                        <li>
                            <span>Egyéb rendelkezések</span>
                            <p>
                                Szervező a Játékkal kapcsolatos esetleges
                                módosításokat vagy annak visszavonását a
                                <a
                                    href="https://bizzclub.hu/login"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    www.bizzclub.hu
                                </a>{" "}
                                oldalon teszi közzé. Jelen nyereményjátékra a
                                szerencsejáték szervezéséről szóló 1991. évi
                                XXXIV. törvényben foglaltak irányadók.
                            </p>
                        </li>
                        <li>
                            <span>Adatkezeléshez hozzájárulás</span>
                            <p>
                                A Szervező minden személyes adatot, amelyhez a
                                Nyereményjáték során jutnak hozzá, bizalmasan és
                                a hatályos adatvédelmi szabályoknak, valamint
                                jelen Szabályzatban foglaltaknak megfelelően
                                kezelnek. Az adatkezelési tájékoztató a jelen
                                Szabályzat 1. sz. mellékletét képezi.
                            </p>
                        </li>
                        <li>
                            <span>Egyéb</span>
                            <p>Budapest, 2022. október 25.</p>
                            <p>Szervező</p>
                        </li>
                    </ol>
                    <hr />
                    <div className="text-xl text-black-alpha-90 font-500 mb-3 text-center">
                        1. számú Melléklet
                        <br />
                        <strong>Adatkezelési tájékoztató</strong>
                    </div>
                    <p>
                        Adatkezelési tájékoztató Zepter 2022.06.14. –
                        2022.12.31. között szervezett Nyereményjátékhoz
                        kapcsolódóan
                        <br />
                        <span>Adatkezelő:</span> Zepter Ungarn Kft. (székhely:
                        1138 Budapest, Váci út 191.; cjsz.: 01-09-076039;
                        Adószám: 10537361-2-41), a továbbiakban “Adatkezelő”.{" "}
                        <span>Adattovábbítás:</span> Adatkezelő által történő
                        adatfelvétel esetén az adatokat az adatkezelő kezeli. Az
                        adattovábbításra nem kerül sor (azaz nem kerül sor
                        harmadik országba történő adatátadásra).
                        <br />
                        <span>
                            Adatkezelés célja, jogalapja, kezelt adatok köre
                            (adatkategóriák), adatkezelés időtartama:
                            Adatkezelés célja:
                        </span>{" "}
                        <span>
                            Promóciós nyereményjáték lebonyolítása, játékosok
                            nyilvántartása, nyeremények átadása.
                        </span>{" "}
                        Adatkezelés jogalapja: GDPR 6.§. (1) b. és c. pont –
                        nyereményjátékra vonatkozó szerződés teljesítése,
                        résztvevők nyilvántartása, megkülönböztetése, sorsolás
                        szervezése, nyertes és tartaléknyertes nyilvántartása,
                        nyertes és/vagy tartaléknyertes nevének és
                        irányítószámának nyilvánosságra hozatala;
                        <br />
                        <span>Adatkategória:</span> teljes név, e-mail cím,
                        telefonszám, irányítószám, nyeremény esetén nyertes
                        adatai, termékadatok, nyereményjáték azonosítói; jogi
                        személyek vagy jogi személyiség nélküli szervezetek
                        esetében cégnév, adószám, székhely, kapcsolattartó neve,
                        e-mail cím és telefonszám. Adatkezelés időtartama: a
                        nyereményjáték lezárultától számított 1 év + 60 nap,
                        nyertes esetében a jótállási időt követő 5 év és 60 nap.
                        A sorsolást megelőzően a nyereményjátékból kilépés
                        esetén jogi kötelezettség teljesítése miatt 5 év + 60
                        nap.
                        <br />
                        <span>Adatfeldolgozó:</span> Adatkezelő munkatársai
                        és/vagy megbízottai végzik a jelen hozzájárulás alapján
                        átadott adatokkal kapcsolatos tevékenységeket. Bizonyos
                        esetekben, az erről történő megfelelő tájékoztatás
                        mellett Adatfeldolgozó a címzettek csoportjait jelöli
                        meg, mint lehetséges adatfeldolgozó. Címzettek egy
                        csoportja esetében a csoport tagjai az adatkezelési
                        időtartam alatt a gazdasági szükségszerűség miatt akár
                        több alkalommal is változhatnak, a csoport tagjainak
                        fluktuációja miatt pedig Adatkezelő valamennyi
                        adatgyűjtést megelőzően nem, vagy nem teljeskörűen,
                        avagy nem átlátható (jelentős mennyiségre figyelemmel)
                        módon tudná csak tájékoztatni az érintettet a konkrét
                        adatfeldolgozó személyéről. Amennyiben azonban az
                        érintett tájékoztatást kér arra vonatkozóan, hogy
                        Adatkezelő a tevékenysége során konkrétan mely
                        csoporttag részére adott át személyes adatot, úgy az
                        érintett kérésére tájékoztatást nyújt.
                        <br />
                        <span>Önkéntes hozzájárulás:</span> A jelen adatkezelés
                        önkéntes. A hozzájárulás bármikor, korlátozás nélkül
                        visszavonható, azzal, hogy a hozzájárulás visszavonása
                        előtt végrehajtott adatkezelés jogszerűségét a
                        visszavonás nem érinti. A Nyereményjátékhoz kapcsolódó
                        adatkezelési hozzájárulás visszavonása egyúttal a
                        Nyereményjátékban történő részvétel megszüntetését is
                        jelenti. Amennyiben a hozzájárulást visszavonja, úgy az
                        adatait a jogszerű adatkezelést igazoló nyilvántartásba
                        veszi fel az Adatkezelő.
                        <br />
                        <span>Profilalkotás:</span> Adatkezelő az adatkezelés
                        során profilalkotást nem végez.
                        <br />
                        <span>Érintett jogai:</span> A helyesbítéshez való jog:
                        Az adatai pontosítását, javítását, helyesbítését
                        kérheti.
                        <br />
                        <span>A törléshez való jog:</span> Bizonyos esetekben
                        kérheti, hogy személyes adatai törlésre kerüljenek, így
                        az eredeti adatkezelési cél érdekében már nincs szükség
                        az adatai tárolására, vagy hozzájáruláson alapuló
                        adatkezelés esetén visszavonja hozzájárulását, feltéve,
                        hogy az adatok törlését jogszabály vagy egyéb jogi
                        kötelezettség teljesítése nem zárja ki.
                        <br />
                        <span>Az adatkezelés korlátozásához való jog:</span>
                        Bizonyos esetekben joga van kérni az adatok zárolását
                        (fel nem használását) valamely intézkedés
                        megtörténtének, vagy joga gyakorlásának elbírálásáig.
                        <br />
                        <span>Adathordozhatósághoz való jog:</span> Önnek joga
                        van kérni elektronikus adatkezelés esetén, hogy adjuk át
                        személyes adatait az Ön által kijelölt harmadik fél
                        részére. Az adatokat „.xls” vagy „.pdf” fájlformátumban
                        adjuk ki.
                        <br />
                        <span>Tiltakozáshoz való jog:</span> Ön tiltakozhat
                        adatai kezelése ellen, amennyiben úgy látja, egyes
                        adatkezelési cél érdekében már szükségtelen a további
                        adatkezelés, kivéve ha az adatkezelő igazolja, hogy az
                        adatkezelés jogszerű és szükséges.
                        <br />
                        <span>Felügyeleti hatóság:</span> Ön jogosult az
                        adatkezeléssel szemben panasszal a Felügyeleti
                        hatósághoz fordulni. A felügyeleti hatóság a Nemzeti
                        Adatvédelmi és Információszabadság Hatóság.
                        <br />
                        Nemzeti Adatvédelmi és Információszabadság Hatóság
                        elérhetőségei:
                        <br />
                        cím: 1055 Budapest, Falk Miksa u. 9-11. | telefon:
                        +36-1-391-1400 | fax: +36 -1-391-1410 | e-mail:
                        <a href="mailto:ugyfelszolgalat@naih.hu">
                            ugyfelszolgalat@naih.hu
                        </a>{" "}
                        | internetcím:{" "}
                        <a
                            href="https://naih.hu"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://naih.hu
                        </a>
                        <br />
                        <span>Irányadó jog és szabályzat:</span> A személyes
                        adatok kezelésére a természetes személyeknek a személyes
                        adatok kezelése tekintetében történő védelméről és az
                        ilyen adatok szabad áramlásáról, valamint a 95/46/EK
                        rendelet hatályon kívül helyezéséről szóló az EURÓPAI
                        PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE (“GDPR”
                        vagy “általános adatvédelmi rendelet”) az irányadó. Az
                        adatvédelmi szabályzat az Adatkezelő{" "}
                        <a
                            href="http://www.zeptermagazin.hu/adatvedelem"
                            target="_blank"
                            rel="noreferrer"
                        >
                            http://www.zeptermagazin.hu/adatvedelem
                        </a>{" "}
                        honlapján, székhelyén valamint egyébként a kereskedő
                        munkatársaknál, megbízottaknál minden esetben elérhető.
                    </p>
                </div>
            </div>
            {/* </div> */}
        </>
    );
};

export default GeneralTerms220615;
