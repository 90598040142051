import React, { useEffect } from "react";
import CountUp from "react-countup";
import { readNumberOfClubMembers } from "../service/home/NumberOfClubMembersService";

const NumberOfClubMembers: React.FC = () => {
    const [numberOfClubMembers, setNumberOfClubMembers] =
        React.useState<string>("");

    useEffect(() => {
        readNumberOfClubMembers().then((response) =>
            setNumberOfClubMembers(response.data)
        );
    }, [numberOfClubMembers]);

    return (
        <>
            <div className="ml-3">
                <span className="font-medium text-black-alpha-90">
                    Jelenlegi Zepter Club tagok száma
                </span>
                <h2 className="mt-2 mb-0 text-black-alpha-50 line-height-3">
                    <CountUp
                        start={0}
                        end={parseInt(numberOfClubMembers)}
                        duration={4}
                        separator=" "
                    ></CountUp>
                </h2>
            </div>
        </>
    );
};

export default NumberOfClubMembers;
