import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { setActiveMenuItem } from "../../state/dashboard/navigationSlice";
import { setLoadingOn, setLoadingOff } from "../../state/dashboard/mainSlice";
import { logout, setAuthToken } from "../../service/dashboard/AuthService";
import { StatusCodes } from "http-status-codes";
import pageTeaserClubMemberInvitationImage from "../../asset/page-teaser-clubmember-invitation.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { readAllClubMemberPendingInvitation } from "../../service/dashboard/ClubMemberInvitationService";
import { formatDate, formatName } from "../../util/stringFormat";

interface InvitedType {
    created: Date | null | undefined;
    firstName: string | null;
    lastName: string | null;
    companyName: string | null;
    companyType: string | null;
    email: string | null;
}

interface InvitedEntityType {
    date: string;
    name: string | null;
    email: string;
}

const ClubMemberPendingInvitedList: React.FC = () => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("clubmemberpendinginvitedlist"));
    setAuthToken();

    let navigate = useNavigate();

    const userProfile: any = useSelector(
        (state: RootState) => state.user.userProfile
    );

    const [clubMemberPendingInvitedList, setClubMemberPendingInvitedList] =
        useState([]);

    useEffect(() => {
        dispatch(
            setLoadingOn({
                blockPage: true,
                message: "Meghívttak listájának összeállítása folyamatban.",
            })
        );
        readAllClubMemberPendingInvitation(userProfile.vipNumber).then(
            (response: any) => {
                console.log(response.data);
                if (
                    response &&
                    response.data &&
                    response.data.clubMemberInvitationList
                ) {
                    const pendingList =
                        response.data.clubMemberInvitationList.map(
                            (invited: InvitedType) => {
                                const formattedDate = formatDate(
                                    invited.created
                                );
                                const initedEntity: InvitedEntityType = {
                                    date: formattedDate
                                        ? formattedDate
                                        : "Dátum nem elérhető",
                                    name: formatName(
                                        invited.lastName,
                                        invited.firstName,
                                        invited.companyName,
                                        invited.companyType
                                    ),
                                    email: invited.email
                                        ? invited.email
                                        : "Nincs e-mail adat",
                                };
                                return initedEntity;
                            }
                        );
                    setClubMemberPendingInvitedList(pendingList);
                }
                dispatch(setLoadingOff());
            },
            (error) => {
                console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
                dispatch(setLoadingOff());
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="flex flex-wrap shadow-2">
                <div className="grid p-4 pb-0 lg:p-7 lg:pb-0">
                    <div className="col-12 md:col-6">
                        <div className="flex align-items-center justify-content-center w-auto">
                            <img
                                className="responsive fadeinleft animation-duration-500"
                                src={pageTeaserClubMemberInvitationImage}
                                alt="Zepter Club tag meghívás"
                            />
                        </div>
                    </div>

                    <div className="text-center col-12 md:col-6">
                        <h1 className="text-black-alpha-90 font-500 mb-3 sm:mt-3">
                            Függőben lévő meghívott klubtagok
                        </h1>
                    </div>
                </div>
                <div className="mt-0 w-full p-4 surface-card">
                    <DataTable
                        className="admin-list"
                        value={clubMemberPendingInvitedList}
                        size="small"
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Lista: {first}-{last} | Összesen: {totalRecords}"
                        rows={20}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                    >
                        <Column field="date" header="Dátum" sortable />
                        <Column field="name" header="Név" sortable />
                        <Column field="email" header="Email" sortable />
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default ClubMemberPendingInvitedList;
