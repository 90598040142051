import React from "react";
import { Link } from "react-router-dom";
import "../../css/GeneralTerms.css";
import pageGeneralTermsImage from "../../asset/page-teaser-general-terms-aszf.png";

const GeneralTermsConsultant220615: React.FunctionComponent<any> = (
    props: any
) => {
    return (
        <>
            <div className="px-4 md:px-6 lg:px-8">
                <div className="flex flex-wrap shadow-2">
                    <div className="w-full lg:w-12 px-3 py-3 lg:p-7 bg-blue-50">
                        <div className="mt-0 mb-2 flex justify-content-center">
                            <div className="w-auto md:w-8">
                                <img
                                    src={pageGeneralTermsImage}
                                    className="responsive fadeinleft animation-duration-500"
                                    alt="bizzclub.hu tanácsadói ászf kép"
                                />
                            </div>
                        </div>
                        <div className="text-xl text-black-alpha-90 font-500 mb-3 text-center">
                            ZEPTER CLUBLIVE100
                            <br />
                            TANÁCSADÓI TEVÉKENYSÉG ELLÁTÁSÁRA VONATKOZÓ
                            <br />
                            ÁLTALÁNOS SZERZŐDÉSI FELTÉTELEK
                        </div>
                        <ol>
                            <li>
                                <span>
                                    Tanácsadói tevékenység ellátására vonatkozó
                                    általános szerződési feltételek
                                </span>
                                <ol>
                                    <li>
                                        A ClubLive100 hűségprogram szabályzat
                                        rendelkezéseit (a továbbiakban
                                        „Szabályzat”) jelen Tanácsadói általános
                                        szerződési feltételekkel (a továbbiakban
                                        „ÁSZF”) együtt kell alkalmazni, azzal,
                                        hogy jelen ÁSZF rendelkezései
                                        kiegészítik a Szabályzat rendelkezéseit.
                                        Az ÁSZF-ben nem szabályozott kérdésekben
                                        a Szabályzat rendelkezései az
                                        irányadóak. Az ÁSZF ás a Szabályzat
                                        esetleges eltérő rendelkezései esetén az
                                        ÁSZF rendelkezései az irányadóak.
                                    </li>
                                    <li>
                                        A Szabályzatban meghatározott fogalmakat
                                        jelen ÁSZF-ben azonos tartalommal kell
                                        alkalmazni.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>Tanácsadó</span>
                                <ol>
                                    <li>
                                        Tanácsadó az a magánszemély lehet, aki
                                        rendelkezik ClubLive100 tagsággal és
                                        elfogadta a Szabályzatot, egyúttal
                                        rendelkezik ClubLive100 klubtagsági
                                        számmal. A Tanácsadói státusz
                                        megszerzésének további feltétele, hogy a
                                        Tanácsadó rendelkezzen ClubLive100
                                        online regisztrációval és felhasználói
                                        fiókkal a www.bizzclub.hu felületen és
                                        webshopban.
                                    </li>
                                    <li>
                                        Tanácsadó nem végez értékesítési
                                        tevékenységet ZEPTER részére.
                                    </li>
                                    <li>
                                        Tanácsadó és ZEPTER között jelen
                                        ÁSZF-ben szabályozott megbízási
                                        szerződés (a továbbiakban „Tanácsadói
                                        megbízási szerződés”) jön létre az ÁSZF
                                        elfogadásával. A Tanácsadó a jelen
                                        Tanácsadói megbízási szerződés alapján
                                        ZEPTER részére potenciális Klubtagokat
                                        kutat fel, és ajánlja részükre a
                                        ClubLive100 hűségprogramban történő
                                        részvételt, elektronikus úton. A
                                        Tanácsadói megbízási szerződés alapján
                                        Tanácsadó a Szabályzatban meghatározott
                                        kedvezményekhez képest többlet előnyökre
                                        és kedvezményekre lehet jogosult.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>Tanácsadó felület</span>
                                <ol>
                                    <li>
                                        A Klubtag a www.bizzclub.hu felületén
                                        regisztrálhat Tanácsadóként, az erre
                                        létrehozott menüpontban.
                                    </li>
                                    <li>
                                        A Tanácsadói regisztrációt követően a
                                        Tanácsadó eléri a ClubLive100
                                        hűségprogram Tanácsadói felületét is. A
                                        Tanácsadói felülethez történő
                                        hozzáféréshez a regisztráció során
                                        megadott felhasználónévvel és jelszó
                                        megadásával léphet be a Tanácsadó.
                                    </li>
                                    <li>
                                        A Tanácsadói felületen ZEPTER elérhetővé
                                        teszi a bizzclub.hu-t, azzal, hogy a
                                        Tanácsadó további kedvezmények
                                        elérésére, felhasználására jogosult. A
                                        webshopon keresztül megkötött Termék
                                        megvásárlására vonatkozó adásvételi
                                        szerződés megkötése során a Tanácsadó
                                        jogosult az általa Klubtagként és
                                        Tanácsadóként megszerzett előnyök és
                                        kedvezmények beszámítására és
                                        felhasználására.
                                    </li>
                                    <li>
                                        A Tanácsadói felületen a Tanácsadó
                                        kezdeményezheti harmadik személyek
                                        meghívását a ClubLive100 hűségprogramba.
                                        A Tanácsadó nyilatkozik, hogy az
                                        ajánlott személytől felhatalmazással
                                        rendelkezik a személyes adatok ZEPTER
                                        részére történő átadására az ajánlás
                                        tekintetében, és ezért felelősséget
                                        vállal.
                                    </li>
                                    <li>
                                        A Tanácsadó a Tanácsadói felületen
                                        nyomonkövetheti sikeres és sikertelen
                                        ajánlásait, valamint ellenőrizheti, hogy
                                        az általa ajánlott személyek vásárlásai
                                        után előnyre vagy kedvezményre vált
                                        jogosulttá.
                                    </li>
                                    <li>
                                        A Tanácsadó felhasználói Fiókjához
                                        személyes virtuális pénztárca, azaz
                                        Wallet tartozik. A Wallet tartalma
                                        szerint feltünteti, hogy a Tanácsadó
                                        milyen mértékű előnnyel vagy
                                        kedvezménnyel rendelkezik, további a
                                        Wallet biztosítja a Tanácsadó felé
                                        történő elszámolás lehetőségét.
                                    </li>
                                    <li>
                                        A Wallet-ből a Tanácsadó lehívással
                                        vezetheti át a rendelkezésére álló
                                        kedvezményt egy további vársárlására,
                                        vagy ajánlói prémium kifizetés
                                        formájában.
                                    </li>
                                    <li>
                                        A Tanácsadó a Tanácsadói felületen
                                        kezelheti személyes adatait,
                                        vásárlásokat kezdeményezhet és
                                        bonyolíthat, utánvétes rendeléseket
                                        adhat le, kezelheti fiókját.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>Tanácsadói előnyök és kedvezmények</span>
                                <ol>
                                    <li>
                                        Tanácsadó a jelen ÁSZF-ben meghatározott
                                        előnyöket és kedvezményeket a
                                        Szabályzatban meghatározott, Klubtagnak
                                        járó előnyökkel-, és kedvezményekkel
                                        együtt, azt meghaladóan érvényesítheti,
                                        az alábbiak szerint.
                                    </li>
                                    <li>
                                        Tanácsadói vásárlási kedvezmény
                                        <ol>
                                            <li>
                                                A Tanácsadók akár 50 %-os
                                                kedvezményben részesülhetnek
                                                bizonyos ZEPTER termékek
                                                megvásárlása esetén az adott
                                                vásárlás összértékének
                                                függvényében. A kedvezmény a
                                                Klubtag árból érvényesíthető
                                                közvetlenül a vásárláskor. A
                                                kedvezményes vételárat az egyes
                                                termékek tekintetében a
                                                mindenkori árlista „Klubtag ár”
                                                rovata tartalmazza (a
                                                továbbiakban „Klubtag ár”). A
                                                kedvezmény igénybe vehető a
                                                https://bizzclub.hu weboldalon. A
                                                Tanácsadói vásárlási kedvezmény
                                                a Klubtagot megillető vásárlási
                                                kedvezményen felüli kedvezmény.
                                                A vásárlási kedvezmény nem
                                                vehető igénybe olyan fogyó
                                                eszközök esetében, mint a
                                                víztisztítók szűrői,
                                                alkatrészek, egyes kiegészítő
                                                termékek.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Ajánlói prémium
                                        <ol>
                                            <li>
                                                Ha egy Tanácsadó ajánlására (a
                                                továbbiakban ajánló Tanácsadó)
                                                új Tanácsadó (a továbbiakban
                                                ajánlott Tanácsadó) ZEPTER
                                                terméket vásárol, úgy az ajánló
                                                Tanácsadó az ajánlott Tanácsadó
                                                első vásárlása nettó értékének
                                                akár 10%-val egyező összegű
                                                „ajánlói prémium”-ra válik
                                                jogosulttá. Az egyes termékekhez
                                                kapcsolódó, konkrét ajánlói
                                                prémium összege a webshopban
                                                feltüntetésre kerül.
                                            </li>
                                            <li>
                                                Az ajánlói prémiumok több
                                                sikeres ajánlás esetén
                                                összevonhatók, de csak abban az
                                                országban lehet felhasználni,
                                                amelyben az új Tanácsadót ajánló
                                                Tanácsadó regisztrált. Az
                                                ajánlói prémium kifizetése
                                                minden esetben magyar forintban
                                                az elszámolás napján érvényes
                                                MNB deviza közép árfolyama
                                                szerint kerül elszámolásra. Az
                                                ajánlói prémium elektronikus
                                                úton érhető el a Tanácsadói
                                                felületen, a Wallet-ben. A
                                                Wallet-ben feltüntetésre kerül
                                                az ajánlói prémium
                                                felhasználható összege, magyar
                                                forintban meghatározva.
                                            </li>
                                            <li>
                                                A kifizetés feltétele, hogy az
                                                ajánló Tanácsadó jelen ÁSZF-et
                                                elfogadja, valamint megadja az
                                                ajánlói prémium elszámolásához
                                                szükséges adatokat.
                                            </li>
                                            <li>
                                                A kifizetés további feltétele,
                                                hogy az ajánlott Tanácsadó a
                                                vásárlását teljes egészében
                                                kifizette, a terméket átvette,
                                                és a szerződéstől nem állt el.
                                            </li>
                                            <li>
                                                Bármely kifizetésre az ajánlói
                                                prémiumra való jogosultság
                                                bekövetkezésétől – valamint az
                                                összes adat, információ, okmány
                                                beérkezésétől, és a Wallet-ből
                                                történő lehívást követően -
                                                számított 30 napon belül
                                                kerülhet sor.
                                            </li>
                                            <li>
                                                Az ajánlói prémium
                                                érvényesítésére az esedékessé
                                                válását követő 1 (egy) évig van
                                                lehetőség, ezt követően az
                                                érintett ajánlói prémium
                                                megszűnik, amennyiben a prémium
                                                érvényesítése ezen időszak alatt
                                                a Tanácsadó hibájából hiúsul
                                                meg, így különösen, ha nem
                                                kezdeményezi a Wallet-ből
                                                történő lehívást (jogvesztés
                                                kikötése).
                                            </li>
                                            <li>
                                                ZEPTER csak nettó 1.000,- Ft
                                                összeget meghaladó ajánlói
                                                prémium kifizetését köteles
                                                teljesíteni.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Az ajánló Tanácsadó a kifizetést
                                        <ol>
                                            <li>
                                                az önálló tevékenységből
                                                származó jövedelem elszámolására
                                                és kifizetésére vonatkozó
                                                szabályok (a személyi
                                                jövedelemadóról szóló 1995. évi
                                                CXVII. tv. 16. § (1) bekezdése
                                                szerint igényelheti, és ZEPTER
                                                az ajánlói prémium kifizetését
                                                kizárólag valamennyi, a
                                                kifizetés időpontjában hatályos
                                                jogszabályban előírt adó-, és
                                                járulék valamint más közteher
                                                díj levonását követően
                                                teljesíti. Az ajánló Tanácsadó
                                                önadózóvá kell, hogy váljon az
                                                ajánlói prémium érvényesítésével
                                                együtt. Az ajánló Tanácsadó a
                                                kifizetés érdekében köteles
                                                valamennyi adat és dokumentum
                                                átadására és igazolására ZEPTER
                                                felé. ZEPTER kifizetést csak
                                                akkor teljesít, ha az önálló
                                                jövedelem bevallásához,
                                                bejelentéséhez és kifizetéséhez
                                                szükséges valamennyi adat,
                                                információ és dokumentum részére
                                                átadásra került. ZEPTER jogosult
                                                a jelen ÁSZF-ben rögzítettek
                                                teljesítésének érdekében a
                                                Tanácsadót a megjelölt e-mail
                                                címen, vagy a Tanácsadói
                                                felületen tájékoztatni,
                                                információt kérni a kifizetéssel
                                                összefüggésben. ZEPTER
                                                felhívására a Tanácsadó köteles
                                                az eredeti iratok, okmányok vagy
                                                más dokumentumok bemutatására
                                                vagy átadására. Az ajánló
                                                Tanácsadó a kifizetés érdekében
                                                és feltételeként köteles ZEPTER
                                                részére a következő adatok
                                                megadására: név, születési név,
                                                születési hely és idő, anyja
                                                neve, adóazonosító jel, Taj
                                                szám, személyi igazolvány száma,
                                                állampolgárság, lakcím,
                                                tartózkodási cím, e-mail cím,
                                                telefonszám, bankszámlaszám,
                                                bank neve. Jelen pont szerinti
                                                kifizetés választása esetén a
                                                Wallet-ből történő lehívást
                                                követően az ajánlói prémium
                                                kizárólag az ajánló Tanácsadó
                                                részére írható jóvá, és nem
                                                átruházható.
                                            </li>
                                            <li>
                                                a vállalkozói tevékenységre
                                                irányadó hatályos szabályozás
                                                alapján, adószámmal rendelkező
                                                gazdálkodó szervezet útján,
                                                számla benyújtásával
                                                érvényesítheti. A Wallet-ből
                                                lehívható összegek nettó
                                                összegek. Számla kiállítással
                                                történő ajánlói prémium
                                                érvényesítése esetén az ajánló
                                                Tanácsadó köteles az ajánlói
                                                prémium összegét átruházni a
                                                számlát kiállító gazdálkodó
                                                szervezetre engedményezés
                                                formájában, valamint köteles
                                                nyilatkozni, hogy az ajánló
                                                Tanácsadót a számlát kiállító
                                                gazdálkodó szervezet milyen
                                                formában foglalkoztatja, vagy
                                                áll fenn az ajánló Tanácsadónak
                                                jogviszonya a gazdálkodó
                                                szervezettel. A megjelölt
                                                gazdálkodó szervezet a számla
                                                kiállítása érdekében köteles a
                                                ClubLive100 hűségprogramban
                                                részt venni, és elfogadni a
                                                hűségprogram Szabályzatát,
                                                valamint köteles elfogadni a
                                                jelen ÁSZF-et, továbbá
                                                ZEPTER-rel Kereskedői megbízási
                                                szerződést kötni. A Kereskedői
                                                megbízási szerződés alapján a
                                                gazdálkodó szervezet ZEPTER
                                                hivatalos kereskedőjévé válik.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Wallet-ből történő bármely előny vagy
                                        kedvezmény lehívása esetén a Wallet
                                        zárolja az összeget, és az a
                                        továbbiakban nem használható fel. A
                                        kedvezmény vagy más előny összegének
                                        kifizetésére csak akkor kerül sor, ha a
                                        Tanácsadó minden szükséges jogszabályi
                                        előírást teljesített, szükséges adatot
                                        megadott, és igazolt ZEPTER felé.
                                        Amennyiben a Tanácsadó meggondolja
                                        magát, és a lehívás igényt törli, úgy a
                                        Wallet-be visszavezetésre kerül az előny
                                        vagy kedvezmény összege.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>
                                    Felfüggesztés és visszavonás, megszüntetés
                                </span>
                                <ol>
                                    <li>
                                        A ClubLive100 tagok kötelesek betartani
                                        a jelen ÁSZF-ben leírtakat. A klubtag
                                        köteles ZEPTER részére valós és teljes
                                        körű adatokat szolgáltatni.
                                    </li>
                                    <li>
                                        A ZEPTER fenntartja a jogot, hogy
                                        felfüggessze vagy megszüntesse azon
                                        Tanácsadó Klubtagságát és Tanácsadói
                                        megbízási jogviszonyát (egyúttal az
                                        azzal járó kedvezményeket és előnyöket
                                        is), aki visszaél a Klubtagsággal, a
                                        Tanácsadói megbízási szerződés szerinti
                                        jogosultságokat és/kötelezettségeket
                                        megsérti, azokat visszaélésszerűen
                                        gyakorolja, vagy bármely módon kárt okoz
                                        ZEPTER számára. Ilyen esetekben a
                                        Tanácsadó nem jogosult kártérítésre a
                                        Tanácsadói jogviszony felfüggesztése,
                                        vagy megszüntetése okán.
                                    </li>
                                    <li>
                                        A Tanácsadó a Tanácsadói megbízási
                                        jogviszony bármikor megszüntetheti
                                        írásban ZEPTER-nek írt levéllel, vagy a{" "}
                                        <a href="mailto:vevoszolgalat@zepter.hu">
                                            vevoszolgalat@zepter.hu
                                        </a>
                                        -ra küldött e-mail üzenettel. A
                                        Tanácsadói megbízási jogviszony
                                        megszüntetésével a Tanácsadó lemond a
                                        Tanácsadói megbízási jogviszonyból eredő
                                        kedvezményekről és a fel nem használt
                                        ajánlói prémiumról. A Tanácsadó
                                        ZEPTER-rel szemben – korábbi vásárlások
                                        kapcsán – felmerült fizetési
                                        kötelezettségei azok rendezéséig
                                        továbbra is fennállnak.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>Változtatások</span>
                                <ol>
                                    <li>
                                        A ZEPTER bármikor visszavonhatja vagy
                                        megváltoztathatja a jelen ÁSZF-et,
                                        jutalékot, prémiumot valamint a
                                        marketing tervet. Az aktuális ÁSZF-et
                                        weblapunkon (
                                        <a
                                            href="https://www.zeptermagazin.hu/informaciok"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            https://www.zeptermagazin.hu/informaciok
                                        </a>
                                        ) találhatja. A változtatásokról ZEPTER
                                        a Felhasználói felületen értesíti a
                                        Tanácsadót.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>Irányadó jog</span>
                                <ol>
                                    <li>
                                        A ZEPTER ClubLive100 Tanácsadói
                                        megbízási jogviszonnyal kapcsolatos
                                        kérdésekben a magyar jog az irányadó. A
                                        felmerülő viták és jogi kérdések
                                        rendezésében Zepter Ungarn Kft.
                                        székhelye szerinti illetékes bíróság a
                                        jogosult.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>Panaszkezelés </span>
                                <ol>
                                    <li>
                                        A ClubLive100 Tanácsadói tevékenységgel
                                        összefüggésben felmerült panaszkezelési
                                        szabályokat elérheti a Zepter honlapján,
                                        a{" "}
                                        <a
                                            href="https://www.zeptermagazin.hu/informaciok"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            https://www.zeptermagazin.hu/informaciok
                                        </a>{" "}
                                        címen. A Tanácsadó nem minősül
                                        fogyasztónak, amennyiben a 4.2. pont
                                        szerinti ajánlói prémiumot érvényesíti.
                                    </li>
                                    <li>
                                        Zepter és a Tanácsadó az esetleges
                                        nézeteltéréseiket békés úton rendezik,
                                        ennek hiányában a Tanácsadó a bírósági
                                        út igénybevétele nélkül – amennyiben a
                                        4.1. pont szerinti kedvezménnyel
                                        összefüggésben vagy általános
                                        ügyintézéssel összefüggő panasza alapján
                                        fogyasztónak minősül – az illetékes
                                        békéltető testülethez fordulhat:
                                        Budapesti Békéltető Testület (Székhelye:
                                        1016 Budapest, Krisztina krt. 99. III.
                                        em. 310., Levelezési cím: 1253 Budapest,
                                        Pf.: 10., Elektronikus levelezési cím:
                                        bekelteto.testulet@bkik.hu, Telefax: 06
                                        (1) 488 21 86, Telefon: 06 (1) 488 21
                                        31)
                                        <br />
                                        Online vitarendezés lehetősége: ZEPTER a
                                        fogyasztói jogviták online rendezéséről,
                                        valamint a 2006/2004/EK rendelet és a
                                        2009/22/EK irányelv módosításáról szóló,
                                        2013. május 21-i 524/2013/EU európai
                                        parlamenti és tanácsi rendeletben, és a
                                        fogyasztói jogviták online rendezéséről
                                        szóló 524/2013/EU európai parlamenti és
                                        tanácsi rendeletben előírt online
                                        vitarendezési platform feladatai
                                        ellátásának módjáról és a panaszok
                                        bejelentésére szolgáló elektronikus
                                        űrlap kitöltésének módjáról, és az
                                        online vitarendezési kapcsolattartó
                                        pontok közötti együttműködés módjáról
                                        szóló, a Bizottság 2015/1051 számú
                                        végrehajtási rendelet előírásainak
                                        megfelelően közreműködik és eljár az
                                        online vitarendezési platformon
                                        keresztül beterjesztett panaszok
                                        elintézésben.
                                        <br />
                                        Az online vitarendezés tájékoztató
                                        anyaga:{" "}
                                        <a
                                            href="https://bekeltet.bkik.hu/hirek/online-vitarendezes"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            https://bekeltet.bkik.hu/hirek/online-vitarendezes
                                        </a>
                                        <br />
                                        Az online vitarendezése elérhetősége:{" "}
                                        <a
                                            href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=HU"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=HU
                                        </a>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>ADATVÉDELEM</span>
                                <ol>
                                    <li>
                                        A https://bizzclub.hu és{" "}
                                        <a
                                            href="https://www.zepter.hu"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            https://www.zepter.hu
                                        </a>{" "}
                                        domain néven üzemelő webáruház adatait
                                        ZEPTER kezeli.
                                    </li>
                                    <li>
                                        ZEPTER a személyes adatok Tanácsadótól
                                        történő átvételét és az adatkezelés
                                        megkezdése előtt a Tanácsadó részére az
                                        adatkezelésről tájékoztatást ad. Az
                                        adatkezelésre vonatkozó tájékoztató
                                        megadására ZEPTER ClubLive100
                                        hűségprogramban történő regisztrációval
                                        egyidejűleg kerül sor. Az adatkezelési
                                        tájékoztató az alábbi linken is
                                        elérhető:
                                        <Link to="/cl100generaltermsandconditions230301">
                                            https://bizzclub.hu/cl100generaltermsandconditions230301
                                        </Link>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>EGYÉB RENDELKEZÉSEK</span>
                                <ol>
                                    <li>
                                        ZEPTER bármikor jogosult jelen ÁSZF
                                        feltételeit egyoldalúan módosítani.
                                    </li>
                                    <li>
                                        A jelen általános szerződési
                                        feltételekben nem szabályozott
                                        kérdésekben a polgári törvénykönyvtől
                                        szóló 2013. évi V. törvény rendelkezései
                                        az irányadók.
                                    </li>
                                    <li>
                                        Jelen ÁSZF rendelkezései aláírás nélkül
                                        is érvényesek és hatályosak a
                                        Tanácsadókra és a Zepter Ungarn Kft-re.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GeneralTermsConsultant220615;
