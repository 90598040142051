import { Card } from "primereact/card";
import { Badge } from "primereact/badge";
import { Carousel } from "primereact/carousel";
import PhilipZepterSigniture from "../../asset/philip_zepter_signiture.png";

const PhilipZepterQuotes: React.FC = () => {
    const quotes = [
        "Ha az ember jó példákat lát maga körül és hisz önmagában, az már fél siker. Talán nem a győzelem a legfontosabb az életben, de egészséges versenyszellem nélkül biztosan nem jutunk egyről a kettőre. Akármibe is fogunk, tegyük szívvel-lélekkel, és a jutalom nem marad el. Ha nem figyelünk oda az eredményeinkre, könnyen kudarcot vallhatunk. Honnan tudnánk, mi folyik körülöttünk és hogyan irányítsuk a céget, ha a cél nem lebeg mindig és mindenkor a szemünk előtt?",
        "Úgy hiszem, hogy a személyes példám és a Zepter sikere a garancia arra, hogy nők is felébreszthetik szunnyadó  képességeiket. Meggyőződésem, hogy ezek a szunnyadó képességek mindenkiben ott vannak.",
        "Sok nagyszerű beszédet hallottam életemben, vállalativezetőket és tehetséges szónokokat, de nem ez a lényeg: az ékesszólás önmagában nem jelent garanciát a sikerre. A dualizmus törvénye szerint a kezdetés a vég egy - vagyis az első pillanattól tudnunk kell, mi a célunk. Minden győzelmet kétszer élünk meg:először a képzeletünkben, majd a valóságban. Ha nem képzeljük el, a valóságban sem fog megtörténni.",
        "A siker mindenki előtt nyitva áll, mindössze annyit kell tennünk, hogy törekszünk rá. A sikerhez egyetlen dolog kell: akarat. Koncentráljunk a célra, szenteljük magunkat teljesegészében a feladatnak, és mindent elérhetünk, amire csak vágyunk. Ám hogy elérjük a célunkat, ahhoz tanulnunk is kell, és sokszor ez nem is olyan könnyű. Ha tanulunk, jobb eredményeket érhetünk el a mindennapi munkánkbanés ez közelebb visz minket az áhított célhoz.",
        "A sikerhez is kell némi gyakorlat. Én magam végigjártam ezt az utat, így sok tapasztalatotszereztem, amit most át szeretnék adni Önöknek. Mindenekelőtt tartanunk kell magunkat a jól beváltmódszerekhez, mert ezeknek a működését ismerjük. Csak ezekkel lehetünk sikeresek.",
        "A sikerhez vezető út hibákkal van kikövezve. Ha meg akarunk tanulni repülni, esni is tudnunk kell. Hibáinkból ugyanolyan sokat profitálhatunk,mint sikereinkből, de kétszer egymás után lehetőleg ne kövessük el ugyanazt a hibát. Barátaim, itt az ideje, hogy felébredjenek! Járják az utat nyitott szemmel, és sose térjenek  le a kitaposott ösvényről.",
        "A sikerhez bátorság is kell. Minél bátrabbak vagyunk,annál kevésbé hátrálunk meg az új kihívások elől. A kihívás sikert hoz, a siker pedig még több sikert. A siker a legnagyobb ösztönző erő.",
        "Nem vagyok okosabb vagy bölcsebb Önöknél. Egyszerűencsak betartom a szabályokat. Mert a sikernek megvannaka maga törvényszerűségei, ahogy a kudarcnak is.",
        "Ami a legfontosabb, és amiben még jobban kell teljesítenünk,az a munka. Dolgozzunk kétszer annyit, mint eddig, így azt is elérhetjük, amit eddig nem is remélhettünk. A napi rutin segítségével jutottunk el oda, ahol ma vagyunk. Hiszek a példa erejében. Nap mint nap példát mutatok. Háromszor annyit dolgozom, mint amennyit feltétlenül szükséges, hogy elérjem a célomat.",
        "Nem álmaim vannak, hanem terveim.",
        "Egyetlen alternatívánk a haladás; a folyamatos fejlődés és terjeszkedés. Ez hozzáállás kérdése, a mindennapimunkában és az élet minden területén. Ez nem affinitáskérdése, hanem életforma; üzletiértelemben a túlélés alapfeltétele. Ha egy percremegtorpanunk, nemcsak, hogy nem őrizzük mega pozíciónkat, de menthetetlenül csúszni kezdünk. Szerencsére a Zepter történetében ez még sosem fordult elő, és mi mindent megteszünk, hogy ne is forduljon elő.",
        "A Zepter az üzleti világban egyedülálló gyorsasággalterjeszkedik. Új távlatokat nyitottunk a közvetlenértékesítésben. A példánkat sokan követték, és jelentőssikereket értek el. Ezért sem akarom valami újjal felváltani a jól bevált módszert, hiszen, ahogy már említettem,ez a ritmus az életünk részévé vált. Hiszem, hogy minden,ami az elmúlt 20 évben történt, egy új korszak eljövetelétszolgálja. Mert éppen erre van szükségünk: egy új korszakra.Egy munkatársam azt mondta:“A Zepter vulkán, nem lassú sodrású folyam. Nekünk, akik itt dolgozunk, megvan a gyakorlatunk, méghozzá nem akármiben: a sikerben!”",
        "Azt is látnunk kell, hogy sok cég boldog lenne, ha a miénkhezhasonló eredményeket tudna felmutatni. Ami minket illet,nem elégedetlenek vagyunk, hanem optimisták. Mert mi a különbség az optimista és a pesszimista között? Az optimista mindig mosolyog, mert tudja, hogy bármikor rosszabbra fordulhatnak a dolgok. Mi viszont azértmosolygunk, mert tudjuk, hogy soha nem fognak rosszra fordulni a dolgok. Nekünk csak felfelé vezet az út.",
        "A sikert nem adják ingyen, viszont könnyen elvehetik az embertől. Ezt Önök közül sokan hallhatták már tőlem. Mindenki a saját szerencséjének kovácsa, és aki a Zepternéldolgozik, folyamatos támogatást tudhat maga mögött; tudja, hogy a cégnél van jövője, hiszen egy világszerte ismert márkával dolgozik, kiváló termékekkel, a munkája pedig kreatív és kihívásteli.",
        "Sokan nem látják, ami az orruk előtt van, és máshol keresik a boldogságot. Remélem, most már mindannyiuk számáravilágossá vált, hogy ez a kincs mindig is itt volt,karnyújtásnyira Önöktől. A Zepter mindenki számáraegyértelmű üzenetet fogalmaz meg; akinek van szeme,látja a siker titkát. A győztesek mindig többet tudnak, mint a vesztesek.",
        "A kívülálló talán nem látja, mi minden történik a Zepternél, de amiről mindenki hallott, az az ismert márka, a megbízhatóság, az évtizedes tapasztalat, a folyamatosterjeszkedés, az innovatív megoldások, a végtelen lehetőségek  és a jó kereseti lehetőség.",
        "Hadd fejezzem be azzal, hogy a fejlődés egyetlen kerékkötője a megszokás. Ezen leginkább azoknak érdemes eltűnődni, akik azt hiszik, hogy mindent tudnak. Ha valamit nemképzelünk el magunkban, akkor nem fogjuk élvezni, ha avalóságban megtörténik, és nem használjuk ki az abbólszármazó előnyöket. Ha megegyezünk a közös célban,akkor azt el kell érnünk; az eredmény rajtunk múlik.",
        "Annyira nem vagyunk sikeresek, hogy ne lehetne fokoznia sikert. De vigyáznunk kell, mert a sikertelenséget is lehetfokozni. A siker és a kudarc ugyanazt a rendszert feltételezi, hasonló lépések vezethetnek egyikhez is, másikhoz is.",
        "Egy értékesítői csapatban mindig érezni a dinamizmust, a céltudatosságot, a magabiztosságot. Ebben a környezetben magunk is változunk, egymást inspiráljuk.",
        "Senki sem kér lehetetlent Önöktől. Csak azt kérem,hogy végezzék a munkájukat a szabályok szerint. Megvan a rendszerünk; használják, minden áldott nap.",
        "Mindig akadnak, akik kifogásokat keresnek és nem akarjákelfogadni a rendszert. Ez az, amit egyikünk sem engedhetmeg magának; az axiómákról nem érdemes vitát nyitni. A dolgokat csinálni kell, nem okoskodni. Vagyis: a rendszer megvan, csak használni kell.",
        "Azt mondom most Önöknek, hogy bárki, aki belép a céghez,Kazahsztántól Ausztráliáig, már a második hónaptólakár 5 000 eurót kereshet, mindössze napi 8 óra munkával. A nyolcórás munkanap ugyanis legalább napi két bemutatót jelent, az összesen havi 60 bemutató. Senki sem várhatja, hogy havi 4 bemutatóból havi 10 000 eurós jövedelme legyen. Ha így volna, Putyin, Schröder és Bush volnaebben a szakmában, nem Önök vagy én.",
        "A földrajzi kötöttségek nem korlátozzák cégünk fejlődését. Vannak persze feltáratlan piacok és ki nem aknázottlehetőségek - olyan területek, ahová még nem jutottakel kereskedőink. Nekünk ezek nem kicsi vagy nagy piacok, hanem lehetőségek. Öt évvel ezelőtt kitűztük a célt, hogy minden ötvenezer lakosú városban nyitunk Zepter-irodát,így csodálkozva hallom, hogy vannak kétszer, háromszor nagyobb városok, ahol még mindig nem vagyunk jelen.",
        "Gyakran keresnek meg azzal, hogy „Zepter úr, konkrét célokra lenne szükségünk. Határozza meg a keretet, amiből dolgozhatunk és adja meg az irányt.” Mi semegyszerűbb ennél: a keret a költségvetés, a cél pedig a profit. Hogy hogyan érik el a céljaikat, az legyen az Önök gondja. Hogy a Maldív-szigeteken vagy a föld egy másik csücskén dolgoznak, az sem számít. Ha megvalósítják a kitűzött célt, Önök az én embereim.",
        "Nem számít, melyik kontinensen, országban vagy városban dolgoznak, lehetőségek mindenhol vannak, csak észre kell venni őket.",
        "Egy cég ereje globális szinten leginkább azzal mérhető, mennyire eltökélten dolgoznak munkatársai a közös célért. A Zepter mára az erő és a stabilitás szimbólumává vált.",
        "Büszkék vagyunk termékeinkre, kiváló minőségükre, egyéni formavilágukra és fejlett gyártási technológiánkra. Élvezzük a munkánkat és megbecsüljük munkatársainkat. Legfőbb célunk, hogy Ön egészségesebb és teljesebb életet élhessen.",
        "Hiszem, hogy minden cél elérhető, és hogy a korlátok csaka fejünkben léteznek. Erősségeink és gyengeségeink belőlünkfakadnak. Az önmegismeréssel minden út megnyílik, és eltűnnek a határok.",
        "Minden nap új kezdetet jelent; ez a legkedvesebb mondásom. Így oldom meg a nehéz helyzeteket, ahogy a győzelmeket is ezzel fogadom. Minden nap új lehetőséget jelent; a Zepternél minden óra, minden perc új lehetőséget jelent.",
        "Sikerre vagyunk kárhoztatva, és ezt a terhet nem könnyű cipelni. A mai nap mottója legyen ez: nyissuk ki a szemünket! Számtalan út vezet a Zepterhez, ahogy a sikerhez is számtalan út vezet. A Zepter mindig a csúcsra tör, sőt, már meg is hódította a csúcsot. A legjobbak közé tartozunk. A Zepter lehetőséget ad,hogy kiteljesíthessük lehetőségeinket és megvalósítsuk a bennünk rejlő ígéretet.",
        "Mindannyian művészek vagyunk; remekművet kovácsolhatunk az életünkből, csak akarnunk kell.",
    ];

    quotes.sort(() => 0.5 - Math.random());

    const quoteTemplate = (quote: string) => {
        return (
            <p className="flex max-w-max text-center align-items-center justify-content-center p-0 m0 font-italic line-height-3 white-space-normal z-5">
                {quote}
            </p>
        );
    };

    return (
        <div className="flex align-items-center justify-content-center grid mb-2">
            <div className="text-center z-2 -mb-4">
                <Badge
                    className="flex bg-green-500 text-white mt-4 md:mt-0"
                    value="Inspiráló gondolatok Philip
        Zeptertől"
                />
            </div>
            <Card className="w-full p-0 mt-3 mb-0 ml-3 mr-3 md:ml-0 md:mr-0">
                <div className="quotes">
                    <Carousel
                        value={quotes}
                        numVisible={1}
                        numScroll={1}
                        className="max-w-max ymt-0 pt-0 mb-0 pb-0 z-3 text-center"
                        circular
                        autoplayInterval={40000}
                        itemTemplate={quoteTemplate}
                        indicatorsContentClassName="quotesIndicators"
                        containerClassName="quotesContainer"
                        contentClassName="quotesContent"
                    />
                </div>
            </Card>
            <div className="w-full text-right z-2 -mt-6 mr-4 mb-0">
                <img
                    className="max-w-10rem"
                    src={PhilipZepterSigniture}
                    alt="Philip Zepter aláírás"
                />
            </div>
        </div>
    );
};

export default PhilipZepterQuotes;
