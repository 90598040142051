import clubMemberBannerSquareI from "../../asset/bc-nyj-2024-q1_1000x1000_I.jpg";
import clubMemberBannerWideI from "../../asset/bc-nyj-2024-q1_1000x250_I.jpg";
import clubMemberBannerSquareII from "../../asset/bc-nyj-2024-q1_1000x1000_II.jpg";
import clubMemberBannerWideII from "../../asset/bc-nyj-2024-q1_1000x250_II.jpg";
import "../../css/GeneralTerms.css";

const GeneralTermsCMNYJ2024Q1 = (props: any) => {
    return (
        <>
            <div className="flex flex-wrap shadow-2">
                <div className="w-full lg:w-12 px-3 py-3 lg:p-7 bg-blue-50">
                    <div className="col-12">
                        <div className="flex align-items-center justify-content-center">
                            <img
                                className="max-w-full mb-3 border-round-xl md:hidden"
                                src={clubMemberBannerSquareI}
                                alt="Zepter BizzClub nyereményjáték"
                            />
                            <img
                                className="hidden max-w-full mb-3 border-round-xl md:block"
                                src={clubMemberBannerWideI}
                                alt="Zepter BizzClub nyereményjáték"
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="flex align-items-center justify-content-center">
                            <img
                                className="max-w-full mb-3 border-round-xl md:hidden"
                                src={clubMemberBannerSquareII}
                                alt="Zepter BizzClub nyereményjáték"
                            />
                            <img
                                className="hidden max-w-full mb-3 border-round-xl md:block"
                                src={clubMemberBannerWideII}
                                alt="Zepter BizzClub nyereményjáték"
                            />
                        </div>
                    </div>
                    <div className="text-xl text-black-alpha-90 font-500 mt-3 mb-3 text-center">
                        NYEREMÉNYJÁTÉK
                        <br />
                        <br />
                        Játékszabályzat Zepter Klubtag hűségprogramhoz
                        kapcsolódó nyereményjátékhoz
                        <br />
                        <br />
                        Játékszabályzat 2024.01.01. – 2024.04.19.
                    </div>
                    <ol>
                        <li>
                            <span>A Játék</span>
                            <p>
                                Zepter Ungarn Kft. (Zepter Ungarn Kft.,
                                székhely: 1138 Budapest, Váci út 191..; Cg.:
                                01-09-076039; adószám: 10537361-2-41) a
                                továbbiakban: „Szervező” vagy „Zepter”)
                                nyereményjátékot szervez (a továbbiakban:
                                Nyereményjáték) a BizzClub hűségprogramhoz
                                történő csatlakozás és a tagság bővítése
                                érdekében. A Nyereményjátékban történő részvétel
                                feltétele a jelen Nyereményjáték feltételek
                                elfogadása. A Nyereményjátékban olyan személy
                                vehet részt, aki korábban nem került Klubtagként
                                regisztrálásra és aki kifejezetten nem
                                nyilatkozott a részvétel elutasításáról,
                                valamint teljesíti a Nyereményjáték feltételeit.
                            </p>
                        </li>
                        <li>
                            <span>Nyereményjátékban résztvevő személyek</span>
                            <p>
                                A Nyereményjátékban részt vehet minden 18.
                                életévét betöltött devizabelföldi, magyar
                                állampolgár, természetes személy, továbbá jogi
                                személy vagy jogi személyiséggel nem rendelkező
                                társaság, amennyiben nem minősül Klubtagnak, és
                                korábban sem került sor Klubtagként történő
                                regisztrálására és teljesíti a nyereményjáték
                                feltételeit, ide nem értve a Szervező
                                megbízásából a Nyereményjáték előkészítésében és
                                lebonyolításában közreműködő személyek és cégek
                                vezető tisztségviselői, munkavállalói,
                                megbízottjai, valamint ezen személyeknek a Ptk.
                                fentebb hivatkozott közeli hozzátartozói.
                            </p>
                        </li>
                        <li>
                            <span>
                                Feltételek, jelentkezés időtartama, sorsolás
                                időpontja
                            </span>
                            <p>
                                Nyereményjátékra történő jelentkezés 2024.01.01
                                00:00 órától 2024.03.31. 23:59 óráig tart, míg
                                2024.04.19. napján 10:00 órakor kerül sor a
                                sorsolásra. A Nyereményjáték megkezdése előtt,
                                és a Nyereményjáték befejezése után teljesített
                                feltételek érvénytelenek.
                            </p>
                            <span>
                                Nyereményjátékon történő részvétel feltétele:
                            </span>
                            <ol>
                                <li>
                                    Nyereményjátékra történő jelentkezés
                                    időtartama alatt{" "}
                                    <a
                                        href="https://bizzclub.hu/login"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        www.bizzclub.hu
                                    </a>
                                    ,{" "}
                                    <a
                                        href="https://www.cl100.hu/login"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        www.cl100.hu
                                    </a>
                                    ,{" "}
                                    <a
                                        href="https://www.zepter.hu/bizzclubwelcome"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        www.zepter.hu
                                    </a>
                                    &nbsp;felületen vagy a „Zepter App”-ban (
                                    <a
                                        href="https://www.zepter.app"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        www.zepter.app
                                    </a>
                                    ) a BizzClub hűségprogramba történő
                                    regisztráció és klubtaggá válás.
                                </li>
                                <li>
                                    Amennyiben a Klubtag (ideértve azt is, aki
                                    az 3.1. pont szerinti Nyereményjáték
                                    keretében vált Klubtaggá) új Klubtagot ajánl
                                    és az ajánlott új Klubtag a Nyereményjátékra
                                    történő jelentkezés időtartama Klubtaggá
                                    válik.
                                </li>
                                <p>
                                    A fenti feltételek teljesítése a
                                    Nyereményjátékon történő részvételre
                                    jogosít.
                                </p>
                            </ol>
                        </li>
                        <li>
                            <span>Sorsolás menete</span>
                            <p>
                                <strong>
                                    A Nyereményjáték sorsolás időpontja és
                                    helyszíne: 2024.04.19. 10:00 napja
                                    elektronikus sorsolással történik, Zepter
                                    budapesti székhelyén, a 1138 Budapest, Váci
                                    út 191. cím alatt. Szervező a nyeremény
                                    kategóriák sorrendjében 1 nyertest és 1
                                    tartalék nyertest húz.
                                </strong>
                            </p>
                        </li>
                        <li>
                            <span>Sorsolás nyereménye</span>
                            <p>
                                A Szervező a sorsolás alkalmával Nyereményenként
                                1 nyertest és 1 tartaléknyertest sorsol.
                                Amennyiben a Nyertes bármely okból nem jogosult
                                a nyereményre, vagy 30 napon belül nem elérhető,
                                úgy a Szervező értesíti és felkéri a nyeremény
                                átvételére az adott kategóriához kisorsolt,
                                soron következő tartaléknyertest.
                            </p>
                            <p>
                                A Szervező a sorsolást követően e-mailben
                                értesíti Nyertest a sorsolást követő 10 napon
                                belül.
                            </p>
                            <p>
                                A Nyeremény átadása a sorsolást követő 30 napon
                                belül történik.
                            </p>
                            <p>
                                <strong>Nyeremények:</strong>
                                <ul>
                                    <li>
                                        3.1. pont szerinti feltételek
                                        teljesítése esetén: BL-002 ArtMix PRO,
                                        értéke 264 900 Ft;
                                    </li>
                                    <li>
                                        3.2. pont szerinti feltételek
                                        teljesítése esetén: A Nyeremény szabadon
                                        választható Zepter Hyperlight Eyewear
                                        szemüveg modell 192.900 és 221.900 Ft
                                        közötti fogyasztói áron a Nyertes
                                        választása szerint (az alacsonyabb
                                        értékű szemüveg modell választása nem
                                        jogosít az esetleges különbözeti érték
                                        bármely formában történő igénylésére).
                                    </li>
                                </ul>
                            </p>
                            <p>
                                A nyertes a nyeremény átvételére az értesítést
                                követően, de legkésőbb a sorsolást követő 30
                                napon belül jogosult. Amennyiben a nyeremény
                                átvételére jogosult Játékos a nyereményét a
                                rendelkezésére álló határidőn belül nem venné
                                át, úgy a Szervező a fentiek szerint értesíti a
                                tartaléknyertest. Tartaléknyertes kiesése esetén
                                a Szervező nem köteles további tartaléknyertest
                                sorsolni. A játékban résztvevők vállalják, hogy
                                nyertességük esetén jogosultságukat személyi
                                azonosító irattal igazolják, és együttműködnek a
                                Szervezővel annak érdekében, hogy a nyeremény
                                átadása legkésőbb az adott sorsolást követő 30
                                napon belül megtörténjen. Amennyiben a nyertes
                                cselekvőképességében korlátozott vagy
                                cselekvőképtelen, úgy a nyereményével
                                kapcsolatos érdemi ügyintézésre vagy a nyeremény
                                átvételére nevében kizárólag törvényes
                                képviselője járhat el.
                            </p>
                            <p>A nyeremény pénzre nem váltható át.</p>
                            <p>
                                A nyertest a nyereménnyel kapcsolatosan nem
                                terheli személyi jövedelemadó-fizetési
                                kötelezettség. A nyeremény átvételével és
                                felhasználásával összefüggésben esetlegesen
                                felmerülő egyéb költségek és kiadások (pl.
                                nyeremény átadásának helyszínére történő utazás
                                költsége) a Nyertest terhelik.
                            </p>
                        </li>
                        <li>
                            <span>Egyéb rendelkezések</span>
                            <p>
                                Szervező a Játékkal kapcsolatos esetleges
                                módosításokat vagy annak visszavonását a{" "}
                                <a
                                    href="https://bizzclub.hu/login"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    www.bizzclub.hu
                                </a>{" "}
                                és a{" "}
                                <a
                                    href="https://zepter.hu"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    www.zepter.hu
                                </a>{" "}
                                oldalakon teszi közzé. Jelen nyereményjátékra a
                                szerencsejáték szervezéséről szóló 1991. évi
                                XXXIV. törvényben foglaltak irányadók.
                            </p>
                        </li>
                        <li>
                            <span>Adatkezeléshez hozzájárulás</span>
                            <p>
                                A Szervező minden személyes adatot, amelyhez a
                                Nyereményjáték során jutnak hozzá, bizalmasan és
                                a hatályos adatvédelmi szabályoknak, valamint
                                jelen Szabályzatban foglaltaknak megfelelően
                                kezelnek. Az adatkezelési tájékoztató a jelen
                                Szabályzat 1. sz. mellékletét képezi.
                            </p>
                        </li>
                    </ol>
                    <span>Budapest, 2023.12.31.</span>

                    <hr />
                    <div className="text-xl text-black-alpha-90 font-500 mb-3 text-center">
                        1. számú Melléklet
                        <br />
                        <strong>Adatkezelési tájékoztató</strong>
                    </div>
                    <p>
                        Adatkezelési tájékoztató Zepter 2024.01.01. –
                        2024.04.19. között szervezett Nyereményjátékhoz
                        kapcsolódóan
                        <br />
                        <span>Adatkezelő:</span> Zepter Ungarn Kft. (székhely:
                        1138 Budapest, Váci út 191.; cjsz.: 01-09-076039;
                        Adószám: 10537361-2-41), a továbbiakban “Adatkezelő”.{" "}
                        <span>Adattovábbítás:</span> Adatkezelő által történő
                        adatfelvétel esetén az adatokat az adatkezelő kezeli. Az
                        adattovábbításra nem kerül sor (azaz nem kerül sor
                        harmadik országba történő adatátadásra).
                        <br />
                        <span>
                            Adatkezelés célja, jogalapja, kezelt adatok köre
                            (adatkategóriák), adatkezelés időtartama:
                            Adatkezelés célja:
                        </span>{" "}
                        <span>
                            Promóciós nyereményjáték lebonyolítása, játékosok
                            nyilvántartása, nyeremények átadása.
                        </span>{" "}
                        Adatkezelés jogalapja: GDPR 6.§. (1) b. és c. pont –
                        nyereményjátékra vonatkozó szerződés teljesítése,
                        résztvevők nyilvántartása, megkülönböztetése, sorsolás
                        szervezése, nyertes és tartaléknyertes nyilvántartása,
                        nyertes és/vagy tartaléknyertes nevének és
                        irányítószámának nyilvánosságra hozatala;
                        <br />
                        <span>Adatkategória:</span> teljes név, e-mail cím,
                        telefonszám, irányítószám, nyeremény esetén nyertes
                        adatai, termékadatok, nyereményjáték azonosítói; jogi
                        személyek vagy jogi személyiség nélküli szervezetek
                        esetében cégnév, adószám, székhely, kapcsolattartó neve,
                        e-mail cím és telefonszám. Adatkezelés időtartama: a
                        nyereményjáték lezárultától számított 1 év + 60 nap,
                        nyertes esetében a jótállási időt követő 5 év és 60 nap.
                        A sorsolást megelőzően a nyereményjátékból kilépés
                        esetén jogi kötelezettség teljesítése miatt 5 év + 60
                        nap.
                        <br />
                        <span>Adatfeldolgozó:</span> Adatkezelő munkatársai
                        és/vagy megbízottai végzik a jelen hozzájárulás alapján
                        átadott adatokkal kapcsolatos tevékenységeket. Bizonyos
                        esetekben, az erről történő megfelelő tájékoztatás
                        mellett Adatfeldolgozó a címzettek csoportjait jelöli
                        meg, mint lehetséges adatfeldolgozó. Címzettek egy
                        csoportja esetében a csoport tagjai az adatkezelési
                        időtartam alatt a gazdasági szükségszerűség miatt akár
                        több alkalommal is változhatnak, a csoport tagjainak
                        fluktuációja miatt pedig Adatkezelő valamennyi
                        adatgyűjtést megelőzően nem, vagy nem teljeskörűen,
                        avagy nem átlátható (jelentős mennyiségre figyelemmel)
                        módon tudná csak tájékoztatni az érintettet a konkrét
                        adatfeldolgozó személyéről. Amennyiben azonban az
                        érintett tájékoztatást kér arra vonatkozóan, hogy
                        Adatkezelő a tevékenysége során konkrétan mely
                        csoporttag részére adott át személyes adatot, úgy az
                        érintett kérésére tájékoztatást nyújt.
                        <br />
                        <span>Adattovábbítás:</span>Az adattovábbítás Svájcba
                        történik, ahol a regisztrált adatok tárolására kerül
                        sor. A GDPR 45. Cikke alapján az adattovábbítás Svájcba
                        külön nyilatkozat vagy hozzájárulás nélkül is
                        megvalósítható, figyelemmel az Európai Unió Bizottsága
                        által hozott megfelelőségi határozat alapján (A
                        BIZOTTSÁG HATÁROZATA a 95/46/EK európai parlamenti és
                        tanácsi irányelv alapján, a személyes adatok Svájcban
                        biztosított megfelelő védelméről (2000. július 26.),
                        közzétéve: AZ EURÓPAI KÖZÖSSÉGEK HIVATALOS LAPJA L
                        215/1). Az adattovábbítás kapcsán az adatfeldolgozó a
                        Amazon Web Services EMEA SARL (38 avenue John F.
                        Kennedy, L-1855 Luxembourg).
                        <br />
                        <span>Önkéntes hozzájárulás:</span> A jelen adatkezelés
                        önkéntes. A hozzájárulás bármikor, korlátozás nélkül
                        visszavonható, azzal, hogy a hozzájárulás visszavonása
                        előtt végrehajtott adatkezelés jogszerűségét a
                        visszavonás nem érinti. A Nyereményjátékhoz kapcsolódó
                        adatkezelési hozzájárulás visszavonása egyúttal a
                        Nyereményjátékban történő részvétel megszüntetését is
                        jelenti. Amennyiben a hozzájárulást visszavonja, úgy az
                        adatait a jogszerű adatkezelést igazoló nyilvántartásba
                        veszi fel az Adatkezelő.
                        <br />
                        <span>Profilalkotás:</span> Adatkezelő az adatkezelés
                        során profilalkotást nem végez.
                        <br />
                        <span>Érintett jogai:</span> A helyesbítéshez való jog:
                        Az adatai pontosítását, javítását, helyesbítését
                        kérheti.
                        <br />
                        <span>A törléshez való jog:</span> Bizonyos esetekben
                        kérheti, hogy személyes adatai törlésre kerüljenek, így
                        az eredeti adatkezelési cél érdekében már nincs szükség
                        az adatai tárolására, vagy hozzájáruláson alapuló
                        adatkezelés esetén visszavonja hozzájárulását, feltéve,
                        hogy az adatok törlését jogszabály vagy egyéb jogi
                        kötelezettség teljesítése nem zárja ki.
                        <br />
                        <span>Az adatkezelés korlátozásához való jog:</span>
                        Bizonyos esetekben joga van kérni az adatok zárolását
                        (fel nem használását) valamely intézkedés
                        megtörténtének, vagy joga gyakorlásának elbírálásáig.
                        <br />
                        <span>Adathordozhatósághoz való jog:</span> Önnek joga
                        van kérni elektronikus adatkezelés esetén, hogy adjuk át
                        személyes adatait az Ön által kijelölt harmadik fél
                        részére. Az adatokat „.xls” vagy „.pdf” fájlformátumban
                        adjuk ki.
                        <br />
                        <span>Tiltakozáshoz való jog:</span> Ön tiltakozhat
                        adatai kezelése ellen, amennyiben úgy látja, egyes
                        adatkezelési cél érdekében már szükségtelen a további
                        adatkezelés, kivéve ha az adatkezelő igazolja, hogy az
                        adatkezelés jogszerű és szükséges.
                        <br />
                        <span>Felügyeleti hatóság:</span> Ön jogosult az
                        adatkezeléssel szemben panasszal a Felügyeleti
                        hatósághoz fordulni. A felügyeleti hatóság a Nemzeti
                        Adatvédelmi és Információszabadság Hatóság.
                        <br />
                        Nemzeti Adatvédelmi és Információszabadság Hatóság
                        elérhetőségei:
                        <br />
                        cím: 1055 Budapest, Falk Miksa u. 9-11. | telefon:
                        +36-1-391-1400 | fax: +36 -1-391-1410 | e-mail:
                        <a href="mailto:ugyfelszolgalat@naih.hu">
                            ugyfelszolgalat@naih.hu
                        </a>{" "}
                        | internetcím:{" "}
                        <a
                            href="https://naih.hu"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://naih.hu
                        </a>
                        <br />
                        <span>Irányadó jog és szabályzat:</span> A személyes
                        adatok kezelésére a természetes személyeknek a személyes
                        adatok kezelése tekintetében történő védelméről és az
                        ilyen adatok szabad áramlásáról, valamint a 95/46/EK
                        rendelet hatályon kívül helyezéséről szóló az EURÓPAI
                        PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE (“GDPR”
                        vagy “általános adatvédelmi rendelet”) az irányadó. Az
                        adatvédelmi szabályzat az Adatkezelő{" "}
                        <a
                            href="https://www.zepter.hu/rules/adatkezeles"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.zepter.hu/rules/adatkezeles
                        </a>{" "}
                        honlapján, székhelyén valamint egyébként a kereskedő
                        munkatársaknál, megbízottaknál minden esetben elérhető.
                    </p>
                </div>
            </div>
        </>
    );
};

export default GeneralTermsCMNYJ2024Q1;
