import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { setActiveMenuItem } from "../../state/dashboard/navigationSlice";
import { setUserProfile, setUserRanks } from "../../state/dashboard/userSlice";
import { StatusCodes } from "http-status-codes";
import {
    setAuthToken,
    getCurrentUser,
    logout,
} from "../../service/dashboard/AuthService";
import { StyleClass } from "primereact/styleclass";
import { Badge } from "primereact/badge";
import { Ripple } from "primereact/ripple";
import {
    readProfile,
    readUserRanks,
} from "../../service/dashboard/ProfileService";
import { readAllProducts } from "../../service/dashboard/webshop/ProductService";
import { isOnlinePartnerInAMonth } from "../../util/dashboard/OnlinePartnerProgramUtil";
import {
    replaceArabicZeterMonthToRoman,
    replaceShortRanks,
    setNumberStringThousandSeparator,
} from "../../util/stringFormat";
import Profile from "./Profile";
import Dashboard from "./Dashboard";
import ClubMemberAdminList from "./admin/ClubMemberAdminList";
import ClubMemberAdminDetail from "./admin/ClubMemberAdminDetail";
import DocumentAdminList from "./admin/DocumentAdminList";
import ProductAdminList from "./admin/webshop/ProductAdminList";
import PriceGroupAdminList from "./admin/webshop/PriceGroupAdminList";
import CategoryAdminList from "./admin/webshop/ProductCategoryAdminList";
import PromoAdminList from "./admin/webshop/PromoAdminList";
import ProductList from "./webshop/ProductList";
import ProductDetail from "./webshop/ProductDetail";
import Checkout from "./webshop/Checkout";
import ConsultantRegistration from "./ConsultantRegistration";
import RecommanderLink from "./RecommenderLink";
import ClubMemberSearch from "./ClubMemberSearch";
import ClubMemberStructure from "./ClubMemberStructure";
import ClubMemberInvitation from "./ClubMemberInvitation";
import ClubMemberPendingInvitedList from "./ClubMemberPendingInvitedList";
import RepresentativeStructure from "./RepresentativeStructure";
import Information from "./Information";
import GeneralTermsConsultant220615 from "../regulation/GeneralTermsConsultant220615";
import { setLoadingOff } from "../../state/dashboard/mainSlice";
import { setCart, setProductList } from "../../state/dashboard/webshopSlice";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Divider } from "primereact/divider";
import { ProgressSpinner } from "primereact/progressspinner";
import { BlockUI } from "primereact/blockui";
import zepterCl100LogoLightBlue from "../../asset/zepter_bizzclub_logo_black.png";
import noImageAvailable from "../../asset/no_image_available.png";
import SimplePayTestSending from "./SimplePayTestSending";
import SimplePayTestResponse from "./SimplePayTestResponse";
import CheckoutSimplePayTest from "./webshop/CheckoutSimplePayTest";
import InstallmentPayment from "./InstallmentPayment";

const Main: React.FC = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    setAuthToken();

    const activeMenuItem: string = useSelector(
        (state: RootState) => state.navigation.activeMenuItem
    );

    const cart: [] = useSelector((state: RootState) => state.webshop.cart);
    const cartSummary: any = useSelector(
        (state: RootState) => state.webshop.cartSummary
    );

    const loading: any = useSelector((state: RootState) => state.main.loading);

    const btnRefSidebar = useRef(null);
    const btnRefDashboard = useRef(null);
    const btnRefAdministration = useRef(null);
    const btnRefConsultantRegistration = useRef(null);
    const btnRefClubMemberAdminList = useRef(null);
    const btnRefDocumentAdmin = useRef(null);

    const btnRefWebshopAdministration = useRef(null);
    const btnRefProductAdmin = useRef(null);
    const btnRefPriceGroupAdmin = useRef(null);
    const btnRefCategoryAdmin = useRef(null);
    const btnRefPromoAdmin = useRef(null);
    const btnRefSimplePay = useRef(null);

    const btnRefWebshop = useRef(null);
    const btnRefProductList = useRef(null);
    const btnRefAffiliate = useRef(null);
    const btnRefRecommenderLink = useRef(null);
    const btnRefClubMemberInvitation = useRef(null);
    const btnRefClubMemberPendingInvitedList = useRef(null);
    const btnRefClubMemberSearch = useRef(null);
    const btnRefStructure = useRef(null);
    const btnRefClubMemberStructure = useRef(null);
    const btnRefRepresentativeStructure = useRef(null);
    const btnRefInformation = useRef(null);
    const btnRefInstallmentPayment = useRef(null);
    const btnRefTopbar = useRef(null);
    const btnRefProfil = useRef(null);
    const btnRefCartOpen1 = useRef(null);
    const btnRefCartOpen2 = useRef(null);
    const btnRefCartClose1 = useRef(null);
    const btnRefCartClose2 = useRef(null);
    const btnRefCartClose3 = useRef(null);

    const userProfile: any = useSelector(
        (state: RootState) => state.user.userProfile
    );

    const clear = () => {
        logout();
        dispatch(setUserProfile({}));
        dispatch(setUserRanks([]));
        dispatch(setLoadingOff());
        navigate("/login");
        //TODO redux üríteni
        // console.log('Logout is called');
    };

    // const cartTotalPriceCount = () => {
    //     let sumPrice = 0;
    //     cart.forEach((item: any) => {
    //         sumPrice += item.productPlPrice * item.productQuantity;
    //     });
    //     return sumPrice;
    // };

    // const cartItemsNumberCount = () => {
    //     let sumItems = 0;
    //     cart.forEach((item: any) => {
    //         sumItems += item.productQuantity;
    //     });
    //     return sumItems;
    // };

    // const localStorageCl100User = getCurrentUser();
    // console.log(localStorageCl100User.email);

    // TODO 2023.06.30-án kikapcsolni
    const [createdCart, setCreatedCart] = useState<any>([]);
    const [onlinePartnerInAMonthDiscount, setOnlinePartnerInAMonthDiscount] =
        useState<boolean>(false);
    const [originalBonusItemQuantity, setOriginalBonusItemQuantity] =
        useState<number>(0);
    const [originalBonusItemNumber, setOriginalBonusItemNumber] =
        useState<string>("");
    const [bonusMinusPrice, setBonusMinusPrice] = useState<string>("0");
    useEffect(() => {
        let cartWithHalfPrice: any = [];
        // const halfPriceItem: any = null;
        let isHalfPriceProductInArray: boolean = false;
        if (
            userProfile.representativeRegistrationDate &&
            isOnlinePartnerInAMonth(userProfile.representativeRegistrationDate)
                .discount
        ) {
            setOnlinePartnerInAMonthDiscount(
                isOnlinePartnerInAMonth(
                    userProfile.representativeRegistrationDate
                ).discount
            );
            cart.forEach((item: any) => {
                if (item.productHalfPrice === false) {
                    cartWithHalfPrice = [...cartWithHalfPrice, item];
                } else {
                    setOriginalBonusItemNumber(item.productItemNumber);
                    isHalfPriceProductInArray = true;
                    if (item.productQuantity === 1) {
                        setOriginalBonusItemQuantity(1);
                        const newHalfPriceItem = { ...item };
                        newHalfPriceItem.productPlDiscountPercentage = 50;
                        newHalfPriceItem.productPlPrice = Number(
                            newHalfPriceItem.productRrpPrice / 2
                        ).toString();
                        newHalfPriceItem.productPlTotalPrice =
                            newHalfPriceItem.productPlPrice;
                        newHalfPriceItem.productTotalPrice =
                            newHalfPriceItem.productPlPrice;
                        setBonusMinusPrice(
                            (
                                Number(item.productPlPrice) -
                                Number(newHalfPriceItem.productPlPrice)
                            ).toString()
                        );
                        cartWithHalfPrice = [
                            ...cartWithHalfPrice,
                            newHalfPriceItem,
                        ];
                    } else if (item.productQuantity > 0) {
                        // szét kell szedni kettőbe
                        // 1, eredeti termék módosítása
                        const originalItem = { ...item };
                        --originalItem.productQuantity;
                        setOriginalBonusItemQuantity(
                            originalItem.productQuantity
                        );
                        originalItem.productRrpTotalPrice = (
                            Number(originalItem.productRrpTotalPrice) -
                            Number(originalItem.productRrpPrice)
                        ).toString();
                        originalItem.productPlTotalPrice = (
                            Number(originalItem.productPlTotalPrice) -
                            Number(originalItem.productPlPrice)
                        ).toString();
                        originalItem.productTotalPrice =
                            originalItem.productPlTotalPrice;
                        originalItem.productHalfPrice = false;
                        cartWithHalfPrice = [
                            ...cartWithHalfPrice,
                            originalItem,
                        ];
                        // 2, új 50%-os bónusz létrehozása
                        const newHalfPriceItem = { ...item };
                        newHalfPriceItem.productQuantity = 1;
                        newHalfPriceItem.productPlDiscountPercentage = 50;
                        newHalfPriceItem.productPlPrice = Number(
                            item.productRrpPrice / 2
                        ).toString();
                        newHalfPriceItem.productRrpTotalPrice =
                            newHalfPriceItem.productRrpPrice;
                        newHalfPriceItem.productPlTotalPrice =
                            newHalfPriceItem.productPlPrice;
                        newHalfPriceItem.productTotalPrice =
                            newHalfPriceItem.productPlPrice;
                        setBonusMinusPrice(
                            (
                                Number(item.productPlPrice) -
                                Number(newHalfPriceItem.productPlPrice)
                            ).toString()
                        );
                        cartWithHalfPrice = [
                            ...cartWithHalfPrice,
                            newHalfPriceItem,
                        ];
                    }
                }
            });
            if (!isHalfPriceProductInArray) {
                setOriginalBonusItemQuantity(0);
                setOriginalBonusItemNumber("");
                setBonusMinusPrice("0");
            }
            setCreatedCart(cartWithHalfPrice);
        } else {
            setCreatedCart(cart);
        }
    }, [cart, userProfile.representativeRegistrationDate]);

    useEffect(() => {
        // console.log("MessageDialog useEffect is called");
        readProfile(getCurrentUser().email).then(
            (response) => {
                // setUser(response.data);
                dispatch(setUserProfile(response.data));
                // console.log(response.data);
                if (
                    response.data.representativeNumber &&
                    response.data.representativeNumber !==
                        "Ön még nem tanácsadó"
                ) {
                    readUserRanks(response.data.representativeNumber).then(
                        (response) => {
                            // console.log(response.data.rankList);
                            response.data.rankList.map((rank: any) => {
                                rank.zepterMonth =
                                    replaceArabicZeterMonthToRoman(
                                        rank.zepterMonth
                                    );
                                rank.rank = replaceShortRanks(rank.rank);
                                rank.fromDate =
                                    rank.fromDate.split(" ")[0] + ".";
                                rank.toDate = rank.toDate.split(" ")[0] + ".";
                                return rank;
                            });
                            dispatch(
                                setUserRanks(response.data.rankList.reverse())
                            );
                            // console.log(response.data);
                            readAllProducts().then(
                                (response) => {
                                    // console.log(response);
                                    dispatch(
                                        setProductList(
                                            response.data.productList
                                        )
                                    );
                                    dispatch(setLoadingOff());
                                },
                                (error) => {
                                    console.log(error.response);
                                }
                            );
                        },
                        (error) => {
                            // console.log(error.response.status);
                            if (
                                error &&
                                error.response &&
                                error.response.status &&
                                error.response.status ===
                                    StatusCodes.UNAUTHORIZED
                            ) {
                                logout();
                                dispatch(setUserProfile({}));
                                dispatch(setUserRanks([]));
                                dispatch(setLoadingOff());
                                navigate("/login?status=unauthorized");
                            }
                        }
                    );
                } else {
                    readAllProducts().then(
                        (response) => {
                            // console.log(response);
                            dispatch(setProductList(response.data.productList));
                            dispatch(setLoadingOff());
                        },
                        (error) => {
                            console.log(error.response);
                        }
                    );
                }
            },
            (error) => {
                // console.log(error.response.status);
                if (
                    error &&
                    error.response &&
                    error.response.status &&
                    error.response.status === StatusCodes.UNAUTHORIZED
                ) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    dispatch(setUserProfile({}));
                    dispatch(setUserRanks([]));
                    dispatch(setLoadingOff());
                    navigate("/login?status=unauthorized");
                } else if (error && error.response && error.response.status) {
                    logout();
                    dispatch(setUserProfile({}));
                    dispatch(setUserRanks([]));
                    dispatch(setLoadingOff());
                    navigate("/login?status=connectionerror");
                }
            }
        );
    }, [dispatch, navigate]);

    return (
        <div className="min-h-screen flex relative lg:static surface-ground">
            <div
                id="app-sidebar"
                className={
                    "surface-section h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none z-5"
                }
                style={{ width: "300px" }}
            >
                <div className="flex flex-column h-full z-5">
                    <div
                        className="flex align-items-center justify-content-center px-5 flex-shrink-0"
                        style={{ height: "60px" }}
                    >
                        <img
                            src={zepterCl100LogoLightBlue}
                            height={40}
                            alt="Zepter Club Live 100 logo"
                        />
                    </div>
                    <div className="overflow-y-auto">
                        <ul key="ul1" className="list-none p-3 m-0 z-5">
                            <ul
                                key="ul2"
                                className="list-none p-0 m-0 overflow-hidden"
                            >
                                <li key="li1">
                                    <StyleClass
                                        nodeRef={btnRefDashboard}
                                        selector="#app-sidebar"
                                        leaveToClassName="hidden"
                                        // leaveActiveClassName="fadeoutleft"
                                    >
                                        <nav
                                            ref={btnRefDashboard}
                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                            // text-indigo-500
                                            style={{ borderRadius: "12px" }}
                                            onClick={() => {
                                                navigate("dashboard");
                                                dispatch(
                                                    setActiveMenuItem(
                                                        "dashboard"
                                                    )
                                                );
                                            }}
                                        >
                                            <i className="pi pi-home mr-2 text-indigo-500"></i>
                                            <span
                                                className={
                                                    activeMenuItem ===
                                                    "dashboard"
                                                        ? "font-medium text-indigo-500"
                                                        : "font-medium"
                                                }
                                            >
                                                Dashboard
                                            </span>
                                            <Ripple />
                                        </nav>
                                    </StyleClass>
                                </li>
                                {userProfile &&
                                (userProfile.zepterRole === "ADMIN" ||
                                    userProfile.zepterRole === "VALIDATOR" ||
                                    userProfile.zepterRole ===
                                        "PRODUCTMANAGER" ||
                                    userProfile.zepterRole === "VIEWER" ||
                                    userProfile.email ===
                                        "teszt@simplepay.hu") ? (
                                    <li key="li2">
                                        <StyleClass
                                            nodeRef={btnRefAdministration}
                                            selector="@next"
                                            enterClassName="hidden"
                                            enterActiveClassName="slidedown"
                                            leaveToClassName="hidden"
                                            leaveActiveClassName="slideup"
                                        >
                                            <nav
                                                ref={btnRefAdministration}
                                                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                style={{
                                                    borderRadius: "12px",
                                                }}
                                            >
                                                <i className="pi pi-chart-line mr-2 text-orange-500"></i>
                                                <span
                                                    className={
                                                        activeMenuItem ===
                                                            "clubmemberadminlist" ||
                                                        activeMenuItem ===
                                                            "documentadminlist" ||
                                                        activeMenuItem ===
                                                            "productadminlist" ||
                                                        activeMenuItem ===
                                                            "pricegroupadminlist" ||
                                                        activeMenuItem ===
                                                            "productcategoryadminlist" ||
                                                        activeMenuItem ===
                                                            "promoadminlist"
                                                            ? "font-medium text-orange-500"
                                                            : "font-medium"
                                                    }
                                                >
                                                    Adminisztráció
                                                </span>
                                                <i className="pi pi-chevron-down ml-auto"></i>
                                                <Ripple />
                                            </nav>
                                        </StyleClass>
                                        <ul
                                            key="ul3"
                                            className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out"
                                        >
                                            {userProfile &&
                                            (userProfile.zepterRole ===
                                                "ADMIN" ||
                                                userProfile.zepterRole ===
                                                    "VALIDATOR" ||
                                                userProfile.zepterRole ===
                                                    "VIEWER") ? (
                                                // TODO ide kell
                                                // <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                <li key="li3">
                                                    <StyleClass
                                                        nodeRef={
                                                            btnRefClubMemberAdminList
                                                        }
                                                        selector="#app-sidebar"
                                                        leaveToClassName="hidden"
                                                        // leaveActiveClassName="fadeoutleft"
                                                    >
                                                        <nav
                                                            ref={
                                                                btnRefClubMemberAdminList
                                                            }
                                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                            style={{
                                                                borderRadius:
                                                                    "12px",
                                                            }}
                                                            onClick={() => {
                                                                navigate(
                                                                    "clubmemberadminlist"
                                                                );
                                                                dispatch(
                                                                    setActiveMenuItem(
                                                                        "clubmemberadminlist"
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <i className="pi pi-id-card mr-2 text-orange-500"></i>
                                                            <span
                                                                className={
                                                                    activeMenuItem ===
                                                                    "clubmemberadminlist"
                                                                        ? "font-medium text-orange-500"
                                                                        : "font-medium"
                                                                }
                                                            >
                                                                Zepter Club
                                                                tagok
                                                            </span>
                                                            <Ripple />
                                                        </nav>
                                                    </StyleClass>
                                                </li>
                                            ) : null}
                                            {userProfile &&
                                            (userProfile.zepterRole ===
                                                "ADMIN" ||
                                                userProfile.zepterRole ===
                                                    "PRODUCTMANAGER") ? (
                                                // TODO ide kell
                                                // <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                <li key="li4">
                                                    <StyleClass
                                                        nodeRef={
                                                            btnRefDocumentAdmin
                                                        }
                                                        selector="#app-sidebar"
                                                        leaveToClassName="hidden"
                                                        // leaveActiveClassName="fadeoutleft"
                                                    >
                                                        <nav
                                                            ref={
                                                                btnRefDocumentAdmin
                                                            }
                                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                            style={{
                                                                borderRadius:
                                                                    "12px",
                                                            }}
                                                            onClick={() => {
                                                                navigate(
                                                                    "documentadminlist"
                                                                );
                                                                dispatch(
                                                                    setActiveMenuItem(
                                                                        "documentadminlist"
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <i className="pi pi-file-pdf mr-2 text-orange-500"></i>
                                                            <span
                                                                className={
                                                                    activeMenuItem ===
                                                                    "documentadminlist"
                                                                        ? "font-medium text-orange-500"
                                                                        : "font-medium"
                                                                }
                                                            >
                                                                Dokumentumok
                                                            </span>
                                                            <Ripple />
                                                        </nav>
                                                    </StyleClass>
                                                </li>
                                            ) : null}
                                            {/* TODO permission: admin, validator, viewer */}
                                            {/* <li key="li5" >
                                                <nav
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                    style={{
                                                        borderRadius: "12px"
                                                    }}
                                                >
                                                    <i className="pi pi-briefcase mr-2 text-orange-500"></i>
                                                    <span className="font-medium">
                                                        Tanácsadók
                                                    </span>
                                                    <Ripple />
                                                </nav>
                                            </li> */}
                                            {/* TODO permission: admin, validator, viewer */}
                                            {/* <li key="li5" >
                                                <nav
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                    style={{
                                                        borderRadius: "12px"
                                                    }}
                                                >
                                                    <i className="pi pi-credit-card mr-2 text-orange-500"></i>
                                                    <span className="font-medium">
                                                        Megrendelések
                                                    </span>
                                                    <Ripple />
                                                </nav>
                                            </li> */}
                                            {/* TODO permission: admin, product manager, viewer */}
                                            {userProfile &&
                                            (userProfile.zepterRole ===
                                                "ADMIN" ||
                                                userProfile.zepterRole ===
                                                    "PRODUCTMANAGER" ||
                                                userProfile.email ===
                                                    "teszt@simplepay.hu") ? (
                                                <li key="li6">
                                                    <StyleClass
                                                        nodeRef={
                                                            btnRefWebshopAdministration
                                                        }
                                                        selector="@next"
                                                        enterClassName="hidden"
                                                        enterActiveClassName="slidedown"
                                                        leaveToClassName="hidden"
                                                        leaveActiveClassName="slideup"
                                                    >
                                                        <nav
                                                            ref={
                                                                btnRefWebshopAdministration
                                                            }
                                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                            style={{
                                                                borderRadius:
                                                                    "12px",
                                                            }}
                                                        >
                                                            <i className="pi pi-cog mr-2 text-orange-500"></i>
                                                            <span
                                                                className={
                                                                    activeMenuItem ===
                                                                        "productadminlist" ||
                                                                    activeMenuItem ===
                                                                        "pricegroupadminlist" ||
                                                                    activeMenuItem ===
                                                                        "productcategoryadminlist" ||
                                                                    activeMenuItem ===
                                                                        "promoadminlist"
                                                                        ? "font-medium text-orange-500"
                                                                        : "font-medium"
                                                                }
                                                            >
                                                                Webshop
                                                            </span>
                                                            <i className="pi pi-chevron-down ml-auto"></i>
                                                            <Ripple />
                                                        </nav>
                                                    </StyleClass>
                                                    <ul
                                                        key="ul4"
                                                        className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out"
                                                    >
                                                        {userProfile &&
                                                        userProfile.email !==
                                                            "teszt@simplepay.hu" ? (
                                                            <>
                                                                <li key="li7">
                                                                    <StyleClass
                                                                        nodeRef={
                                                                            btnRefProductAdmin
                                                                        }
                                                                        selector="#app-sidebar"
                                                                        leaveToClassName="hidden"
                                                                        // leaveActiveClassName="fadeoutleft"
                                                                    >
                                                                        <nav
                                                                            ref={
                                                                                btnRefProductAdmin
                                                                            }
                                                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                                            style={{
                                                                                borderRadius:
                                                                                    "12px",
                                                                            }}
                                                                            onClick={() => {
                                                                                navigate(
                                                                                    "productadminlist"
                                                                                );
                                                                                dispatch(
                                                                                    setActiveMenuItem(
                                                                                        "productadminlist"
                                                                                    )
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i className="pi pi-book mr-2 text-orange-500"></i>
                                                                            <span
                                                                                className={
                                                                                    activeMenuItem ===
                                                                                    "productadminlist"
                                                                                        ? "font-medium text-orange-500"
                                                                                        : "font-medium"
                                                                                }
                                                                            >
                                                                                Termékek
                                                                            </span>
                                                                            <Ripple />
                                                                        </nav>
                                                                    </StyleClass>
                                                                </li>
                                                                <li key="li8">
                                                                    <StyleClass
                                                                        nodeRef={
                                                                            btnRefPriceGroupAdmin
                                                                        }
                                                                        selector="#app-sidebar"
                                                                        leaveToClassName="hidden"
                                                                        // leaveActiveClassName="fadeoutleft"
                                                                    >
                                                                        <nav
                                                                            ref={
                                                                                btnRefPriceGroupAdmin
                                                                            }
                                                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                                            style={{
                                                                                borderRadius:
                                                                                    "12px",
                                                                            }}
                                                                            onClick={() => {
                                                                                navigate(
                                                                                    "pricegroupadminlist"
                                                                                );
                                                                                dispatch(
                                                                                    setActiveMenuItem(
                                                                                        "pricegroupadminlist"
                                                                                    )
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i className="pi pi-money-bill mr-2 text-orange-500"></i>
                                                                            <span
                                                                                className={
                                                                                    activeMenuItem ===
                                                                                    "pricegroupadminlist"
                                                                                        ? "font-medium text-orange-500"
                                                                                        : "font-medium"
                                                                                }
                                                                            >
                                                                                Árak
                                                                            </span>
                                                                            <Ripple />
                                                                        </nav>
                                                                    </StyleClass>
                                                                </li>
                                                                <li key="li9">
                                                                    <StyleClass
                                                                        nodeRef={
                                                                            btnRefCategoryAdmin
                                                                        }
                                                                        selector="#app-sidebar"
                                                                        leaveToClassName="hidden"
                                                                        // leaveActiveClassName="fadeoutleft"
                                                                    >
                                                                        <nav
                                                                            ref={
                                                                                btnRefCategoryAdmin
                                                                            }
                                                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                                            style={{
                                                                                borderRadius:
                                                                                    "12px",
                                                                            }}
                                                                            onClick={() => {
                                                                                navigate(
                                                                                    "productcategoryadminlist"
                                                                                );
                                                                                dispatch(
                                                                                    setActiveMenuItem(
                                                                                        "productcategoryadminlist"
                                                                                    )
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i className="pi pi-tags mr-2 text-orange-500"></i>
                                                                            <span
                                                                                className={
                                                                                    activeMenuItem ===
                                                                                    "productcategoryadminlist"
                                                                                        ? "font-medium text-orange-500"
                                                                                        : "font-medium"
                                                                                }
                                                                            >
                                                                                Kategóriák
                                                                            </span>
                                                                            <Ripple />
                                                                        </nav>
                                                                    </StyleClass>
                                                                </li>
                                                                <li key="li10">
                                                                    <StyleClass
                                                                        nodeRef={
                                                                            btnRefPromoAdmin
                                                                        }
                                                                        selector="#app-sidebar"
                                                                        leaveToClassName="hidden"
                                                                        // leaveActiveClassName="fadeoutleft"
                                                                    >
                                                                        <nav
                                                                            ref={
                                                                                btnRefPromoAdmin
                                                                            }
                                                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                                            style={{
                                                                                borderRadius:
                                                                                    "12px",
                                                                            }}
                                                                            onClick={() => {
                                                                                navigate(
                                                                                    "promoadminlist"
                                                                                );
                                                                                dispatch(
                                                                                    setActiveMenuItem(
                                                                                        "promoadminlist"
                                                                                    )
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i className="pi pi-percentage mr-2 text-orange-500"></i>
                                                                            <span
                                                                                className={
                                                                                    activeMenuItem ===
                                                                                    "promoadminlist"
                                                                                        ? "font-medium text-orange-500"
                                                                                        : "font-medium"
                                                                                }
                                                                            >
                                                                                Promociók
                                                                            </span>
                                                                            <Ripple />
                                                                        </nav>
                                                                    </StyleClass>
                                                                </li>
                                                            </>
                                                        ) : null}

                                                        <li key="li999">
                                                            <StyleClass
                                                                nodeRef={
                                                                    btnRefSimplePay
                                                                }
                                                                selector="#app-sidebar"
                                                                leaveToClassName="hidden"
                                                                // leaveActiveClassName="fadeoutleft"
                                                            >
                                                                <nav
                                                                    ref={
                                                                        btnRefSimplePay
                                                                    }
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                                    style={{
                                                                        borderRadius:
                                                                            "12px",
                                                                    }}
                                                                    onClick={() => {
                                                                        navigate(
                                                                            "checkoutsimplepaytest"
                                                                        );
                                                                        dispatch(
                                                                            setActiveMenuItem(
                                                                                "checkoutsimplepaytest"
                                                                            )
                                                                        );
                                                                    }}
                                                                >
                                                                    <i className="pi pi-credit-card mr-2 text-orange-500"></i>
                                                                    <span
                                                                        className={
                                                                            activeMenuItem ===
                                                                            "checkoutsimplepaytest"
                                                                                ? "font-medium text-orange-500"
                                                                                : "font-medium"
                                                                        }
                                                                    >
                                                                        SimplePay
                                                                        Test
                                                                        Checkout
                                                                    </span>
                                                                    <Ripple />
                                                                </nav>
                                                            </StyleClass>
                                                        </li>
                                                    </ul>
                                                </li>
                                            ) : null}
                                        </ul>
                                    </li>
                                ) : null}
                                {/* <li key="li11" >
                                                    <nav
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{
                                                            borderRadius:
                                                                "12px",
                                                        }}
                                                    >
                                                        <i className="pi pi-cog mr-2 text-orange-500"></i>
                                                        <span className="font-medium">
                                                            Webshop
                                                        </span>
                                                        <Ripple />
                                                    </nav>
                                                </li> */}
                                {/* TODO permission: admin, viewer */}
                                {/* <li key="li12" >
                                                <nav
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                    style={{
                                                        borderRadius: "12px"
                                                    }}
                                                >
                                                    <i className="pi pi-chart-pie mr-2 text-orange-500"></i>
                                                    <span className="font-medium">
                                                        Statisztikák
                                                    </span>
                                                    <Ripple />
                                                </nav>
                                            </li>*/}
                                {userProfile &&
                                userProfile.zepterRank === "CLUBMEMBER" &&
                                (userProfile.representativeNumber === null ||
                                    userProfile.representativeNumber === "" ||
                                    userProfile.representativeNumber ===
                                        "Ön még nem tanácsadó") ? (
                                    <li key="li13">
                                        <StyleClass
                                            nodeRef={
                                                btnRefConsultantRegistration
                                            }
                                            selector="#app-sidebar"
                                            leaveToClassName="hidden"
                                            // leaveActiveClassName="fadeoutleft"
                                        >
                                            <nav
                                                ref={
                                                    btnRefConsultantRegistration
                                                }
                                                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                style={{
                                                    borderRadius: "12px",
                                                }}
                                                onClick={() => {
                                                    navigate(
                                                        "consultantregistration"
                                                    );
                                                    dispatch(
                                                        setActiveMenuItem(
                                                            "consultantregistration"
                                                        )
                                                    );
                                                }}
                                            >
                                                <i className="pi pi-user-plus mr-2 text-teal-500"></i>
                                                <span
                                                    className={
                                                        activeMenuItem ===
                                                        "consultantregistration"
                                                            ? "font-medium text-teal-500"
                                                            : "font-medium"
                                                    }
                                                >
                                                    Online Partner regisztráció
                                                </span>
                                                <Ripple />
                                            </nav>
                                        </StyleClass>
                                    </li>
                                ) : null}
                                <li key="li14">
                                    <StyleClass
                                        nodeRef={btnRefWebshop}
                                        selector="@next"
                                        enterClassName="hidden"
                                        enterActiveClassName="slidedown"
                                        leaveToClassName="hidden"
                                        leaveActiveClassName="slideup"
                                    >
                                        <nav
                                            ref={btnRefWebshop}
                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                            style={{
                                                borderRadius: "12px",
                                            }}
                                        >
                                            <i className="pi pi-percentage mr-2 text-purple-500"></i>
                                            <span
                                                className={
                                                    activeMenuItem ===
                                                        "productlist" ||
                                                    activeMenuItem ===
                                                        "checkout"
                                                        ? "font-medium text-purple-500"
                                                        : "font-medium"
                                                }
                                            >
                                                Webshop
                                            </span>
                                            <i className="pi pi-chevron-down ml-auto"></i>
                                            <Ripple />
                                        </nav>
                                    </StyleClass>
                                    <ul
                                        key="ul5"
                                        className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out"
                                    >
                                        <li key="li15">
                                            {/* <nav
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                    style={{
                                                        borderRadius: "12px"
                                                    }}
                                                >
                                                    <i className="pi pi-wallet mr-2 text-purple-500"></i>
                                                    <span className="font-medium">
                                                        Wallet
                                                    </span>
                                                    <Ripple />
                                                </nav> */}
                                        </li>
                                        <li key="li16">
                                            <StyleClass
                                                nodeRef={btnRefProductList}
                                                selector="#app-sidebar"
                                                leaveToClassName="hidden"
                                                // leaveActiveClassName="fadeoutleft"
                                            >
                                                <nav
                                                    ref={btnRefProductList}
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                    style={{
                                                        borderRadius: "12px",
                                                    }}
                                                    onClick={() => {
                                                        navigate("productlist");
                                                        dispatch(
                                                            setActiveMenuItem(
                                                                "productlist"
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <i className="pi pi-th-large mr-2 text-purple-500"></i>
                                                    <span
                                                        className={
                                                            activeMenuItem ===
                                                            "productlist"
                                                                ? "font-medium text-purple-500"
                                                                : "font-medium"
                                                        }
                                                    >
                                                        Termékek
                                                    </span>
                                                    {/* <Badge
                                                        className="ml-1"
                                                        value="ÚJ"
                                                        severity="info"
                                                    /> */}
                                                    <Ripple />
                                                </nav>
                                            </StyleClass>
                                        </li>
                                        <StyleClass
                                            nodeRef={btnRefCartOpen2}
                                            selector="#slideover-cart"
                                            enterClassName="hidden"
                                            enterActiveClassName="fadeinup"
                                            leaveToClassName="hidden"
                                            leaveActiveClassName="fadeoutup"
                                            // hideOnOutsideClick
                                            // hideOnOutsideClick
                                        >
                                            <li key="li17">
                                                <nav
                                                    ref={btnRefCartOpen2}
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                    style={{
                                                        borderRadius: "12px",
                                                    }}
                                                >
                                                    <i className="pi pi-shopping-cart mr-2 text-purple-500"></i>
                                                    <span className="font-medium">
                                                        Kosár
                                                    </span>
                                                    <Badge
                                                        className="ml-1"
                                                        value={
                                                            cartSummary.totalQuantity
                                                        }
                                                    />
                                                    <Ripple />
                                                </nav>
                                            </li>
                                        </StyleClass>

                                        {/* </StyleClass> */}
                                        {/* <li key="li18" >
                                                <nav
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                    style={{
                                                        borderRadius: "12px",
                                                    }}
                                                >
                                                    <i className="pi pi-check-square mr-2 text-purple-500"></i>
                                                    <span className="font-medium">
                                                        Előfizetésem
                                                    </span>
                                                    <Ripple />
                                                </nav>
                                            </li> */}
                                        {/* <li key="li19" >
                                                <nav
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                    style={{
                                                        borderRadius: "12px",
                                                    }}
                                                >
                                                    <i className="pi pi-shopping-bag mr-2 text-purple-500"></i>
                                                    <span className="font-medium">
                                                        Korábbi vásárlásaim
                                                    </span>
                                                    <Ripple />
                                                </nav>
                                            </li> */}
                                    </ul>
                                </li>
                                <li key="li20">
                                    <StyleClass
                                        nodeRef={btnRefAffiliate}
                                        selector="@next"
                                        enterClassName="hidden"
                                        enterActiveClassName="slidedown"
                                        leaveToClassName="hidden"
                                        leaveActiveClassName="slideup"
                                    >
                                        <nav
                                            ref={btnRefAffiliate}
                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                            style={{
                                                borderRadius: "12px",
                                            }}
                                        >
                                            <i className="pi pi-users mr-2 text-blue-500"></i>
                                            <span
                                                className={
                                                    activeMenuItem ===
                                                        "recommenderlink" ||
                                                    activeMenuItem ===
                                                        "clubmembersearch"
                                                        ? "font-medium text-blue-500"
                                                        : "font-medium"
                                                }
                                            >
                                                Affiliate
                                            </span>
                                            <i className="pi pi-chevron-down ml-auto"></i>
                                            <Ripple />
                                        </nav>
                                    </StyleClass>
                                    <ul
                                        key="ul6"
                                        className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out"
                                    >
                                        <li key="li21">
                                            <StyleClass
                                                nodeRef={btnRefRecommenderLink}
                                                selector="#app-sidebar"
                                                leaveToClassName="hidden"
                                                // leaveActiveClassName="fadeoutleft"
                                            >
                                                <nav
                                                    ref={btnRefRecommenderLink}
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                    style={{
                                                        borderRadius: "12px",
                                                    }}
                                                    onClick={() => {
                                                        navigate(
                                                            "recommenderlink"
                                                        );
                                                        dispatch(
                                                            setActiveMenuItem(
                                                                "recommenderlink"
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <i className="pi pi-share-alt mr-2 text-blue-500"></i>
                                                    <span
                                                        className={
                                                            activeMenuItem ===
                                                            "recommenderlink"
                                                                ? "font-medium text-blue-500"
                                                                : "font-medium"
                                                        }
                                                    >
                                                        Ajánlói linkem
                                                    </span>
                                                    <Ripple />
                                                </nav>
                                            </StyleClass>
                                        </li>
                                        {/* TODO ha van adatkezelési, csak akkor jelenjen meg, Profilnál frissíteni */}
                                        {userProfile &&
                                        (userProfile.zepterRank ===
                                            "REPRESENTATIVE" ||
                                            userProfile.zepterRank ===
                                                "EMPLOYEE") &&
                                        userProfile.representativeNumber !==
                                            null &&
                                        userProfile.representativeNumber !==
                                            "" ? (
                                            <>
                                                <li key="li221">
                                                    <StyleClass
                                                        nodeRef={
                                                            btnRefClubMemberInvitation
                                                        }
                                                        selector="#app-sidebar"
                                                        leaveToClassName="hidden"
                                                    >
                                                        <nav
                                                            ref={
                                                                btnRefClubMemberInvitation
                                                            }
                                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                            style={{
                                                                borderRadius:
                                                                    "12px",
                                                            }}
                                                            onClick={() => {
                                                                navigate(
                                                                    "clubmemberinvitation"
                                                                );
                                                                dispatch(
                                                                    setActiveMenuItem(
                                                                        "clubmemberinvitation"
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <i className="pi pi-send mr-2 text-blue-500"></i>
                                                            <span
                                                                className={
                                                                    activeMenuItem ===
                                                                    "clubmemberinvitation"
                                                                        ? "font-medium text-blue-500"
                                                                        : "font-medium"
                                                                }
                                                            >
                                                                Klubtag meghívás
                                                            </span>
                                                            <Ripple />
                                                        </nav>
                                                    </StyleClass>
                                                </li>
                                                <li key="li222">
                                                    <StyleClass
                                                        nodeRef={
                                                            btnRefClubMemberPendingInvitedList
                                                        }
                                                        selector="#app-sidebar"
                                                        leaveToClassName="hidden"
                                                    >
                                                        <nav
                                                            ref={
                                                                btnRefClubMemberPendingInvitedList
                                                            }
                                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                            style={{
                                                                borderRadius:
                                                                    "12px",
                                                            }}
                                                            onClick={() => {
                                                                navigate(
                                                                    "clubmemberpendinginvitedlist"
                                                                );
                                                                dispatch(
                                                                    setActiveMenuItem(
                                                                        "clubmemberpendinginvitedlist"
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <i className="pi pi-list mr-2 text-blue-500"></i>
                                                            <span
                                                                className={
                                                                    activeMenuItem ===
                                                                    "clubmemberpendinginvitedlist"
                                                                        ? "font-medium text-blue-500"
                                                                        : "font-medium"
                                                                }
                                                            >
                                                                Függő
                                                                meghívottak
                                                            </span>
                                                            <Ripple />
                                                        </nav>
                                                    </StyleClass>
                                                </li>
                                                <li key="li23">
                                                    <StyleClass
                                                        nodeRef={
                                                            btnRefClubMemberSearch
                                                        }
                                                        selector="#app-sidebar"
                                                        leaveToClassName="hidden"
                                                    >
                                                        <nav
                                                            ref={
                                                                btnRefClubMemberSearch
                                                            }
                                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                            style={{
                                                                borderRadius:
                                                                    "12px",
                                                            }}
                                                            onClick={() => {
                                                                navigate(
                                                                    "clubmembersearch"
                                                                );
                                                                dispatch(
                                                                    setActiveMenuItem(
                                                                        "clubmembersearch"
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <i className="pi pi-search mr-2 text-blue-500"></i>
                                                            <span
                                                                className={
                                                                    activeMenuItem ===
                                                                    "clubmembersearch"
                                                                        ? "font-medium text-blue-500"
                                                                        : "font-medium"
                                                                }
                                                            >
                                                                Zepter Club tag
                                                                keresés
                                                            </span>
                                                            <Ripple />
                                                        </nav>
                                                    </StyleClass>
                                                </li>
                                            </>
                                        ) : null}
                                    </ul>
                                </li>
                                <li key="li24">
                                    <StyleClass
                                        nodeRef={btnRefStructure}
                                        selector="@next"
                                        enterClassName="hidden"
                                        enterActiveClassName="slidedown"
                                        leaveToClassName="hidden"
                                        leaveActiveClassName="slideup"
                                    >
                                        <nav
                                            ref={btnRefStructure}
                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                            style={{
                                                borderRadius: "12px",
                                            }}
                                        >
                                            <i className="pi pi-sitemap mr-2 text-pink-500"></i>
                                            <span
                                                className={
                                                    activeMenuItem ===
                                                        "clubmemberstructure" ||
                                                    activeMenuItem ===
                                                        "representativestructure"
                                                        ? "font-medium text-pink-500"
                                                        : "font-medium"
                                                }
                                            >
                                                Struktúráim
                                            </span>
                                            {/* <Badge
                                                className="ml-1"
                                                value="ÚJ"
                                                severity="info"
                                            /> */}
                                            <Ripple />
                                            <i className="pi pi-chevron-down ml-auto"></i>
                                            <Ripple />
                                        </nav>
                                    </StyleClass>
                                    <ul
                                        key="ul7"
                                        className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out"
                                    >
                                        <li key="li25">
                                            <StyleClass
                                                nodeRef={
                                                    btnRefClubMemberStructure
                                                }
                                                selector="#app-sidebar"
                                                leaveToClassName="hidden"
                                                // leaveActiveClassName="fadeoutleft"
                                            >
                                                <nav
                                                    ref={
                                                        btnRefClubMemberStructure
                                                    }
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                    style={{
                                                        borderRadius: "12px",
                                                    }}
                                                    onClick={() => {
                                                        navigate(
                                                            "clubmemberstructure"
                                                        );
                                                        dispatch(
                                                            setActiveMenuItem(
                                                                "clubmemberstructure"
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <i className="pi pi-id-card mr-2 text-pink-500"></i>
                                                    <span
                                                        className={
                                                            activeMenuItem ===
                                                            "clubmemberstructure"
                                                                ? "font-medium text-pink-500"
                                                                : "font-medium"
                                                        }
                                                    >
                                                        Zepter Club tagok&nbsp;
                                                    </span>

                                                    <Ripple />
                                                </nav>
                                            </StyleClass>
                                        </li>
                                        {userProfile &&
                                        userProfile.zepterRank &&
                                        userProfile.zepterRank !==
                                            "CLUBMEMBER" ? (
                                            <li key="li26">
                                                <StyleClass
                                                    nodeRef={
                                                        btnRefRepresentativeStructure
                                                    }
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                    // leaveActiveClassName="fadeoutleft"
                                                >
                                                    <nav
                                                        ref={
                                                            btnRefRepresentativeStructure
                                                        }
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{
                                                            borderRadius:
                                                                "12px",
                                                        }}
                                                        onClick={() => {
                                                            navigate(
                                                                "representativestructure"
                                                            );
                                                            dispatch(
                                                                setActiveMenuItem(
                                                                    "representativestructure"
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        <i className="pi pi-users mr-2 text-pink-500"></i>
                                                        <span
                                                            className={
                                                                activeMenuItem ===
                                                                "representativestructure"
                                                                    ? "font-medium text-pink-500"
                                                                    : "font-medium"
                                                            }
                                                        >
                                                            Tanácsadóim&nbsp;
                                                        </span>
                                                        {/* <Badge
                                                            className="ml-1"
                                                            value="ÚJ"
                                                            severity="info"
                                                        /> */}
                                                        <Ripple />
                                                    </nav>
                                                </StyleClass>
                                            </li>
                                        ) : null}
                                    </ul>
                                </li>
                                <li key="li27">
                                    <StyleClass
                                        nodeRef={btnRefInformation}
                                        selector="#app-sidebar"
                                        leaveToClassName="hidden"
                                        // leaveActiveClassName="fadeoutleft"
                                    >
                                        <nav
                                            ref={btnRefInformation}
                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                            style={{ borderRadius: "12px" }}
                                            onClick={() => {
                                                // setShowAppSidebar(false);
                                                navigate("information");
                                                dispatch(
                                                    setActiveMenuItem(
                                                        "information"
                                                    )
                                                );
                                            }}
                                        >
                                            <i className="pi pi-info-circle mr-2 text-teal-500 "></i>
                                            <span
                                                className={
                                                    activeMenuItem ===
                                                    "information"
                                                        ? "font-medium text-teal-500"
                                                        : "font-medium"
                                                }
                                            >
                                                Információk
                                            </span>
                                            <Ripple />
                                        </nav>
                                    </StyleClass>
                                </li>
                                <li key="li28">
                                    <StyleClass
                                        nodeRef={btnRefInstallmentPayment}
                                        selector="#app-sidebar"
                                        leaveToClassName="hidden"
                                        // leaveActiveClassName="fadeoutleft"
                                    >
                                        <nav
                                            ref={btnRefInstallmentPayment}
                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                            style={{ borderRadius: "12px" }}
                                            onClick={() => {
                                                // setShowAppSidebar(false);
                                                navigate("installmentpayment");
                                                dispatch(
                                                    setActiveMenuItem(
                                                        "installmentpayment"
                                                    )
                                                );
                                            }}
                                        >
                                            <i className="pi pi-credit-card mr-2 text-green-500 "></i>
                                            <span
                                                className={
                                                    activeMenuItem ===
                                                    "installmentpayment"
                                                        ? "font-medium text-green-500"
                                                        : "font-medium"
                                                }
                                            >
                                                Részletfizetés
                                            </span>
                                            {/* <Badge
                                                className="ml-1"
                                                value="ÚJ"
                                                severity="info"
                                            /> */}
                                            <Ripple />
                                        </nav>
                                    </StyleClass>
                                </li>
                                <li key="li29">
                                    <nav
                                        onClick={() => {
                                            clear();
                                        }}
                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                        style={{ borderRadius: "12px" }}
                                    >
                                        <i className="pi pi-sign-out mr-2 text-bluegray-500"></i>
                                        <span className="font-medium">
                                            Kijelentkezés
                                        </span>
                                        <Ripple />
                                    </nav>
                                </li>
                            </ul>
                        </ul>
                    </div>
                </div>
            </div>
            <BlockUI
                blocked={
                    loading && loading.blockPage ? loading.blockPage : false
                }
                className="max-w-full w-full h-full z-1"
                template={
                    <div
                        className="text-center text-teal-50"
                        style={{ fontSize: "2rem" }}
                    >
                        <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "3rem" }}
                        />
                        <br />
                        {loading && loading.message ? loading.message : null}
                        <br />
                        Kérem várjon!
                    </div>
                }
            >
                <div className="min-h-screen flex flex-column relative flex-auto">
                    <div
                        className=" max-w-full w-full flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static"
                        style={{ height: "60px" }}
                    >
                        <StyleClass
                            nodeRef={btnRefSidebar}
                            selector="#app-sidebar"
                            enterClassName="hidden"
                            enterActiveClassName="fadeinleft"
                            leaveToClassName="hidden"
                            leaveActiveClassName="fadeoutleft"
                            hideOnOutsideClick
                        >
                            <nav
                                ref={btnRefSidebar}
                                className="p-ripple cursor-pointer block lg:hidden text-700 mr-3"
                            >
                                <i className="pi pi-bars text-4xl"></i>
                                <Ripple />
                            </nav>
                        </StyleClass>
                        {userProfile &&
                        (userProfile.zepterRank === "REPRESENTATIVE" ||
                            userProfile.zepterRank === "EMPLOYEE") &&
                        userProfile.representativeNumber !== null &&
                        userProfile.representativeNumber !== "" ? (
                            <div className="footer-link">
                                <a
                                    target="_blank"
                                    href="https://www.facebook.com/groups/mybasics"
                                    className="cursor-pointer text-700"
                                    rel="noreferrer"
                                >
                                    <Button
                                        className="facebook p-2"
                                        aria-label="Facebook"
                                    >
                                        <i className="facebook pi pi-facebook px-1"></i>
                                        <span className="px-1 text-sm">
                                            Csatlakozom a MYB exkluzív Facebook
                                            csoporthoz
                                        </span>
                                    </Button>
                                </a>
                            </div>
                        ) : null}
                        <StyleClass
                            nodeRef={btnRefTopbar}
                            selector="@next"
                            enterClassName="hidden"
                            enterActiveClassName="fadein"
                            leaveToClassName="hidden"
                            leaveActiveClassName="fadeout"
                            hideOnOutsideClick
                        >
                            <nav
                                ref={btnRefTopbar}
                                className="p-ripple cursor-pointer block lg:hidden text-700"
                            >
                                <i className="pi pi-ellipsis-v text-2xl"></i>
                                <Ripple />
                            </nav>
                        </StyleClass>
                        <ul
                            key="ul8"
                            className="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row-reverse w-auto lg:w-full surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static"
                        >
                            <StyleClass
                                nodeRef={btnRefCartOpen1}
                                selector="#slideover-cart"
                                enterClassName="hidden"
                                enterActiveClassName="fadeinup"
                                leaveToClassName="hidden"
                                leaveActiveClassName="fadeoutup"
                                // hideOnOutsideClick
                            >
                                <li key="li29">
                                    <nav
                                        ref={btnRefCartOpen1}
                                        className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors"
                                    >
                                        <i className="pi pi-shopping-cart text-base text-purple-500 lg:text-2xl mr-2 lg:mr-0 p-overlay-badge">
                                            <Badge
                                                className="hidden lg:block mt-1 font-medium"
                                                value={
                                                    cartSummary.totalQuantity !==
                                                    0
                                                        ? cartSummary.totalQuantity
                                                        : null
                                                }
                                            />
                                        </i>
                                        <span className="block lg:hidden font-medium">
                                            Kosár
                                            <Badge
                                                className="ml-1"
                                                value={
                                                    cartSummary.totalQuantity !==
                                                    0
                                                        ? cartSummary.totalQuantity
                                                        : null
                                                }
                                            />
                                        </span>
                                        <Ripple />
                                    </nav>
                                </li>
                            </StyleClass>
                            {/*
                            <li key="li30" >
                                <nav className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors">
                                    <i className="pi pi-bell text-base text-green-500 lg:text-2xl mr-2 lg:mr-0 p-overlay-badge">
                                        <Badge />
                                    </i>
                                    <span className="block lg:hidden font-medium">
                                        Értesítések
                                    </span>
                                    <Ripple />
                                </nav>
                            </li> */}
                            <li key="li31">
                                <StyleClass
                                    nodeRef={btnRefProfil}
                                    selector="@grandparent"
                                    leaveToClassName="hidden"
                                    leaveActiveClassName="fadeout"
                                >
                                    <nav
                                        ref={btnRefProfil}
                                        className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-200 font-medium border-round cursor-pointer
                            transition-duration-150 transition-colors"
                                        onClick={() => {
                                            navigate("profile");
                                            dispatch(
                                                setActiveMenuItem("profile")
                                            );
                                        }}
                                    >
                                        <i className="pi pi-user text-base text-cyan-500 lg:text-2xl mr-2 lg:mr-0"></i>
                                        <span
                                            className={
                                                activeMenuItem === "profile"
                                                    ? "block lg:hidden font-medium text-cyan-500"
                                                    : "block lg:hidden font-medium"
                                            }
                                        >
                                            Profil
                                        </span>
                                        <Ripple />
                                    </nav>
                                </StyleClass>
                            </li>
                            {/* <li key="li32"  className="border-top-1 surface-border lg:border-top-none ml-auto">
                                <nav
                                    className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer
                            transition-duration-150 transition-colors"
                                >
                                    <div className="block lg:hidden">
                                        <div className="text-900 font-medium">
                                            {userProfile
                                                ? userProfile.lastName +
                                                  " " +
                                                  userProfile.firstName
                                                : null}
                                        </div>
                                        <span className="text-600 font-medium text-sm"></span>
                                    </div>
                                    <Ripple />
                                </nav>
                            </li> */}
                            <li
                                key="li33"
                                className="border-top-1 surface-border lg:border-top-none ml-auto"
                            >
                                <nav
                                    className="p-ripple flex px-3 py-2 align-items-center hover:surface-100 font-medium border-round
                            transition-duration-150 transition-colors"
                                >
                                    {/* TODO +company name */}
                                    <div className="text-900 font-medium">
                                        {userProfile &&
                                        userProfile.lastName &&
                                        userProfile.firstName ? (
                                            userProfile.lastName +
                                            " " +
                                            userProfile.firstName
                                        ) : (
                                            <ProgressSpinner
                                                style={{
                                                    width: "25px",
                                                    height: "25px",
                                                }}
                                            />
                                        )}
                                    </div>
                                    <Ripple />
                                </nav>
                            </li>
                        </ul>
                        <div
                            id="slideover-cart"
                            className="slideover-cart cart-product-list hidden absolute right-0 w-full z-4 md:w-30rem"
                        >
                            <div className="flex flex-column shadow-2 z-4">
                                <div className="surface-100 p-3 flex">
                                    <StyleClass
                                        nodeRef={btnRefCartClose1}
                                        selector="#slideover-cart"
                                        leaveToClassName="hidden"
                                        leaveActiveClassName="fadeoutup"
                                    >
                                        <Button
                                            ref={btnRefCartClose1}
                                            icon="pi pi-chevron-up"
                                            className="p-button-text p-button-rounded"
                                        />
                                    </StyleClass>
                                    <div className="border-right-1 border-300 mx-3"></div>
                                    <span className="text-900 text-xl font-medium inline-flex align-items-center ml-1">
                                        Kosaram
                                    </span>
                                </div>
                                <div className="cart-product-list flex-auto overflow-y-auto py-4 px-4 md:px-2 z-4">
                                    <div className="flex align-items-start h-full sm:align-items-center mb-2">
                                        <ul
                                            key="ul9"
                                            className="list-none p-0 m-0"
                                        >
                                            {createdCart &&
                                            createdCart.length > 0 ? (
                                                createdCart.map(
                                                    (
                                                        product: any,
                                                        index: number
                                                    ) => {
                                                        return (
                                                            <>
                                                                <li
                                                                    key={
                                                                        "product-" +
                                                                        index.toString()
                                                                    }
                                                                    className="flex align-items-center mt-3 mb-3"
                                                                >
                                                                    {/* {
                                                                        bonusMinusPrice
                                                                    } */}
                                                                    <img
                                                                        className="left-0 top-0 w-5 h-5 bg-white m-0 p-0 border-solid border-blue-500 border-2 border-round-2xl md:w-max-full h-full"
                                                                        src={
                                                                            product &&
                                                                            product.productThumbnail
                                                                                ? `data:image/png;base64,${product.productThumbnail}`
                                                                                : noImageAvailable
                                                                        }
                                                                        alt={
                                                                            product &&
                                                                            product.productThumbnail &&
                                                                            product.name
                                                                                ? product.name +
                                                                                  " kép"
                                                                                : "A termékhez nem érhető el kép"
                                                                        }
                                                                    />
                                                                    {/* <Button
                                                                        icon="pi pi-shopping-cart"
                                                                        className="fadein p-link w-3rem h-3rem bg-pink-500 hover:bg-pink-600 border-circle shadow-2 inline-flex align-items-center justify-content-center absolute transition-colors transition-duration-300"
                                                                        style={{
                                                                            top: "0.5rem",
                                                                            right: "0.5rem",
                                                                        }}
                                                                        onClick={() => {
                                                                            dispatch(
                                                                                setProductDetailFromList(
                                                                                    {
                                                                                        showDialog:
                                                                                            true,
                                                                                        product:
                                                                                            product,
                                                                                        productDiscountPrice:
                                                                                            product.productDiscountPrice,
                                                                                        productDiscountPercentage:
                                                                                            product.productDiscountPercentage,
                                                                                        productInStock:
                                                                                            product.productInStock,
                                                                                        bonus: false,
                                                                                    }
                                                                                )
                                                                            );
                                                                        }}
                                                                    ></Button>*/}
                                                                    {/* </div> */}
                                                                    <div className="flex-auto px-3">
                                                                        <div className="flex align-items-center mb-3">
                                                                            <span className="text-900 font-medium">
                                                                                {product &&
                                                                                product.productName
                                                                                    ? product.productName
                                                                                    : null}
                                                                            </span>
                                                                        </div>
                                                                        {onlinePartnerInAMonthDiscount ===
                                                                        true ? (
                                                                            <>
                                                                                <div className="flex align-items-center mb-3">
                                                                                    <span
                                                                                        className="text-900 font-bold"
                                                                                        style={{
                                                                                            whiteSpace:
                                                                                                "pre-line",
                                                                                        }}
                                                                                    >
                                                                                        {product &&
                                                                                        product.productPlPrice &&
                                                                                        product.productQuantity &&
                                                                                        product.productHalfPrice ===
                                                                                            false
                                                                                            ? setNumberStringThousandSeparator(
                                                                                                  product.productPlPrice
                                                                                              ) +
                                                                                              " Ft * " +
                                                                                              product.productQuantity +
                                                                                              " db =\n" +
                                                                                              setNumberStringThousandSeparator(
                                                                                                  product.productPlTotalPrice.toString()
                                                                                              ) +
                                                                                              " Ft"
                                                                                            : null}
                                                                                        {product &&
                                                                                        product.productRrpTotalPrice &&
                                                                                        product.productHalfPrice ===
                                                                                            true
                                                                                            ? setNumberStringThousandSeparator(
                                                                                                  product.productPlTotalPrice
                                                                                              ) +
                                                                                              " Ft * 1 db =\n" +
                                                                                              setNumberStringThousandSeparator(
                                                                                                  product.productPlTotalPrice.toString()
                                                                                              ) +
                                                                                              " Ft"
                                                                                            : null}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="flex flex-auto justify-content-between align-items-center">
                                                                                    {product &&
                                                                                    product.productPlDiscountPercentage &&
                                                                                    product.productPlDiscountPercentage !==
                                                                                        100 &&
                                                                                    product.productHalfPrice ===
                                                                                        false ? (
                                                                                        <>
                                                                                            <InputNumber
                                                                                                showButtons
                                                                                                buttonLayout="horizontal"
                                                                                                min={
                                                                                                    0
                                                                                                }
                                                                                                inputClassName="w-3rem text-center py-2 px-1 border-transparent"
                                                                                                // value={
                                                                                                //     product.productQuantity
                                                                                                // }
                                                                                                value={
                                                                                                    originalBonusItemNumber ===
                                                                                                        product.productItemNumber &&
                                                                                                    product.productQuantity >
                                                                                                        2
                                                                                                        ? originalBonusItemQuantity
                                                                                                        : product.productQuantity
                                                                                                }
                                                                                                className="border-1 surface-border border-round"
                                                                                                decrementButtonClassName="p-button-text py-1 px-1"
                                                                                                incrementButtonClassName="p-button-text py-1 px-1"
                                                                                                incrementButtonIcon="pi pi-plus"
                                                                                                decrementButtonIcon="pi pi-minus"
                                                                                                onValueChange={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    const newCart =
                                                                                                        cart.map(
                                                                                                            (
                                                                                                                item: any
                                                                                                            ) => {
                                                                                                                const newItem =
                                                                                                                    {
                                                                                                                        ...item,
                                                                                                                    };

                                                                                                                if (
                                                                                                                    newItem.productId &&
                                                                                                                    product.productId &&
                                                                                                                    newItem.productId ===
                                                                                                                        product.productId &&
                                                                                                                    newItem.productPlDiscountPercentage &&
                                                                                                                    newItem.productPlDiscountPercentage !==
                                                                                                                        100
                                                                                                                ) {
                                                                                                                    if (
                                                                                                                        e
                                                                                                                            .target
                                                                                                                            .value
                                                                                                                    ) {
                                                                                                                        newItem.productQuantity =
                                                                                                                            e.target.value;
                                                                                                                        //pluszban hozzá kell adni a bónuszt
                                                                                                                        if (
                                                                                                                            originalBonusItemNumber ===
                                                                                                                                product.productItemNumber &&
                                                                                                                            product.productQuantity >
                                                                                                                                2
                                                                                                                        ) {
                                                                                                                            ++newItem.productQuantity;
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        newItem.productQuantity = 0;
                                                                                                                    }
                                                                                                                    newItem.productRrpTotalPrice =
                                                                                                                        (
                                                                                                                            Number(
                                                                                                                                newItem.productRrpPrice
                                                                                                                            ) *
                                                                                                                            newItem.productQuantity
                                                                                                                        ).toString();
                                                                                                                    newItem.productPlTotalPrice =
                                                                                                                        (
                                                                                                                            Number(
                                                                                                                                newItem.productPlPrice
                                                                                                                            ) *
                                                                                                                            newItem.productQuantity
                                                                                                                        ).toString();
                                                                                                                    newItem.productTotalPrice =
                                                                                                                        (
                                                                                                                            Number(
                                                                                                                                newItem.productPlTotalPrice
                                                                                                                            ) -
                                                                                                                            Number(
                                                                                                                                newItem.productDiscountAmount
                                                                                                                            )
                                                                                                                        ).toString();
                                                                                                                }
                                                                                                                return newItem;
                                                                                                            }
                                                                                                        );
                                                                                                    dispatch(
                                                                                                        setCart(
                                                                                                            newCart
                                                                                                        )
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                            <Button
                                                                                                icon="pi pi-trash"
                                                                                                className="p-button-danger p-button-text p-button-rounded"
                                                                                                onClick={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    const newCart =
                                                                                                        cart.filter(
                                                                                                            (
                                                                                                                item: any
                                                                                                            ) => {
                                                                                                                // extra bónusz
                                                                                                                // if (
                                                                                                                //     product.productId ===
                                                                                                                //     "36"
                                                                                                                // ) {
                                                                                                                //     return (
                                                                                                                //         item.productId &&
                                                                                                                //         item.productId !==
                                                                                                                //             product.productId &&
                                                                                                                //         item.productId !==
                                                                                                                //             "42"
                                                                                                                //     );
                                                                                                                // } else {
                                                                                                                return (
                                                                                                                    item.productId &&
                                                                                                                    item.productId !==
                                                                                                                        product.productId
                                                                                                                );
                                                                                                                // }
                                                                                                            }
                                                                                                        );
                                                                                                    dispatch(
                                                                                                        setCart(
                                                                                                            newCart
                                                                                                        )
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {product.productHalfPrice ===
                                                                                            true ? (
                                                                                                <>
                                                                                                    <div className="w-12rem text-center py-2 px-1 text-4xl text-center font-medium text-green-500">
                                                                                                        50%-os
                                                                                                        kedvezmény
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <div className="w-12rem text-center py-2 px-1 text-4xl text-center font-medium text-green-500">
                                                                                                        Extra
                                                                                                        bónusz
                                                                                                    </div>
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className="flex align-items-center mb-3">
                                                                                    <span
                                                                                        className="text-900 font-bold"
                                                                                        style={{
                                                                                            whiteSpace:
                                                                                                "pre-line",
                                                                                        }}
                                                                                    >
                                                                                        {product &&
                                                                                        product.productPlPrice &&
                                                                                        product.productQuantity
                                                                                            ? setNumberStringThousandSeparator(
                                                                                                  product.productPlPrice
                                                                                              ) +
                                                                                              " Ft * " +
                                                                                              product.productQuantity +
                                                                                              " db =\n" +
                                                                                              setNumberStringThousandSeparator(
                                                                                                  product.productPlTotalPrice.toString()
                                                                                              ) +
                                                                                              " Ft"
                                                                                            : null}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="flex flex-auto justify-content-between align-items-center">
                                                                                    {product &&
                                                                                    product.productPlDiscountPercentage &&
                                                                                    product.productPlDiscountPercentage !==
                                                                                        100 ? (
                                                                                        <>
                                                                                            <InputNumber
                                                                                                showButtons
                                                                                                buttonLayout="horizontal"
                                                                                                min={
                                                                                                    0
                                                                                                }
                                                                                                inputClassName="w-3rem text-center py-2 px-1 border-transparent"
                                                                                                // value={
                                                                                                //     product.productQuantity
                                                                                                // }
                                                                                                value={
                                                                                                    originalBonusItemNumber ===
                                                                                                        product.productItemNumber &&
                                                                                                    product.productQuantity >
                                                                                                        2
                                                                                                        ? originalBonusItemQuantity
                                                                                                        : product.productQuantity
                                                                                                }
                                                                                                className="border-1 surface-border border-round"
                                                                                                decrementButtonClassName="p-button-text py-1 px-1"
                                                                                                incrementButtonClassName="p-button-text py-1 px-1"
                                                                                                incrementButtonIcon="pi pi-plus"
                                                                                                decrementButtonIcon="pi pi-minus"
                                                                                                onValueChange={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    const newCart =
                                                                                                        cart.map(
                                                                                                            (
                                                                                                                item: any
                                                                                                            ) => {
                                                                                                                const newItem =
                                                                                                                    {
                                                                                                                        ...item,
                                                                                                                    };

                                                                                                                if (
                                                                                                                    newItem.productId &&
                                                                                                                    product.productId &&
                                                                                                                    newItem.productId ===
                                                                                                                        product.productId &&
                                                                                                                    newItem.productPlDiscountPercentage &&
                                                                                                                    newItem.productPlDiscountPercentage !==
                                                                                                                        100
                                                                                                                ) {
                                                                                                                    if (
                                                                                                                        e
                                                                                                                            .target
                                                                                                                            .value
                                                                                                                    ) {
                                                                                                                        newItem.productQuantity =
                                                                                                                            e.target.value;
                                                                                                                        //pluszban hozzá kell adni a bónuszt
                                                                                                                        if (
                                                                                                                            originalBonusItemNumber ===
                                                                                                                                product.productItemNumber &&
                                                                                                                            product.productQuantity >
                                                                                                                                2
                                                                                                                        ) {
                                                                                                                            ++newItem.productQuantity;
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        newItem.productQuantity = 0;
                                                                                                                    }
                                                                                                                    newItem.productRrpTotalPrice =
                                                                                                                        (
                                                                                                                            Number(
                                                                                                                                newItem.productRrpPrice
                                                                                                                            ) *
                                                                                                                            newItem.productQuantity
                                                                                                                        ).toString();
                                                                                                                    newItem.productPlTotalPrice =
                                                                                                                        (
                                                                                                                            Number(
                                                                                                                                newItem.productPlPrice
                                                                                                                            ) *
                                                                                                                            newItem.productQuantity
                                                                                                                        ).toString();
                                                                                                                    newItem.productTotalPrice =
                                                                                                                        (
                                                                                                                            Number(
                                                                                                                                newItem.productPlTotalPrice
                                                                                                                            ) -
                                                                                                                            Number(
                                                                                                                                newItem.productDiscountAmount
                                                                                                                            )
                                                                                                                        ).toString();
                                                                                                                }
                                                                                                                return newItem;
                                                                                                            }
                                                                                                        );
                                                                                                    dispatch(
                                                                                                        setCart(
                                                                                                            newCart
                                                                                                        )
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                            <Button
                                                                                                icon="pi pi-trash"
                                                                                                className="p-button-danger p-button-text p-button-rounded"
                                                                                                onClick={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    const newCart =
                                                                                                        cart.filter(
                                                                                                            (
                                                                                                                item: any
                                                                                                            ) => {
                                                                                                                // extra bónusz
                                                                                                                // if (
                                                                                                                //     product.productId ===
                                                                                                                //     "36"
                                                                                                                // ) {
                                                                                                                //     return (
                                                                                                                //         item.productId &&
                                                                                                                //         item.productId !==
                                                                                                                //             product.productId &&
                                                                                                                //         item.productId !==
                                                                                                                //             "42"
                                                                                                                //     );
                                                                                                                // } else {
                                                                                                                return (
                                                                                                                    item.productId &&
                                                                                                                    item.productId !==
                                                                                                                        product.productId
                                                                                                                );
                                                                                                                // }
                                                                                                            }
                                                                                                        );
                                                                                                    dispatch(
                                                                                                        setCart(
                                                                                                            newCart
                                                                                                        )
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div className="w-12rem text-center py-2 px-1 text-4xl text-center font-medium text-green-500">
                                                                                                Extra
                                                                                                bónusz
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </li>
                                                                {index + 1 !==
                                                                cart.length ? (
                                                                    <Divider
                                                                        key={
                                                                            "divi-" +
                                                                            index.toString()
                                                                        }
                                                                        className="mt-0 mb-0"
                                                                    />
                                                                ) : null}
                                                            </>
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <li key="li34">A kosár üres</li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                <div className="cart-product-list surface-100 border-top-1 surface-border p-3">
                                    <div className="flex align-items-center justify-content-between mb-3">
                                        <span className="text-900 font-medium">
                                            Összesen
                                            <span className="text-600 text-sm ml-2">
                                                (szállítással)
                                            </span>
                                        </span>
                                        <span className="text-xl text-900 font-bold text-pink-500">
                                            {/* TODO adatbázisból sytem configból szedje a shipmentFee-t */}
                                            {setNumberStringThousandSeparator(
                                                cartSummary.totalQuantity === 0
                                                    ? "0"
                                                    : (cartSummary.shipmentFee !==
                                                      0
                                                          ? cartSummary.rrpTotalPrice
                                                          : cartSummary.rrpTotalPrice +
                                                            1500
                                                      ).toString()
                                            )}{" "}
                                            Ft
                                        </span>
                                    </div>
                                    <div className="flex align-items-center justify-content-between mb-3">
                                        <span className="text-900 font-medium">
                                            Kedvezmények
                                            <span className="text-600 text-sm ml-2">
                                                (szállítással)
                                            </span>
                                        </span>
                                        <span className="text-xl text-900 font-bold text-blue-500">
                                            {/* TODO adatbázisból sytem configból szedje a shipmentFee-t */}
                                            -
                                            {setNumberStringThousandSeparator(
                                                cartSummary.totalQuantity === 0
                                                    ? "0"
                                                    : (cartSummary.shipmentFee !==
                                                      0
                                                          ? cartSummary.rrpTotalPrice -
                                                            cartSummary.totalPrice
                                                          : cartSummary.rrpTotalPrice -
                                                            cartSummary.totalPrice +
                                                            1500
                                                      ).toString()
                                            )}{" "}
                                            Ft
                                        </span>
                                    </div>
                                    <Divider />
                                    <div className="flex align-items-center justify-content-between mb-3">
                                        <span className="text-900 font-medium">
                                            Fizetendő
                                            <span className="text-600 text-sm ml-2">
                                                bruttó
                                            </span>
                                        </span>
                                        <span className="text-xl text-900 font-bold">
                                            {setNumberStringThousandSeparator(
                                                (
                                                    Number(
                                                        cartSummary.totalPrice
                                                    ) - Number(bonusMinusPrice)
                                                ).toString()
                                            )}{" "}
                                            Ft
                                        </span>
                                    </div>
                                    {cartSummary.totalQuantity > 0 ? (
                                        <div className="mb-3">
                                            <div className="flex align-items-center justify-content-between mb-0">
                                                <span className="text-900 font-medium">
                                                    +Szállítási költség
                                                </span>
                                                {cartSummary.shipmentFee > 0 ? (
                                                    <span className="text-xl text-900 font-bold text-red-400">
                                                        +
                                                        {setNumberStringThousandSeparator(
                                                            cartSummary.shipmentFee
                                                        )}{" "}
                                                        Ft
                                                    </span>
                                                ) : (
                                                    <span className="text-xl text-900 font-bold text-green-500">
                                                        ingyenes
                                                    </span>
                                                )}
                                            </div>
                                            <span className="text-600 text-sm mt-0">
                                                (30 000 Ft felett ingyenes)
                                            </span>
                                        </div>
                                    ) : null}
                                    <StyleClass
                                        nodeRef={btnRefCartClose2}
                                        selector="#slideover-cart"
                                        leaveToClassName="hidden"
                                        leaveActiveClassName="fadeoutup"
                                    >
                                        <Button
                                            ref={btnRefCartClose2}
                                            icon="pi pi-book"
                                            className="p-button-success mb-3 w-full"
                                            label="Vásárlás véglegesítése"
                                            disabled={
                                                cartSummary.totalQuantity === 0
                                            }
                                            style={{
                                                border: "none",
                                                borderRadius: "2rem",
                                            }}
                                            onClick={() => {
                                                navigate("checkout");
                                                dispatch(
                                                    setActiveMenuItem(
                                                        "checkout"
                                                    )
                                                );
                                            }}
                                        />
                                    </StyleClass>
                                    <StyleClass
                                        nodeRef={btnRefCartClose3}
                                        selector="#slideover-cart"
                                        leaveToClassName="hidden"
                                        leaveActiveClassName="fadeoutup"
                                    >
                                        <Button
                                            ref={btnRefCartClose3}
                                            icon="pi pi-th-large"
                                            className="p-button p-button-secondary mb-3 w-full"
                                            onClick={() => {
                                                navigate("productlist");
                                                dispatch(
                                                    setActiveMenuItem(
                                                        "productlist"
                                                    )
                                                );
                                            }}
                                            label="Vissza a termékekhez"
                                            style={{
                                                border: "none",
                                                borderRadius: "2rem",
                                            }}
                                        />
                                    </StyleClass>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ProductDetail />
                    <div className="flex flex-column flex-auto p-2 md:p-4 lg:p-5">
                        <Routes>
                            <Route path={"dashboard"} element={<Dashboard />} />
                            <Route path={"profile"} element={<Profile />} />
                            <Route
                                path={"consultantregistration"}
                                element={<ConsultantRegistration />}
                            />
                            <Route
                                path={"clubmemberadminlist"}
                                element={<ClubMemberAdminList />}
                            />
                            <Route
                                path={"documentadminlist"}
                                element={<DocumentAdminList />}
                            />
                            <Route
                                path={"productadminlist"}
                                element={<ProductAdminList />}
                            />
                            <Route
                                path={"pricegroupadminlist"}
                                element={<PriceGroupAdminList />}
                            />
                            <Route
                                path={"productcategoryadminlist"}
                                element={<CategoryAdminList />}
                            />
                            <Route
                                path={"promoadminlist"}
                                element={<PromoAdminList />}
                            />
                            <Route
                                path={"clubmemberadmindetail"}
                                element={<ClubMemberAdminDetail />}
                            />
                            <Route
                                path={"productlist"}
                                element={<ProductList />}
                            />
                            <Route path={"checkout"} element={<Checkout />} />
                            <Route
                                path={"recommenderlink"}
                                element={<RecommanderLink />}
                            />
                            <Route
                                path={"clubmembersearch"}
                                element={<ClubMemberSearch />}
                            />
                            <Route
                                path={"clubmemberstructure"}
                                element={<ClubMemberStructure />}
                            />
                            <Route
                                path={"clubmemberinvitation"}
                                element={<ClubMemberInvitation />}
                            />
                            <Route
                                path={"clubmemberpendinginvitedlist"}
                                element={<ClubMemberPendingInvitedList />}
                            />
                            <Route
                                path={"representativestructure"}
                                element={<RepresentativeStructure />}
                            />
                            <Route
                                path={"information"}
                                element={<Information />}
                            />
                            <Route
                                path={"checkoutsimplepaytest"}
                                element={<CheckoutSimplePayTest />}
                            />
                            <Route
                                path={"simplepaytestsending"}
                                element={<SimplePayTestSending />}
                            />
                            <Route
                                path={"simplepaytestresponse"}
                                element={<SimplePayTestResponse />}
                            />
                            <Route
                                path={"installmentpayment"}
                                element={<InstallmentPayment />}
                            />
                            <Route
                                path={
                                    "mybasicsconsultantgeneraltermsandconditions220615"
                                }
                                element={<GeneralTermsConsultant220615 />}
                            />
                            {/* <Route
                                path={"2022_mybasics_nyeremenyjatek_szabalyzat"}
                                element={<GeneralTermsMyBasicsNYJ2022 />}
                            /> */}
                        </Routes>
                    </div>
                </div>
            </BlockUI>
        </div>
    );
};
export default Main;
