import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setActiveMenuItem } from "../../state/dashboard/navigationSlice";
import { setLoadingOn, setLoadingOff } from "../../state/dashboard/mainSlice";
import { Dialog } from "primereact/dialog";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
    setAuthToken,
    getCurrentUser,
    logout,
} from "../../service/dashboard/AuthService";
import { readRepresentativeStructure } from "../../service/dashboard/RepresentativeStructureService";
import {
    capitalizeFirstLetters,
    formatDateOfBirthFromNumber,
    formatPhoneNumber,
    formatPhoneNumberWithoutSpace,
} from "../../util/stringFormat";
import { StatusCodes } from "http-status-codes";
import pageTeaserRepresentativeStructureImage from "../../asset/page-teaser-representative-structure.png";

const RepresentativeStructure = () => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("representativestructure"));
    let navigate = useNavigate();
    const [nodes, setNodes] = useState([]);
    const [lastStructureRefresh, setLastStructureRefresh] =
        useState<string>("");
    setAuthToken();

    const [actualRepresentativeData, setActualRepresentativeData] =
        useState<any>(null);
    const [showRepresentativeDialog, setShowRepresentativeDialog] =
        useState<boolean>(false);

    //const email: string = "olah.balazs@zepter.hu";
    const email: string = getCurrentUser().email;
    useEffect(() => {
        dispatch(
            setLoadingOn({
                blockPage: true,
                message: "Tanácsadói struktúra létrehozása folyamatban.",
            })
        );
        readRepresentativeStructure(email).then(
            (response: any) => {
                setNodes(response.data.root);
                setLastStructureRefresh(
                    response.data.lastStructureRefresh
                        ? response.data.lastStructureRefresh
                        : "Nincs elérhető információ"
                );
                // console.log(response.data);
                dispatch(setLoadingOff());
            },
            (error) => {
                console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
                dispatch(setLoadingOff());
            }
        );
    }, [navigate, email, dispatch]);

    const showRepresentativeTemplate = (rowData: any) => {
        return (
            <div>
                <Button
                    type="button"
                    icon="pi pi-search"
                    className="p-button-success"
                    onClick={() => {
                        // console.log(rowData);
                        setActualRepresentativeData(rowData.data);
                        setShowRepresentativeDialog(true);
                    }}
                ></Button>
            </div>
        );
    };

    const renderRepresentativeDialogFooter = () => {
        return (
            <div>
                <Button
                    label="Ok"
                    icon="pi pi-check"
                    onClick={() => setShowRepresentativeDialog(false)}
                    autoFocus
                />
            </div>
        );
    };

    return (
        <>
            <Dialog
                header={
                    actualRepresentativeData
                        ? actualRepresentativeData.name + " adatai"
                        : null
                }
                className="w-10 md:w-7"
                visible={showRepresentativeDialog}
                // style={{ width: "50vw" }}
                footer={renderRepresentativeDialogFooter()}
                onHide={() => setShowRepresentativeDialog(false)}
            >
                <ul className="list-none p-0 m-0">
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Név
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualRepresentativeData
                                ? actualRepresentativeData.name
                                : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Zepter Club tagsági szám
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualRepresentativeData
                                ? actualRepresentativeData.vipNumber
                                : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Zepter Club tagság kezdete
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualRepresentativeData
                                ? actualRepresentativeData.vipStartingDate
                                : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Tanácsadó szám
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualRepresentativeData
                                ? actualRepresentativeData.representativeExternalNumber
                                : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Tanácsadói szerződés dátuma
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualRepresentativeData
                                ? actualRepresentativeData.startDate
                                : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Befejezés dátuma
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualRepresentativeData &&
                            actualRepresentativeData.endDate &&
                            actualRepresentativeData.endDate !== "null"
                                ? actualRepresentativeData.endDate
                                : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Születési dátum
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualRepresentativeData &&
                            actualRepresentativeData.dateOfBirth
                                ? formatDateOfBirthFromNumber(
                                      actualRepresentativeData.dateOfBirth
                                  )
                                : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            E-mail
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualRepresentativeData &&
                            actualRepresentativeData.email &&
                            actualRepresentativeData.name ? (
                                <a
                                    href={`mailto:${actualRepresentativeData.name}<${actualRepresentativeData.email}>`}
                                >
                                    {actualRepresentativeData.email}
                                </a>
                            ) : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Mobil
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualRepresentativeData &&
                            actualRepresentativeData.mobileNumber ? (
                                <a
                                    href={`tel:${formatPhoneNumberWithoutSpace(
                                        actualRepresentativeData.mobileNumber
                                    )}`}
                                >
                                    {formatPhoneNumber(
                                        actualRepresentativeData.mobileNumber
                                    )}
                                </a>
                            ) : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Telefon
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualRepresentativeData &&
                            actualRepresentativeData.phoneNumber ? (
                                <a
                                    href={`tel:${formatPhoneNumberWithoutSpace(
                                        actualRepresentativeData.phoneNumber
                                    )}`}
                                >
                                    {formatPhoneNumber(
                                        actualRepresentativeData.phoneNumber
                                    )}
                                </a>
                            ) : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Irányítószám
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualRepresentativeData
                                ? actualRepresentativeData.zip
                                : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Város
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualRepresentativeData
                                ? capitalizeFirstLetters(
                                      actualRepresentativeData.city
                                  )
                                : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 border-bottom-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Cím
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualRepresentativeData &&
                            actualRepresentativeData.street
                                ? capitalizeFirstLetters(
                                      actualRepresentativeData.street
                                  )
                                : null}
                        </div>
                    </li>
                </ul>
            </Dialog>
            <div className="mb-2 flex justify-content-center">
                <div className="w-auto md:w-7">
                    <img
                        src={pageTeaserRepresentativeStructureImage}
                        className="responsive fadeinleft animation-duration-500"
                        alt="bizzclub.hu tanácsadó struktúra kép"
                    />
                </div>
            </div>
            <TreeTable
                // resizableColumns
                columnResizeMode="fit"
                showGridlines
                value={nodes}
                paginator
                currentPageReportTemplate="Lista: {first}-{last} | Összesen: {totalRecords}"
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                // scrollable
                // header="Responsive"
                header={
                    "Tanácsadóim listája | utolsó frissítés: " +
                    lastStructureRefresh
                }
            >
                <Column
                    header="Megtekintés"
                    body={showRepresentativeTemplate}
                    style={{ textAlign: "center", width: "5rem" }}
                />
                <Column
                    className="text-left"
                    field="name"
                    header="Név"
                    filter
                    expander
                    sortable
                ></Column>
                <Column
                    className="text-center"
                    field="representativeExternalNumber"
                    header="Tanácsadó szám"
                ></Column>
                <Column
                    className="text-center"
                    field="startDate"
                    header="Tanácsadói szerződés dátuma"
                    sortable
                ></Column>
                <Column
                    className="text-center"
                    field="vipNumber"
                    header="Zepter Club tagsági szám"
                ></Column>
                <Column
                    className="text-center"
                    field="vipStartingDate"
                    header="Zepter Club tagság kezdete"
                    // filter
                    sortable
                ></Column>
            </TreeTable>
        </>
    );
};

export default RepresentativeStructure;
