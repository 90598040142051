import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { setActiveMenuItem } from "../../state/dashboard/navigationSlice";
import { setLoadingOn, setLoadingOff } from "../../state/dashboard/mainSlice";
import { Dialog } from "primereact/dialog";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Slider, SliderChangeEvent } from "primereact/slider";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import {
    setAuthToken,
    getCurrentUser,
    logout,
} from "../../service/dashboard/AuthService";
import { readClubMemberStructure } from "../../service/dashboard/ClubMemberStructureService";
import {
    capitalizeFirstLetters,
    formatPhoneNumber,
    formatStringToDate,
    formatShortDate,
    setNumberStringThousandSeparator,
    formatPhoneNumberWithoutSpace,
} from "../../util/stringFormat";
import { StatusCodes } from "http-status-codes";
import pageTeaserClubMemberStructureImage from "../../asset/page-teaser-clubmember-structure.png";

const ClubMemberStructure = () => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("clubmemberstructure"));

    const userProfile: any = useSelector(
        (state: RootState) => state.user.userProfile
    );

    let navigate = useNavigate();

    setAuthToken();

    interface Node {
        key: string;
        data: any;
        children?: Node[];
        parentName?: string;
    }

    const [nodes, setNodes] = useState<Node[]>([]);

    const today: Date = new Date();
    const [birthdayNodes, setBirthdayNodes] = useState<Node[]>([]);
    const [showBirthday, setShowBirthday] = useState<boolean>(false);
    const [showStructureDeep, setShowStructureDeep] = useState<boolean>(false);
    const [sumNodesLenght, setSumNodesLenght] = useState<number>(0);
    const [todayBirthdayLenght, setTodayBirthdayLenght] = useState<number>(0);
    const [startBirthdDate, setStartBirthdDate] = useState<Date>(today);
    const [endBirthdDate, setEndBirthdDate] = useState<Date>(today);
    const [birthdayRange, setBirthdayRange] = useState<
        number | [number, number]
    >([30, 30]);
    const [newBirthDayRange, setNewBirthDateRange] = useState<number>(
        today.getTime()
    );

    const manipulateDate = (daysToAddOrSubtract: number): Date => {
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        // Calculation of the new time compared to the current time
        const modifiedTime =
            today.getTime() + daysToAddOrSubtract * millisecondsPerDay;
        const modifiedDate = new Date(modifiedTime);
        return modifiedDate;
    };

    const handleChangeBirthDateRange = (
        range: number | [number, number]
    ): [number, number] => {
        if (Array.isArray(range) && range.length > 0) {
            const finalRange: [number, number] = [
                range[0] < 30 ? 30 : range[0],
                range[1] > 30 ? 30 : range[1],
            ];
            setStartBirthdDate(manipulateDate(-30 + finalRange[1]));
            setEndBirthdDate(manipulateDate(finalRange[0] - 30));
            return finalRange;
        } else {
            return [30, 30];
        }
    };

    const [lastStructureRefresh, setLastStructureRefresh] =
        useState<string>("");

    const [actualClubMemberData, setActualClubMemberData] = useState<any>(null);
    const [showClubMemberDialog, setShowClubMemberDialog] =
        useState<boolean>(false);

    //const email: string = "olah.balazs@zepter.hu";
    const email: string = getCurrentUser().email;

    useEffect(() => {
        dispatch(
            setLoadingOn({
                blockPage: true,
                message:
                    "Zepter Club tagok struktúrájának létrehozása folyamatban.",
            })
        );
        readClubMemberStructure(email).then(
            (response: any) => {
                setNodes(response.data.root);
                setLastStructureRefresh(
                    response.data.lastStructureRefresh
                        ? response.data.lastStructureRefresh
                        : "Nincs elérhető információ"
                );
                // console.log(response.data);
                dispatch(setLoadingOff());
            },
            (error) => {
                console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
                dispatch(setLoadingOff());
            }
        );
    }, [navigate, email, dispatch]);

    // Struktúra rekurzív bejárás

    const traverse = (
        nodes: Node[],
        shouldAddNode: (node: Node) => boolean,
        parentName?: string // Opcionális parentName paraméter
    ): Node[] => {
        const result: Node[] = [];
        for (const node of nodes) {
            try {
                if (shouldAddNode(node)) {
                    if (typeof parentName !== "undefined" && parentName) {
                        const newNode: Node = {
                            ...node,
                            parentName, // Új tulajdonság hozzáadása a newNode-hoz
                        };
                        newNode.data.parentName = parentName;
                        // console.log(newNode);
                        result.push(newNode);
                    } else {
                        result.push(node);
                    }
                }
                if (
                    typeof node.children !== "undefined" &&
                    node.children &&
                    node.children.length > 0
                ) {
                    if (typeof node.data.name !== "undefined") {
                        result.push(
                            ...traverse(
                                node.children,
                                shouldAddNode,
                                node.data.name
                            )
                        );
                    } else {
                        result.push(...traverse(node.children, shouldAddNode));
                    }
                }
            } catch (error) {
                console.error("Hiba történt:", error);
            }
        }
        return result;
    };

    useEffect(() => {
        if (nodes.length !== 0) {
            const sumNodes: Node[] = traverse(nodes, (node) => {
                return true;
            });
            // console.log(sumNodes);
            setSumNodesLenght(sumNodes.length);
            //TODO kell egy checkbox és ha nem akarja látni mélységében a dolgokat, akkor nem traverse bejárás kell
            let filteredNodes: Node[] = [];
            if (showStructureDeep) {
                filteredNodes = traverse(nodes, (node) => {
                    // Ez az if dönti el, hogy melyik Node kerüljön az új tömbbe.
                    const dob = formatStringToDate(node.data.dateOfBirth);
                    // console.log(dob && dob.getUTCDay ? dob    á.getUTCDay : "");
                    if (
                        dob !== null &&
                        dob.getMonth() === today.getMonth() &&
                        dob.getDate() === today.getDate()
                    ) {
                        node.data.name = `🎂 ${node.data.name}`;
                        node.data.name = node.data.name.replaceAll(
                            "🎂 🎂",
                            "🎂"
                        );
                    }
                    if (dob !== null) {
                        if (
                            startBirthdDate.getMonth() <
                            endBirthdDate.getMonth()
                        ) {
                            if (
                                (dob.getMonth() > startBirthdDate.getMonth() &&
                                    dob.getMonth() <
                                        endBirthdDate.getMonth()) ||
                                (dob.getMonth() ===
                                    startBirthdDate.getMonth() &&
                                    dob.getDate() >=
                                        startBirthdDate.getDate()) ||
                                (dob.getMonth() === endBirthdDate.getMonth() &&
                                    dob.getDate() <= endBirthdDate.getDate())
                            ) {
                                return true;
                            }
                        } else if (
                            startBirthdDate.getMonth() ===
                            endBirthdDate.getMonth()
                        ) {
                            if (
                                dob.getMonth() === startBirthdDate.getMonth() &&
                                dob.getDate() >= startBirthdDate.getDate() &&
                                dob.getDate() <= endBirthdDate.getDate()
                            ) {
                                return true;
                            }
                        }
                    }
                    // console.log(filteredNodes);
                    return false;
                });
            } else {
                filteredNodes = nodes.filter((node) => {
                    // Ez az if dönti el, hogy melyik Node kerüljön az új tömbbe.
                    const dob = formatStringToDate(node.data.dateOfBirth);
                    // console.log(dob && dob.getUTCDay ? dob    á.getUTCDay : "");
                    if (
                        dob !== null &&
                        dob.getMonth() === today.getMonth() &&
                        dob.getDate() === today.getDate()
                    ) {
                        node.data.name = `🎂 ${node.data.name}`;
                        node.data.name = node.data.name.replaceAll(
                            "🎂 🎂",
                            "🎂"
                        );
                    }
                    if (dob !== null) {
                        if (
                            startBirthdDate.getMonth() <
                            endBirthdDate.getMonth()
                        ) {
                            if (
                                (dob.getMonth() > startBirthdDate.getMonth() &&
                                    dob.getMonth() <
                                        endBirthdDate.getMonth()) ||
                                (dob.getMonth() ===
                                    startBirthdDate.getMonth() &&
                                    dob.getDate() >=
                                        startBirthdDate.getDate()) ||
                                (dob.getMonth() === endBirthdDate.getMonth() &&
                                    dob.getDate() <= endBirthdDate.getDate())
                            ) {
                                return true;
                            }
                        } else if (
                            startBirthdDate.getMonth() ===
                            endBirthdDate.getMonth()
                        ) {
                            if (
                                dob.getMonth() === startBirthdDate.getMonth() &&
                                dob.getDate() >= startBirthdDate.getDate() &&
                                dob.getDate() <= endBirthdDate.getDate()
                            ) {
                                return true;
                            }
                        }
                    }
                    // console.log(filteredNodes);
                    return false;
                });
            }
            const sortedBirthdays: Node[] = filteredNodes.sort(
                (a: Node, b: Node) => {
                    if (
                        a.data.dateOfBirth.slice(-5) <
                        b.data.dateOfBirth.slice(-5)
                    ) {
                        return -1;
                    } else if (
                        a.data.dateOfBirth.slice(-5) >
                        b.data.dateOfBirth.slice(-5)
                    ) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            );
            const todayBirthdays: Node[] = sortedBirthdays.filter(
                (node: Node) => {
                    return node.data.name && node.data.name.includes("🎂");
                }
            );
            setTodayBirthdayLenght(todayBirthdays.length);
            setBirthdayNodes(sortedBirthdays);
            // console.log(sortedBirthdays);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodes, newBirthDayRange, showStructureDeep]);

    const showClubMemberTemplate = (rowData: any) => {
        //csak munkatárs disabled:
        // || typeof userProfile.representativeNumber !== "string" || (typeof userProfile.representativeNumber === "string" && userProfile.representativeNumber.substring(0, 2) === "77")
        // console.log(rowData.data);
        return (
            <div>
                <Button
                    type="button"
                    icon="pi pi-search"
                    className="cm-details p-button-help"
                    disabled={
                        typeof rowData === "undefined" ||
                        typeof rowData.data === "undefined" ||
                        typeof rowData.data.robinson === "undefined" ||
                        rowData.data.robinson !== false
                    }
                    onClick={() => {
                        setActualClubMemberData(rowData.data);
                        setShowClubMemberDialog(true);
                    }}
                />
            </div>
        );
    };

    const renderClubMemberDialogFooter = () => {
        return (
            <div>
                <Button
                    label="Ok"
                    icon="pi pi-check"
                    onClick={() => setShowClubMemberDialog(false)}
                    autoFocus
                />
            </div>
        );
    };

    const getHeader = () => {
        return (
            <div className="text-center">
                <h2 className="mb-0 pb-0">Zepter Club tagok listája</h2>
                <h3 className="mt-1 pt-0">
                    {showBirthday
                        ? setNumberStringThousandSeparator(
                              birthdayNodes.length.toString()
                          ) +
                          " fő, akikből " +
                          setNumberStringThousandSeparator(
                              todayBirthdayLenght.toString()
                          ) +
                          " fő mai szülinapos "
                        : "Σ " +
                          setNumberStringThousandSeparator(
                              sumNodesLenght.toString()
                          ) +
                          " fő, akikből " +
                          setNumberStringThousandSeparator(
                              (sumNodesLenght - nodes.length).toString()
                          ) +
                          " fő mélyebben"}
                </h3>
                <div className="grid flex align-items-center justify-content-center">
                    <div className="flex align-items-center justify-content-center col-12 md:col-6">
                        <Button
                            label={
                                showBirthday
                                    ? "Mutass mindenkit"
                                    : "🎂 Születésnapos szűrés 🥳"
                            }
                            severity="info"
                            rounded
                            onClick={() =>
                                showBirthday
                                    ? setShowBirthday(false)
                                    : setShowBirthday(true)
                            }
                        />
                    </div>
                    <div className="align-items-center justify-content-center col-12 md:col-6">
                        utolsó frissítés: {lastStructureRefresh}
                    </div>
                    {showBirthday ? (
                        <>
                            <div className="mt-2 grid flex align-items-center justify-content-center">
                                <h3 className="text-indigo-600 mb-0">
                                    Állítsd be a kezdő és vég dátumot a
                                    születésnapos lista szűréséhez:
                                </h3>
                                <div className="mt-5 flex align-items-center justify-content-center col-12">
                                    <Slider
                                        value={birthdayRange}
                                        onChange={(e: SliderChangeEvent) =>
                                            setBirthdayRange(
                                                handleChangeBirthDateRange(
                                                    e.value
                                                )
                                            )
                                        }
                                        className="mt-0 w-20rem md:w-30rem"
                                        range
                                        max={60}
                                    />
                                </div>
                                <div className="flex justify-content-between col-12">
                                    <div className="flex align-items-center justify-content-center ml-4">
                                        {formatShortDate(startBirthdDate)}
                                    </div>
                                    <div className="flex align-items-center justify-content-center mr-4">
                                        {formatShortDate(endBirthdDate)}
                                    </div>
                                </div>
                                <div className="flex align-items-center justify-content-center col-12">
                                    <Button
                                        label="⚙️ Új időszak beállítása"
                                        severity="secondary"
                                        rounded
                                        onClick={() =>
                                            setNewBirthDateRange(
                                                new Date().getTime()
                                            )
                                        }
                                    />
                                </div>
                                <div className="flex justify-content-center gap-5 mt-5 col-12">
                                    <span className="text-900 text-blue-700">
                                        Csak saját
                                    </span>
                                    <InputSwitch
                                        checked={showStructureDeep}
                                        onChange={(e: InputSwitchChangeEvent) =>
                                            setShowStructureDeep(
                                                e.value ?? false
                                            )
                                        }
                                    />
                                    <span className="text-900 text-blue-700">
                                        Teljes struktúra
                                    </span>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        );
    };

    let header = getHeader();

    return (
        <>
            <Dialog
                header={
                    actualClubMemberData
                        ? actualClubMemberData.name + " adatai"
                        : null
                }
                className="w-10 md:w-7 border-0"
                visible={showClubMemberDialog}
                // style={{ width: "50vw" }}
                footer={renderClubMemberDialogFooter()}
                onHide={() => setShowClubMemberDialog(false)}
            >
                <ul className="list-none p-0 m-0">
                    {actualClubMemberData &&
                    typeof actualClubMemberData.parentName !== "undefined" &&
                    actualClubMemberData.parentName ? (
                        <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                            <div className="text-500 text-pink-500 w-full md:w-5 font-medium">
                                Ajánló:
                            </div>
                            <div className="text-900 text-pink-500 w-full md:w-7 md:flex-order-0 flex-order-1">
                                {actualClubMemberData.parentName}
                            </div>
                        </li>
                    ) : null}
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Név
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualClubMemberData
                                ? actualClubMemberData.name
                                : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Zepter Club tagsági szám
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualClubMemberData
                                ? actualClubMemberData.vipNumber
                                : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Zepter Club tagság kezdete
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualClubMemberData
                                ? actualClubMemberData.vipStartingDate
                                : null}
                        </div>
                    </li>

                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Születési dátum
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {/* TODO 2023.04.28 ellenőrizni, hogy milyen formátumban jön a dátum */}
                            {/* formatDateOfBirth(actualClubMemberData.dateOfBirth) */}
                            {actualClubMemberData &&
                            actualClubMemberData.dateOfBirth &&
                            actualClubMemberData.dateOfBirth !== null &&
                            actualClubMemberData.dateOfBirth !== "1753.01.01"
                                ? actualClubMemberData.dateOfBirth
                                : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            E-mail
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualClubMemberData &&
                            actualClubMemberData.email &&
                            actualClubMemberData.name ? (
                                <a
                                    href={`mailto:${actualClubMemberData.name}<${actualClubMemberData.email}>`}
                                >
                                    {actualClubMemberData.email}
                                </a>
                            ) : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Mobil
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualClubMemberData &&
                            actualClubMemberData.mobileNumber ? (
                                <a
                                    href={`tel:${formatPhoneNumberWithoutSpace(
                                        actualClubMemberData.mobileNumber
                                    )}`}
                                >
                                    {formatPhoneNumber(
                                        actualClubMemberData.mobileNumber
                                    )}
                                </a>
                            ) : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Telefon
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualClubMemberData &&
                            actualClubMemberData.phoneNumber ? (
                                <a
                                    href={`tel:${formatPhoneNumberWithoutSpace(
                                        actualClubMemberData.phoneNumber
                                    )}`}
                                >
                                    {formatPhoneNumber(
                                        actualClubMemberData.phoneNumber
                                    )}
                                </a>
                            ) : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Irányítószám
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualClubMemberData
                                ? actualClubMemberData.zip
                                : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Város
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualClubMemberData
                                ? capitalizeFirstLetters(
                                      actualClubMemberData.city
                                  )
                                : null}
                        </div>
                    </li>
                    <li className="flex align-items-center py-1 px-1 border-top-1 border-bottom-1 surface-border flex-wrap">
                        <div className="text-500 w-full md:w-5 font-medium">
                            Cím
                        </div>
                        <div className="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            {actualClubMemberData && actualClubMemberData.street
                                ? capitalizeFirstLetters(
                                      actualClubMemberData.street
                                  )
                                : null}
                        </div>
                    </li>
                </ul>
            </Dialog>
            <div className="mb-2 flex justify-content-center">
                <div className="w-auto md:w-7">
                    <img
                        src={pageTeaserClubMemberStructureImage}
                        className="responsive fadeinleft animation-duration-500"
                        alt="bizzclub.hu Zepter Club tagok struktúra kép"
                    />
                </div>
            </div>
            <TreeTable
                // resizableColumns
                columnResizeMode="fit"
                showGridlines
                value={showBirthday ? birthdayNodes : nodes}
                paginator
                currentPageReportTemplate="Lista: {first}-{last} | Összesen: {totalRecords}"
                rows={50}
                rowsPerPageOptions={[10, 20, 50, 100]}
                // scrollable
                // header="Responsive"
                filterMode="lenient"
                header={header}
            >
                {userProfile &&
                (userProfile.zepterRank === "REPRESENTATIVE" ||
                    userProfile.zepterRank === "EMPLOYEE") &&
                userProfile.representativeNumber !== null &&
                userProfile.representativeNumber !== "" &&
                userProfile.contractReceiveDate !== null ? (
                    <Column
                        header="Megtekintés"
                        body={showClubMemberTemplate}
                        style={{ textAlign: "center", width: "5rem" }}
                    />
                ) : null}
                <Column
                    className="text-left"
                    field="name"
                    header="Név"
                    filter
                    filterPlaceholder="Szűrés név alapján"
                    expander
                    sortable
                />
                <Column
                    className="text-center"
                    field="zip"
                    header="Ir. szám"
                    filter
                    filterPlaceholder="Szűrés ir. szám alapján"
                    // expander
                    sortable
                />
                <Column
                    className="text-center"
                    field="vipNumber"
                    header="Zepter Club tagsági szám"
                    // expander
                    // filter
                />
                <Column
                    className="text-center"
                    field="vipStartingDate"
                    header="Zepter Club tagság kezdete"
                    // filter
                    sortable
                />
            </TreeTable>
        </>
    );
};

export default ClubMemberStructure;
